import React from "react";
import styles from "./SingleAI.module.css";

const SingleAI = (props) => {
  const { icon, name } = props;
  return (
    <div className={styles.card}>
      <div>{icon}</div>
      <div className={styles.title}>{name}</div>
    </div>
  );
};

export default SingleAI;
