import React from "react";
import { Nav, Navbar, Dropdown } from "react-bootstrap";
import styles from "./UserInfo.module.css";
const UserInfo = ({ name, userId, phoneNumber }) => {
  const displayName = name || phoneNumber || "Welcome User!";

  const logoutHandler = () => {
    localStorage.clear();
    window.location.assign("/");
    const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.oneaichat.com`;
  }
  };

  return (
   


        <Nav className="ms-auto">
          <Dropdown align="end">
            
            <Dropdown.Toggle variant={null} id="dropdown-basic" className={styles.customToggle} >
            <span>Welcome User! &nbsp;</span> 
            </Dropdown.Toggle>

            <Dropdown.Menu  className="dropdown-menu"  >
              <Dropdown.Item href="/chat">Chat</Dropdown.Item>
              <Dropdown.Item onClick={logoutHandler}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>


  );
};

export default UserInfo;