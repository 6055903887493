import React from "react";

const Apple = () => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3744 7.50391C19.2653 7.56519 16.6672 8.85841 16.6672 11.7258C16.7897 14.9959 19.9459 16.1427 20 16.1427C19.9459 16.2039 19.5235 17.7049 18.2724 19.2783C17.2795 20.6336 16.1775 22 14.504 22C12.9121 22 12.3407 21.0967 10.504 21.0967C8.53147 21.0967 7.97335 22 6.46314 22C4.78967 22 3.60599 20.5603 2.55896 19.2178C1.19871 17.4606 0.0425398 14.703 0.00172334 12.0553C-0.0257834 10.6522 0.274128 9.27304 1.03544 8.10158C2.10998 6.46614 4.02835 5.35595 6.1233 5.31935C7.72845 5.2708 9.15703 6.30777 10.1366 6.30777C11.0754 6.30777 12.8305 5.31935 14.8163 5.31935C15.6735 5.32014 17.9592 5.55173 19.3744 7.50391ZM10.0009 5.03921C9.71515 3.75792 10.504 2.47663 11.2387 1.65931C12.1774 0.670887 13.6601 0 14.9388 0C15.0204 1.28129 14.5031 2.53791 13.5785 3.45312C12.7489 4.44154 11.3203 5.18565 10.0009 5.03921Z"
        fill="#747681"
      />
    </svg>
  );
};

export default Apple;
