import React, { useState,useEffect } from "react";
import styles from "./Chatbox.module.css";

const ChatTextbox = (props) => {
  const [message, setMessage] = useState("");
  const { chatHandler,isResponsePending, setIsResponsePending } = props;

  const [question, setQuestion] = useState("");
  const [isLoader, setLoader] = useState(false);
  const chatTextboxChangeHandler = (e) => {
    if (!isResponsePending) {
      setMessage(e.target.value);
    }
  };
const searchQuestionHandler=()=>{
  const newChat = {
    message: message,
    sender: "guest",
    receiver: "system",
    timestamp: new Date().getTime(),
  };
  chatHandler(newChat);
  setMessage("");
  setIsResponsePending(true);
  
}
  const chatTextboxKeyDownHandler = (e) => {
    if (e.key === "Enter" && !isResponsePending) {
      const newChat = {
        message: message,
        sender: "guest",
        receiver: "system",
        timestamp: new Date().getTime(),
      };
      chatHandler(newChat);
      setMessage("");
      setIsResponsePending(true);
    }
  };
  
{/*SpeechRecognition Start */}
const [isListening, setIsListening] = useState(false);
const [recognition, setRecognition] = useState(null);

useEffect(() => {
  if (!recognition && (window.SpeechRecognition || window.webkitSpeechRecognition)) {
      const newRecognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
      newRecognition.continuous = true;
      newRecognition.interimResults = true;

      newRecognition.onresult = (event) => {
          const current = event.resultIndex;
          const transcript = event.results[current][0].transcript;
          setMessage(transcript);
          setQuestion(transcript);
      };

      newRecognition.onend = () => {
          setIsListening(false);
      };

      newRecognition.onerror = (event) => {
          console.error('Speech recognition error:', event);
          setIsListening(false);
      };

      setRecognition(newRecognition);
  }
}, [recognition]);

const toggleListening = () => {
  if (isListening) {
      setIsListening(false);
      recognition.stop();
  } else {
      setIsListening(true);
      recognition.start();
  }
};
{/*SpeechRecognition End */}


return (
    <div className={`${styles.wrapper} row align-items-center`}>
     
      <div className="col-lg-9">
        <input
          type="text"
          className={styles.chatTextbox}
          placeholder="What will you create today?"
          value={message}
          onChange={chatTextboxChangeHandler}
          onKeyDown={chatTextboxKeyDownHandler}
          disabled={isResponsePending} // Disable the input field when response is pending
        />
         <div className="col ml-1" style={{ float:"right",paddingRight:"1rem",marginTop:"-32px" }}>
  
        <i class="fa-solid fa-arrow-right fa-beat fa-lg"  onClick={searchQuestionHandler} />
        </div>
     
      </div>
      {/* <div className="col ml-1">
      <i className="fa fa-paint-brush" aria-hidden="true" />
      
      </div>
      <div className="col ml-1">
        
      {isListening 
             ? <i className="fa fa-microphone" aria-hidden="true" onClick={toggleListening} />
             : <i className="fa fa-microphone-slash" aria-hidden="true" onClick={toggleListening} />
            }
      </div>
      <div className="col ml-1">
      <i className="fa fa-paperclip" aria-hidden="true" />
     
      </div> */}
    </div>
  );
};

export default ChatTextbox;
