import React from "react";

const Alert = (props) => {
  const { type, message, onClose } = props;
  return (
    <div
      className={`alert alert-${type} alert-dismissible fade show`}
      role="alert"
    >
      {message}
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
        onClick={() => onClose(false)}
      ></button>
    </div>
  );
};

export default Alert;
