import { useQueryClient } from "react-query";
import usePostRequest from "api/usePostRequest";

const useCreateChatWithRoom = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const successHandler = (res) => {
    queryClient.invalidateQueries("COUNT_BY_ROOM_OF_AI");
    queryClient.invalidateQueries("ROOMS_OF_CHAT");
    if (onSuccess instanceof Function) {
      onSuccess(res);
    }
  };

  const errorHandler = (res) => {
    if (onError instanceof Function) {
      onError(res);
    }
  };

  return usePostRequest({
    key: "CREATE_CHAT_WITH_ROOM",
    url: "/chat/create-chat-with-room",
    onSuccess: successHandler,
    onError: errorHandler,
  });
};

export default useCreateChatWithRoom;
