import React, { useState } from 'react';
import { axiosInstance } from "config/axios";
const Phone= () => {
  const [pageRespondData, setPageRespondData] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      const response = await fetch('https://db.oneaichat.com/pay', {
      method: 'GET',
    });

      if (!response.ok) {
        throw new Error(`Error initiating payment: ${response.statusText}`);
      }
  
      const responseData = await response.json();
  
      // Check if responseData has the expected structure
      if (responseData && responseData.redirectUrl) {
        window.location.href = responseData.redirectUrl;
      } else {
        console.error('Unexpected response format:', responseData);
        // Handle unexpected response format, show a message, or redirect to an error page
      }
    } catch (error) {
      console.error('Payment initiation error:', error);
      // Handle the error, show a message, or redirect to an error page
    }
  };

  return (
    <>
      <form action="/pay" method="get" onSubmit={handleSubmit}>
        <section style={{ backgroundColor: '#eee' }}>
          <h3 className="text-center">PHONEPAY PAYMENT DEMO BY CODE 180 </h3>
          <div className="container py-5">
            <div className="row d-flex justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-4">
                <div className="card rounded-3">
                  <div className="card-body mx-1 my-2">
                    <div className="d-flex align-items-center">
                      <div>
                        <i className="fab fa-cc-visa fa-4x text-black pe-3"></i>
                      </div>
                      <div>
                        <p className="d-flex flex-column mb-0">
                          <b>Code 180 Demo Card</b><span className="small text-muted">**** 7420</span>
                        </p>
                      </div>
                    </div>
                    <div className="pt-3">
                      <div className="d-flex flex-row pb-3">
                        <div className="rounded border border-primary border-2 d-flex w-100 p-3 align-items-center"
                          style={{ backgroundColor: 'rgba(18, 101, 241, 0.07)' }}>
                          <div className="d-flex align-items-center pe-3">
                            <input className="form-check-input" type="radio" name="radioNoLabelX" id="radioNoLabel11"
                              value="" aria-label="..." checked />
                          </div>
                          <div className="d-flex flex-column">
                            <p className="mb-1 small text-primary">Total amount due</p>
                            <h6 className="mb-0 text-primary">INR-1000</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center pb-1">
                      <a href="#!" className="text-muted">Go back</a>
                      <button type="submit" className="btn btn-danger btn-lg">Pay amount</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
      <section style={{ backgroundColor: '#d5d4d4' }}>
        <div className="container py-5">
          <div className="row d-flex justify-content-center">
            <h3 className="text-center">CHECKOUT RESPONSE</h3>
            {/* Display the response data received from the server */}
            <p>{pageRespondData}</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Phone;
