export const publicReply = [
  { message: "hi", reply: "hello" },
  { message: "how are you?", reply: "I am fine." },
  { message: "tell me about you", reply: "I am OneAI." },
  { message: "good morning", reply: "Good morning" },
  { message: "good afternoon", reply: "Good afternoon" },
  { message: "good evening", reply: "Good evening" },
  { message: "bye", reply: "Have a nice day" },
  { message: "Are you human?", reply: ["No, I am not human.", "I am a AI."] },
  {
    message: "Are you a robot?",
    reply: ["No, I am not a robot.", "I am a AI."],
  },
];
