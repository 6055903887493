import usePostRequest from "api/usePostRequest";

const useLogin = (onSuccess, onError) => {
  const successHandler = (res) => {
    const { token, user } = res?.data ?? {};
    if (token) {
      // Only set isLoggedIn to true if a token is received
      localStorage.setItem("authUser", JSON.stringify({ token, ...user }));
      if (onSuccess instanceof Function) {
        onSuccess(res);
      }
    } else {
      // Handle the case where the response doesn't contain a valid token
      if (onError instanceof Function) {
        onError(new Error("Invalid user details"));
      }
    }
  };

  return usePostRequest({
    key: "LOGIN_USER",
    url: "/signin",
    onSuccess: successHandler,
    onError,
  });
};

export default useLogin;
