import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ChatBoard from "../ChatBoard";
import useCreateChatWithRoom from "hooks/useCreateChatWithRoom";
import useCreateChat from "hooks/useCreateChat";
import useGetPaginatedChats from "hooks/useGetPaginatedChats";
import { axiosInstance } from "config/axios";
import axios from 'axios';
import styles from "./ChatTextbox.module.css";
import "./ChatTextbox.module.css";
import { depricated } from "./Depricated";

const ChatTextbox = (props) => {
  const params = useParams();
  const roomId = params?.roomId ?? "";
  const navigate = useNavigate();
  const { aiName, isSubscribed, chatCounts  } = props;
  const category =props.category;
  const [chatCategory, setChatCategory] = useState(category); 
  const roomStatus = props.roomStatus;
  const userId = props.userId;
  const [question, setQuestion] = useState("");
  const [chatImage, setChatImage] = useState(false);
  const [chatDoc, setChatDoc] = useState(false);
  const [chatVideo, setChatVideo] = useState(false);
  const [chatAudio, setChatAudio] = useState(false);
  const [chatInf, setChatInf] = useState(false);
  const [chats, setChats] = useState([]);
  const [showMessages, setShowMessages] = useState(false);
  const [isBannerVisible, setIsBannerVisible] = useState(true); // State to manage banner visibility
  const [freeImageChat, setFreeImageChat] = useState(null);  
  const [freeChat, setFreeChat] = useState(null);
  const [showButtons, setShowButtons] = useState(true);
  const [fileUrl, setFileUrl] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [filePreviewURL, setFilePreviewURL] = useState(null);
  const [isLoader, setLoader] = useState(false);
  const [latestChat, setLatestChat] = useState(false);
  const [contectCleared, setcontectCleared] = useState(false);
  const [isNewChat, setNewChat] = useState(false);
  const checkFreeChatCount = async () => {
    const userFreeChat = await axiosInstance.post(`/freechatcount`);
    setFreeChat(userFreeChat?.data?.chatcount)
    setFreeImageChat(userFreeChat?.data?.imagecount)
  }
  const [showInputBox, setShowInputBox] = useState(true);

  // from here the code for depricated models 

  const [depricatedMessage, setDepricatedMessage] = useState("");
  const [isDepricated, setIsDepricated] = useState(false);
  useEffect(() => {
    setIsDepricated(false)
    // Find the deprecated item and update state accordingly
    depricated.forEach((item) => {
      if (item.model === aiName) {
        // setDepricatedModel(item.model);
        setDepricatedMessage(item.message);
        setIsDepricated(true);
      }
    })
  });


    // till here the code for the depricated model 


  useEffect(() => {
    const redirectUrl = localStorage.getItem("redirectUrl");
    if (redirectUrl) {
      // Redirect URL is present, hide input box and show "Continue to Chat" button
      setShowInputBox(false);
    }
  }, []); // Run this effect once on component mount

  const continueToChatHandler = () => {
    localStorage.removeItem("redirectUrl");
    // Navigate to the chat page
  window.location.href="/chat"
    // Remove the redirect URL from local storage

  };


  const sendFile = async (fileToSend, fileName, question) => {
    setChatVideo(true);
  //  const fs = require('fs');
  console.log(question, "question-----------------------");
    const formData = new FormData();   
    formData.append('file', fileToSend);
    formData.append('query', question);
    formData.append('roomId', roomId);
    formData.append('userId', userId);
    formData.append('chatbot', aiName);
    formData.append('category', category);
    formData.append('isSubscribed', isSubscribed);
    formData.append('chatVideo', chatVideo);
    console.log(question, "formData------------------");
    
    const userStr = localStorage.getItem("authUser");
    const user = userStr ? JSON.parse(userStr) : "";
    try {
      setQuestion('');
      setFileName('');
      setFile(null);
      setLoader(true);
      const response = await axiosInstance.post(
        "/chat/create-chat-with-file",
        formData
      );
      
     console.log(response, "response---------------------");
      if (response.data.status === "success") {
        // Handle successful upload logic here
        const newChatMessage = response.data.chat.answer; // Assuming the chat message is in the 'question' field
        const newChat = {
          sender: "system",
          message: newChatMessage,
          id: response?.data?.chat?._id,
          chatType: 'new',
          timestamp: new Date(response?.data?.chat?.createdAt).getTime(),
        }; 
        setFreeChat(response?.data?.totalCount);
        setLoader(false);
        setChats((e) => [newChat, ...e]);
      //  setChats((prevChats) => [...prevChats, newChat]);
        const newRoom = response?.data?.chat?.roomId;
        if(newRoom){
          navigate(`/chat/${newRoom}`, { replace: true });
        }
      } else {
        console.error("File upload failed:", response.data.error);
      }
      const fileInput = document.getElementById('file-input');
      if (fileInput) {
        fileInput.value = null;
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  //console.log(chatCounts, "chatCounts----------------------------");
  const handleSendClick = async (question) => {    
    if (file) {
      const fileName = file.name; // Extract the filename from the File object
      await sendFile(file, fileName, question);
      URL.revokeObjectURL(filePreviewURL); // Clean up object URL to free memory
      setFilePreviewURL(null); // Reset the preview URL
      setFile(null); // Reset the selected file
    }
  };
  const handleGearClick = () => {
    setIsBannerVisible(false); // Hide the banner
    navigate("/payment-success"); // Navigate to payment success page
  };

  const closeMessage = () => {
    setShowMessages(false);
   };
  
   const clearInput = async() => {  
    if (isChatsByRoom) {
      if ((oldChats.length > 0) && (oldChats[0].question != "Context Cleared")) {       
       console.log(isNewChat, "oldChats[0].question");
        if(isNewChat.message != 'Context Cleared'){
          const newChat = {
            sender: "guest",
            message: 'Context Cleared',
            image: '',
            chatType: 'new',
            timestamp: new Date().getTime(),
          };
          setNewChat(newChat);
          setChats((e) => [newChat,...e]);  
          const response = await axiosInstance.post(
            "/chat/magicbrush",
            {userId, roomId}
          );
        }
      }
    }
};

  
  
const handleKeyDown = (e) => {
    if (e.key === "Enter" && question.trim() !== "") {
        searchQuestionHandler();
    }
};

  const onCreateChatSuccess = (res) => {
  
    
    //const apiAnswer = res?.data?.response?.result;
    const apiAnswer=res?.data?.chat?.answer;
    console.log(res?.data?.chat,"res?.data?.chat?");
    const newChat = {
      sender: "system",
      message: apiAnswer,
      chatType: 'new',
      id: res?.data?.chat?._id,
      timestamp: new Date(res?.data?.chat?.createdAt).getTime(),
    }; 
    console.log(res?.data?.status, "res?.data?.status---------------------------");
    if(res?.data?.status == 'success'){
      setFreeChat(res?.data?.totalCount);
      setChats((e) => [newChat, ...e]); 
    }     
    else{
      setShowMessages(true);
    }  
  };
  const chatMutate = useCreateChat(onCreateChatSuccess);
 // console.log(res, "res");
  const onSuccess = (res) => {
    console.log("onCreateChatSuccess-Start");
    console.log(res.data, "onsuccess");
    const apiAnswer=res?.data?.chat?.answer;
    //const apiAnswer= res?.data?.response?.result;
    // console.log(res?.data, "apiAnswer-res?.data?.response?----------------------------");
    // console.log(apiAnswer, "apiAnswer-generate-image----------------------------");
    const apiQuestion = res?.data?.response?.query;
  
    const roomId = res?.data?.chat?.roomId;
    const newChat = {
      sender: "system",
      message: apiAnswer,
      id: res?.data?.chat?._id,
      chatType: 'new',
      timestamp: new Date(res?.data?.chat?.createdAt).getTime(),
    };    
    console.log(res?.data,"res?.data?.chat?.status");
    if(res?.data?.status == 'success'){
      setFreeChat(res?.data?.totalCount);
      console.log(freeChat, "users-freeChat-------------------------");
      setChats((e) => [newChat, ...e]);
      navigate(`/chat/${roomId}`, { replace: true });    
    }       
    else{
      setShowMessages(true);
    }  
  };
  const chatWithRoomMutate = useCreateChatWithRoom(onSuccess);

  const onChatSuccess = () => {
    setChats([]);
  };

  const {
    data: oldChats,
    isSuccess: isChatsByRoom,
    isLoading: isChatLoader,
    isFetching: isChatFetching,
    fetchNextPage: fetchNextChatPage,
    hasNextPage: hasNextChatPage,
  } = useGetPaginatedChats(roomId, onChatSuccess);

  const isChatLoading = isChatLoader || isChatFetching;

  let oldChatList = [];
  if (isChatsByRoom) {
    oldChats?.forEach((c) => {
      const newAnswer = {
        sender: "system",
        message: c?.answer,
        question: c?.question,
        image: c?.image && c?.image != '' ? c?.image : '',
        id: c?._id,
        deleted: c?.deleted,
        chatType: 'old',
        roomId: c?.roomId,
        status: c?.like,
        timestamp: new Date(c?.createdAt).getTime() + 1,
      };
      oldChatList?.push(newAnswer);
      const newQuestion = {
        sender: "guest",
        message: c?.question,
        id: c?._id,
        chatType: 'old',
        image: c?.image && c?.image != '' ? c?.image : '',
        deleted: c?.deleted,
        status: c?.like,
        roomId: c?.roomId,
        timestamp: new Date(c?.createdAt).getTime(),
      };
      oldChatList?.push(newQuestion);
    });
  }

  const isLoading = chatMutate?.isLoading || chatWithRoomMutate?.isLoading;

  const questionHandler = (e) => {
    if (e?.target?.disabled || isLoading) {
      return;
    }
    setQuestion(e.target.value);
  };

  const searchQuestionHandler = async() => {
   
    if (!question && !file) {
      // No message or file to send
      return;
    }
  
    if (file && !question) {
      // User selected a file but didn't type a message
    
      return;
    }
    const newChat = {
      sender: "guest",
      message: question,
      image: fileName,
      chatType: 'new',
      timestamp: new Date().getTime(),
    };
    setNewChat(newChat);
    setChats((e) => [newChat, ...e]);
    if(file) {    
      await handleSendClick(question);
    }
    else{
    console.log('API Request Parameters:', { question, roomId });
    if (roomId) {
      if(category == 'Art/Design'){
        chatMutate.mutate({ question, roomId, userId, chatbot: aiName, category:'designing', chatImage: chatImage, chatAudio, chatDoc,chatInf,chatCounts, isSubscribed });
      }
      else{
        chatMutate.mutate({ question, userId, chatbot: aiName, roomId,category:chatCategory, chatImage: chatImage, chatAudio,chatDoc,chatInf, chatCounts, isSubscribed});
      }
    
    } else {
      if(category == 'Art/Design'){
        chatWithRoomMutate.mutate({ question, userId,chatbot: aiName ,category: 'designing', chatImage: chatImage, chatAudio, chatDoc,chatInf,chatCounts, isSubscribed });
      }
      else{
        chatWithRoomMutate.mutate({ question, userId, chatbot: aiName ,category: chatCategory, chatImage: chatImage, chatAudio,chatDoc,chatInf, chatCounts, isSubscribed });
      }
      
    //}   
  } 
}
setQuestion("");
    // setFile(null);
    // setFileName('');
  };

  const scrollId = "auth-chat-wrapper";


{/*SpeechRecognition Start */}
const [isListening, setIsListening] = useState(false);
const [recognition, setRecognition] = useState(null);
console.log(isLoader, "isLoader--------------------");
useEffect(() => {
  checkFreeChatCount();
  if (!recognition && (window.SpeechRecognition || window.webkitSpeechRecognition)) {
      const newRecognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
      newRecognition.continuous = true;
      newRecognition.interimResults = true;

      newRecognition.onresult = (event) => {
          const current = event.resultIndex;
          const transcript = event.results[current][0].transcript;
          setQuestion(transcript);
      };

      newRecognition.onend = () => {
          setIsListening(false);
      };

      newRecognition.onerror = (event) => {
          console.error('Speech recognition error:', event);
          setIsListening(false);
      };

      setRecognition(newRecognition);
  }
}, [recognition]);

useEffect(() => {
  if((category == 'Audio/Music') || (category == 'Video')){
    setShowButtons(false);
  }
  else{
    setShowButtons(true);
  }
  setChatCategory(category);
  setChatDoc(false);
  setChatImage(false);
  setChatVideo(false);
  setChatAudio(false);
  setChatInf(false);
  setChats([]);
  setNewChat(false);
}, [category, aiName, roomId]);

const toggleListening = () => {
  if (isListening) {
      setIsListening(false);
      recognition.stop();
  } else {
      setIsListening(true);
      recognition.start();
  }
};

const handleFileChange = async (e) => {
  console.log("handle-fileChange");
  const selectedFile = e.target.files[0];
  const formData = new FormData();   
  formData.append('file', selectedFile);
  const response = await axiosInstance.post(
    "/chat/upload-file",
    formData
  );
  
  // if (selectedFile && selectedFile.type === "application/pdf") {
    if (selectedFile) {
    const newFileUrl = `https://oneaichat.com/${selectedFile.name}`;
    setFileUrl(newFileUrl); 
    setFile(selectedFile);
    setFileName(selectedFile.name);
    
    // No need to create an object URL for the preview
  } else {
    console.error('Invalid file type. Please upload a PDF.');
  }
};
const closeFileButton = () => {
  setFileName('');
  setFile(null);
  setFileUrl(''); // Clear the file URL as well
  const fileInput = document.getElementById('file-input');
  if (fileInput) {
    fileInput.value = null;
  }
};
useEffect(() => {
  // Perform any actions you want when fileName changes
  // For example, you can console.log the fileName
  console.log('FileName changed:', fileName, isLoader);
}, [fileName, file, isLoader, chatVideo]);

const handleChatImage = (generate) => {
  setChatDoc(false);
  setChatImage(false);
  setChatVideo(false);
  setChatAudio(false);
  setChatInf(false);
  console.log(generate, "generate--------------------");
  if(generate == 'audio'){
    if(chatAudio){
      setChatCategory(category);
      setChatAudio(false);
    }else{
      setChatCategory('Audio/Music');
      setChatAudio(true);
    }
   
  }
  else if(generate == 'video'){
    if(chatVideo){
      setChatCategory(category);
      setChatVideo(false);
    }else{
      setChatCategory('Video');
      setChatVideo(true);
    }
  }
  else if(generate == 'image'){
    if(chatImage){
      setChatImage(false);
    }else{
      setChatImage(true);
    }
  }
  else if(generate == 'doc'){
    console.log('Clicked doc')
    if(chatDoc){
      setChatDoc(false);
    }else{
      setChatDoc(true);
    }
  } 
  else if(generate == 'inf'){
    console.log('Clicked inf')
    if(chatInf){
      setChatInf(false);
    }else{
      setChatInf(true);
    }
  } 
}
const handleAttachClick = () => {
  setFile(null);
  console.log("clicked-attachment-icon")
  document.getElementById('file-input').click();
  console.log("clicked-attachment-icon-second-one")
};

  useEffect(() => {
    const element = document.getElementById(scrollId);
    if (element?.scrollHeight) {
      element.scrollTop = element.scrollHeight;
    }
    
  }, [chats?.length, isChatsByRoom, fileName, isLoader, chatVideo]);

  return (
    <div className={styles.chatWrapper}>
     
      <div className={styles.searchChat}>
        <ChatBoard
          chats={[...chats, ...oldChatList]}
          scrollId={scrollId}
          fetchNextChatPage={fetchNextChatPage}
          hasNextChatMore={hasNextChatPage}
          isChatLoading={isChatLoading}
          userId= {userId}
          roomId= {roomId}
          contextCleared = {contectCleared}
        />
          {
            showButtons && (
              <div class="row chat-buttons">        
              <div class="col-md-2 generate-image chat-image"  onClick={() => (!isSubscribed && freeImageChat < 1 && chatCounts.freeChat < 10) ? handleChatImage('image') :  (isSubscribed) ? handleChatImage('image') : '' }><button disabled={(!isSubscribed && freeImageChat < 1 && chatCounts.freeChat < 10) ? false : (isSubscribed) ? false : true} className={chatImage ? styles.active : ''}>Generate Image</button></div> 
              <div class="col-md-2 generate-audio chat-image"  onClick={() => (!isSubscribed && chatCounts.audioCount < 1 && chatCounts.freeChat < 10) ? handleChatImage('audio') :  (isSubscribed) ? handleChatImage('audio') : '' }><button disabled={(!isSubscribed && chatCounts.audioCount < 1 && chatCounts.freeChat < 10) ? false : (isSubscribed) ? false : true} className={chatAudio ? styles.active : ''}>Generate Audio</button></div> 
              {/* <div class="col-md-2 generate-video chat-image"  onClick={() => (!isSubscribed && chatCounts.videoCount < 1 && chatCounts.freeChat < 10) ? handleChatImage('video') :  (isSubscribed) ? handleChatImage('video') : '' }><button disabled={(!isSubscribed && chatCounts.videoCount < 1 && chatCounts.freeChat < 10) ? false : (isSubscribed) ? false : true} className={chatVideo ? styles.active : ''}>Generate Video(Coming Soon)</button></div>  */}

              <div class="col-md-2 generate-video chat-image"  onClick={() => (!isSubscribed && chatCounts.videoCount < 1 && chatCounts.freeChat < 10) ? handleChatImage('video') :  (isSubscribed) ? handleChatImage('video') : '' }><button disabled={(!isSubscribed && chatCounts.videoCount < 1 && chatCounts.freeChat < 10) ? false : (isSubscribed) ? false : true} className={chatVideo ? styles.active : ''}>Generate Video</button></div>  
              
              <div class="col-md-2 generate-doc chat-image"  onClick={() => (!isSubscribed && chatCounts.freeChat < 10) ? handleChatImage('doc') :  (isSubscribed) ? handleChatImage('doc') : '' }><button disabled={(!isSubscribed && chatCounts.freeChat < 10) ? false : (isSubscribed) ? false : true} className={chatDoc ? styles.active : ''}>Generate Document</button></div>          
              <div class="col-md-2 generate-doc chat-image"  onClick={() => (!isSubscribed && chatCounts.freeChat < 10) ? handleChatImage('inf') :  (isSubscribed) ? handleChatImage('inf') : '' }><button disabled={(!isSubscribed && chatCounts.freeChat < 10) ? false : (isSubscribed) ? false : true} className={chatInf ? styles.active : ''}>Live Information</button></div>   
              </div> 
            )
          }        
          {/* from here the code of depricated models logic  */}
          {isDepricated ? (
          <div
            className={styles.chatTextboxWrapper}
            style={{ background: "#8D7A19", cursor: "not-allowed" }}
          >
            {showInputBox && (
              <>
                <input
                  type="text"
                  className={styles.textbox}
                  id="search-chat"
                  value={depricatedMessage}
                  style={{
                    background: "#8D7A19",
                    cursor: "not-allowed",
                    color: "#F9F9B4",
                  }}
                  disabled
                />
              </>
            )}
          </div>
        ) : (
          <div
            className={styles.chatTextboxWrapper}
            style={{ border: showInputBox ? "1px solid #ccc" : "none" }}
          >
            {showInputBox && (
              <>
                <i
                  className="fa fa-paint-brush"
                  aria-hidden="true"
                  style={{ marginLeft: "-18px", marginTop: "5px" }}
                  onClick={clearInput}
                />
                <input
                  type="text"
                  placeholder="What will you create today?"
                  className={styles.textbox}
                  id="search-chat"
                  disabled={isLoading}
                  value={question}
                  onChange={questionHandler}
                  onKeyDown={handleKeyDown}
                  style={{ marginLeft: "15px" }}
                />
                {fileName && (
                  <div>
                    <div className="file-name">
                      <a target="_blank" href={fileUrl}>
                        {fileName}
                      </a>
                    </div>
                    <button
                      className="file-popup-close"
                      onClick={closeFileButton}
                    >
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        class="Dismissable_icon__nU8Xb"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.207 4.793a1 1 0 0 0-1.414 1.414L10.586 12l-5.793 5.793a1 1 0 1 0 1.414 1.414L12 13.414l5.793 5.793a1 1 0 0 0 1.414-1.414L13.414 12l5.793-5.793a1 1 0 0 0-1.414-1.414L12 10.586 6.207 4.793Z"
                        ></path>
                      </svg>
                    </button>
                  </div>
                )}
                {isLoading || isLoader ? (
                  <label htmlFor="serach-chat">
                    <i className="fa fa-spinner fa-spin" />
                  </label>
                ) : (
                  <label
                    className="cursor-pointer"
                    htmlFor="search-chat"
                    style={{
                      display: "flex",
                      gap: "12px",
                      float: "right",
                      top: "-18px",
                      position: "relative",
                    }}
                  >
                    <input
                      type="file"
                      id="file-input"
                      accept=".pdf"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    <i
                      className="fa fa-paperclip"
                      aria-hidden="true"
                      onClick={handleAttachClick}
                    />
                    {isListening ? (
                      <i
                        className="fa fa-microphone"
                        aria-hidden="true"
                        onClick={toggleListening}
                      />
                    ) : (
                      <i
                        className="fa fa-microphone-slash"
                        aria-hidden="true"
                        onClick={toggleListening}
                      />
                    )}
                    <i
                      class="fa-solid fa-arrow-right fa-beat fa-lg"
                      style={{ marginTop: "7px" }}
                      onClick={searchQuestionHandler}
                    />
                  </label>
                )}
              </>
            )}
            {!showInputBox && (
              <center>
                <button
                  className={styles.continueButton}
                  onClick={continueToChatHandler}
                >
                  Continue to Chat
                </button>
              </center>
            )}
          </div>
        )}
{/* till here the code of depricated model logic  */}
        <div className="row align-items-center free-chat">
  {!isSubscribed && (
    <div>
      <span style={{ color: "#cb9512" }}>
        Daily (free) - {10 - freeChat} left
      </span>
    </div>
  )}
</div>

        <div className={`freechatmessage-box ${showMessages ? 'active' : ''}`}>
          <button className="close-btn" onClick={closeMessage}>&times;</button>
          Please subcribe to continue chat.
        </div>
        <div className="container mt-5">
  <p className="text" style={{marginTop:"-3rem"}}>
    <br/>
   <b> OneAIChat aggregates open-source and copyrighted content including image/video/audio/document/text generation for user convenience. We don't own or transfer ownership of any provided content. Users are advised to fact-check information, respect copyrights, and use generated content responsibly. For more details, visit our <a href="/terms-use"style={{textDecoration:"underline"}} >Terms of Service</a>  and <a href="/privacy-policy" style={{textDecoration:"underline"}}>Privacy Policy</a> .
   </b> </p>
</div>
      </div>
      
    </div>
  );
};

export default ChatTextbox;
