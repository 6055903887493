import useGetRequest from "api/useGetRequest";
import { axiosInstance } from "config/axios";
import { useNavigate } from "react-router-dom";

const useRoomCountOfAi = (userIds, aiName, onSuccess, onError) => {
  const navigate = useNavigate();
  const successHandler = async (res) => {
    const authUserString = localStorage.getItem("authUser");
    if (authUserString) {
      const authUser = JSON.parse(authUserString);
      // Now you can check if authUser has the expected properties or values
      let phoneNumber = '';
      let email = '';
      if (authUser) {
          if (authUser.email) {
              email = authUser.email;
          }
          if (authUser.phoneNumber) {
              phoneNumber = authUser.phoneNumber; // Fix: Assign to phoneNumber instead of email
          }
          const response = await axiosInstance.post(`/getuserbyemail`, { email, phoneNumber });
          try {
              // Your logic with the result goes here...
              if (response.data.token) {
                  localStorage.setItem("loggedIn", true);
              } else {
                  localStorage.setItem('token', '');
                  localStorage.setItem('authUser', '');
                  localStorage.setItem("loggedIn", false);
                  navigate('/login');
              }
          } catch (error) {
              console.error('Error fetching user data:', error);
            }
        }
    }
  
    if (onSuccess instanceof Function) {
      onSuccess(res);
    }
  };

  const errorHandler = (res) => {
    if (onError instanceof Function) {
      onError(res);
    }
  };

  const select = (res) => {
    if (res?.data?.status !== "success") {
      return [];
    }
    const rooms = res?.data?.rooms ?? [];
    return rooms;
  };

  console.log(userIds, "userIds----------------------");

  return useGetRequest({
    key: ["COUNT_BY_ROOM_OF_AI", userIds, aiName],
    url: "/room/room-count-of-ai",
    method: "post",
    variables: { userIds, aiName },
    select: select,
    onSuccess: successHandler,
    onError: errorHandler,
  });
};

export default useRoomCountOfAi;
