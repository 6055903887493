export const slides = [
  {
    id: 1,
    description:
      "They not only understand what I say but read between the lines  and also give me ideas of my own.",
    authorName: "Kristin Watson",
    authorTitle: "Medical Assistant",
    authorPic: "assets/img/avatar/av-5.png",
  },
  {
    id: 2,
    description:
      "They not only understand what I say but read between the lines and also give me ideas of my own.",
    authorName: "Brooklyn Simmons",
    authorTitle: "President of Sales",
    authorPic: "assets/img/avatar/av-6.png",
  },
  {
    id: 3,
    description:
      "They not only understand what I say but read between the lines and also give me ideas of my own.",
    authorName: "Annette Black",
    authorTitle: "Nursing Assistant",
    authorPic: "assets/img/avatar/av-7.png",
  },
  {
    id: 4,
    description:
      "They not only understand what I say but read between the lines and also give me ideas of my own.",
    authorName: "Floyd Miles",
    authorTitle: "Dog Trainer",
    authorPic: "assets/img/avatar/av-8.png",
  },
  {
    id: 5,
    description:
      "They not only understand what I say but read between the lines and also give me ideas of my own.",
    authorName: "Kristin Watson",
    authorTitle: "Medical Assistant",
    authorPic: "assets/img/avatar/av-5.png",
  },
  {
    id: 6,
    description:
      "They not only understand what I say but read between the lines and also give me ideas of my own.",
    authorName: "Brooklyn Simmons",
    authorTitle: "President of Sales",
    authorPic: "assets/img/avatar/av-6.png",
  },
  {
    id: 7,
    description:
      "They not only understand what I say but read between the lines and also give me ideas of my own.",
    authorName: "Annette Black",
    authorTitle: "Nursing Assistant",
    authorPic: "assets/img/avatar/av-7.png",
  },
  {
    id: 8,
    description:
      "They not only understand what I say but read between the lines and also give me ideas of my own.",
    authorName: "Floyd Miles",
    authorTitle: "Dog Trainer",
    authorPic: "assets/img/avatar/av-8.png",
  },
  {
    id: 9,
    description:
      "They not only understand what I say but read between the lines and also give me ideas of my own.",
    authorName: "Esther Howard",
    authorTitle: "Nursing Assistant",
    authorPic: "assets/img/avatar/av-5.png",
  },
  {
    id: 10,
    description:
      "They not only understand what I say but read between the lines and also give me ideas of my own.",
    authorName: "Kristin Watson",
    authorTitle: "Medical Assistant",
    authorPic: "assets/img/avatar/av-5.png",
  },
];

export const slidesTwo = [
  {
    id: 1,
    description:
      "They not only understand what I say but read between the lines and also give me ideas of my own.",
    authorName: "Kristin Watson",
    authorTitle: "Medical Assistant",
    authorPic: "assets/img/avatar/av-5.png",
  },
  {
    id: 2,
    description:
      "They not only understand what I say but read between the lines and also give me ideas of my own.",
    authorName: "Brooklyn Simmons",
    authorTitle: "President of Sales",
    authorPic: "assets/img/avatar/av-6.png",
  },
  {
    id: 3,
    description:
      "They not only understand what I say but read between the lines and also give me ideas of my own.",
    authorName: "Annette Black",
    authorTitle: "Nursing Assistant",
    authorPic: "assets/img/avatar/av-7.png",
  },
  {
    id: 4,
    description:
      "They not only understand what I say but read between the lines and also give me ideas of my own.",
    authorName: "Floyd Miles",
    authorTitle: "Dog Trainer",
    authorPic: "assets/img/avatar/av-8.png",
  },
  {
    id: 5,
    description:
      "They not only understand what I say but read between the lines and also give me ideas of my own.",
    authorName: "Esther Howard",
    authorTitle: "Nursing Assistant",
    authorPic: "assets/img/avatar/av-5.png",
  },
  {
    id: 6,
    description:
      "They not only understand what I say but read between the lines and also give me ideas of my own.",
    authorName: "Kristin Watson",
    authorTitle: "Medical Assistant",
    authorPic: "assets/img/avatar/av-5.png",
  },
  {
    id: 7,
    description:
      "They not only understand what I say but read between the lines and also give me ideas of my own.",
    authorName: "Brooklyn Simmons",
    authorTitle: "President of Sales",
    authorPic: "assets/img/avatar/av-6.png",
  },
  {
    id: 8,
    description:
      "They not only understand what I say but read between the lines and also give me ideas of my own.",
    authorName: "Annette Black",
    authorTitle: "Nursing Assistant",
    authorPic: "assets/img/avatar/av-7.png",
  },
  {
    id: 9,
    description:
      "They not only understand what I say but read between the lines and also give me ideas of my own.",
    authorName: "Floyd Miles",
    authorTitle: "Dog Trainer",
    authorPic: "assets/img/avatar/av-8.png",
  },
  {
    id: 10,
    description:
      "They not only understand what I say but read between the lines and also give me ideas of my own.",
    authorName: "Esther Howard",
    authorTitle: "Nursing Assistant",
    authorPic: "assets/img/avatar/av-5.png",
  },
];
