import usePostRequest from "api/usePostRequest";
const useChangePassword = (onSuccess, onError) => {
  const successHandler = (res) => {
    // Handle the response from the server after sending a forgot password request
    if (onSuccess instanceof Function) {
      onSuccess(res);
    }
    
    else {
        console.error("onSuccess is not a function", onSuccess);
      }
  };

  return usePostRequest({
    key: "CHANGE_PASSWORD",
    url: "/change-password", // Adjust the URL according to your API endpoint
    onSuccess: successHandler,
    onError,
    });

};

export default useChangePassword;
