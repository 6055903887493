import React from "react";

const Google = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1258 11.2139C21.1258 10.4225 21.0603 9.84497 20.9185 9.24609H11.6973V12.818H17.1099C17.0008 13.7057 16.4115 15.0425 15.102 15.9408L15.0836 16.0603L17.9992 18.2738L18.2012 18.2936C20.0563 16.6145 21.1258 14.1441 21.1258 11.2139"
        fill="#4285F4"
      />
      <path
        d="M11.6976 20.6248C14.3494 20.6248 16.5755 19.7692 18.2016 18.2934L15.1024 15.9405C14.273 16.5073 13.1599 16.903 11.6976 16.903C9.10043 16.903 6.89609 15.224 6.11031 12.9033L5.99513 12.9129L2.96347 15.2122L2.92383 15.3202C4.53888 18.4644 7.85634 20.6248 11.6976 20.6248Z"
        fill="#34A853"
      />
      <path
        d="M6.10908 12.9034C5.90174 12.3045 5.78175 11.6628 5.78175 10.9997C5.78175 10.3366 5.90174 9.695 6.09817 9.09612L6.09268 8.96857L3.02303 6.63232L2.92259 6.67914C2.25695 7.98388 1.875 9.44905 1.875 10.9997C1.875 12.5504 2.25695 14.0155 2.92259 15.3203L6.10908 12.9034"
        fill="#FBBC05"
      />
      <path
        d="M11.6977 5.09664C13.5419 5.09664 14.7859 5.87733 15.4953 6.52974L18.2671 3.8775C16.5648 2.32681 14.3494 1.375 11.6977 1.375C7.85637 1.375 4.53889 3.53526 2.92383 6.6794L6.09942 9.09638C6.89612 6.77569 9.10047 5.09664 11.6977 5.09664"
        fill="#EB4335"
      />
    </svg>
  );
};

export default Google;
