// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatMessage_optionsButton__Cca2G {
  /* styles for the three-dot button */
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  /* Adjust size as needed */
  width: 10px;
  height: 10px;
  
}

.ChatMessage_optionsMenu__erBrL {
 
  position: absolute; 
 
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 14px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Drop shadow */
  z-index: 10000;  /* To ensure it stacks above other content */
  min-width: 120px; /* Set a min-width for your menu */
}

.ChatMessage_optionsMenu__erBrL ul {
  position: relative;
  margin: 0;
  padding: 5px 0; /* Add padding on the top and bottom */
  z-index: 10001;
}

.ChatMessage_optionsMenu__erBrL li {
  background: white;
  padding: 6px 15px; /* Add padding for each menu item */
  cursor: pointer;
}

.ChatMessage_optionsMenu__erBrL li:hover {
  background-color: #f0f0f0; /* Light grey background on hover */
}

/* Show the menu when showOptions is true */
.ChatMessage_showOptions__Xhw4t .ChatMessage_optionsMenu__erBrL {
  display: block; /* Show the menu */
}

`, "",{"version":3,"sources":["webpack://./src/pages/Chat/components/ChatBoard/ChatMessage.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,UAAU;EACV,SAAS;EACT,0BAA0B;EAC1B,WAAW;EACX,YAAY;;AAEd;;AAEA;;EAEE,kBAAkB;;EAElB,sBAAsB,EAAE,sBAAsB;EAC9C,mBAAmB,EAAE,oBAAoB;EACzC,qCAAqC,EAAE,gBAAgB;EACvD,cAAc,GAAG,4CAA4C;EAC7D,gBAAgB,EAAE,kCAAkC;AACtD;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,cAAc,EAAE,sCAAsC;EACtD,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB,EAAE,mCAAmC;EACtD,eAAe;AACjB;;AAEA;EACE,yBAAyB,EAAE,mCAAmC;AAChE;;AAEA,2CAA2C;AAC3C;EACE,cAAc,EAAE,kBAAkB;AACpC","sourcesContent":[".optionsButton {\n  /* styles for the three-dot button */\n  cursor: pointer;\n  background: none;\n  border: none;\n  padding: 0;\n  margin: 0;\n  /* Adjust size as needed */\n  width: 10px;\n  height: 10px;\n  \n}\n\n.optionsMenu {\n \n  position: absolute; \n \n  border: 1px solid #ccc; /* Light grey border */\n  border-radius: 14px; /* Rounded corners */\n  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Drop shadow */\n  z-index: 10000;  /* To ensure it stacks above other content */\n  min-width: 120px; /* Set a min-width for your menu */\n}\n\n.optionsMenu ul {\n  position: relative;\n  margin: 0;\n  padding: 5px 0; /* Add padding on the top and bottom */\n  z-index: 10001;\n}\n\n.optionsMenu li {\n  background: white;\n  padding: 6px 15px; /* Add padding for each menu item */\n  cursor: pointer;\n}\n\n.optionsMenu li:hover {\n  background-color: #f0f0f0; /* Light grey background on hover */\n}\n\n/* Show the menu when showOptions is true */\n.showOptions .optionsMenu {\n  display: block; /* Show the menu */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionsButton": `ChatMessage_optionsButton__Cca2G`,
	"optionsMenu": `ChatMessage_optionsMenu__erBrL`,
	"showOptions": `ChatMessage_showOptions__Xhw4t`
};
export default ___CSS_LOADER_EXPORT___;
