import useGetRequest from "api/useGetRequest";

const useChatRoomByUser = (userId, ai, onSuccess, onError) => {
  const successHandler = (res) => {
    if (onSuccess instanceof Function) {
      onSuccess(res);
    }
  };

  const errorHandler = (res) => {
    if (onError instanceof Function) {
      onError(res);
    }
  };

  const select = (res) => {
    if (res?.data?.status !== "success") {
      return [];
    }

   // const rooms = res?.data; 
   const rooms = [];
    return rooms;
  };

  return useGetRequest({
    key: ["USER_ROOMS_OF_CHAT", userId, ai],
    url: "/room/chatroom-by-user",
    variables: { userId, ai },
    method: "post",
    onSuccess: successHandler,
    onError: errorHandler,
  });
};

export default useChatRoomByUser;
