import React, { useState ,useEffect,useRef } from "react";
import styles from "./Chat.module.css";
import Link from "components/Link";
import { axiosInstance } from "config/axios";
import useRoomCountOfAi from "hooks/useRoomCountOfAi";
import useChatRoomByUser from "hooks/useChatRoomByUser";
import useGetLoggedInUser from "hooks/useGetLoggedInUser";
import useGetAuthUser from "hooks/useGetAuthUser";
import { useNavigate, useParams } from "react-router-dom";
import InfiniteTopScroll from "components/InfiniteTopScroll";
import Rooms from "pages/Chat/components/Rooms";
const Chatheader =(props)=>{
  const { setPopupVisible: setPopupVisibleProp } = props; // Rename setPopupVisible to setPopupVisibleProp
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionPrompt, setSubscriptionPrompt] = useState(false);
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const history = useNavigate();
  const { showRoombar, toggleRoombar } = props;
  const aiName = localStorage.getItem("selectedAiCategory");
  console.log("aiName from local storage:", aiName);
  
  const selectedCategory = localStorage.getItem("selectedCategory"); // Replace with your logic to get selectedCategory

  const currentauthUser = JSON.parse(localStorage.getItem("authUser"));

  const { data: countOfRooms } = useRoomCountOfAi(currentauthUser._id, aiName);

  const { ai, onClose } = props;
  const userId = currentauthUser._id;

  const { roomId } = useParams();
  const [isRoomSelected, setRoomSelected] = useState('');

  const {
    data: rooms,
    isLoading: isRoomLoading,
    fetchNextPage: fetchNextRoomPage,
    isFetching: isRoomFetching,
    hasNextPage: hasNextRoomPage,
  } = useChatRoomByUser(userId, ai);

  // useEffect(() => {
  //   fetchNextRoomPage();
  // }, [fetchNextRoomPage]);

  const isRoomLoader = isRoomFetching || isRoomLoading;
  const chat_rooms = rooms?.data?.rooms;

  const openRoomHandler = (roomId, chatbot, category) => {
    if (roomId) {
      history(`/chat/${roomId}`, { replace: true, state: { roomCategory: category } });
    } else {
      history(`/chat`, { replace: true });
    }
  };

  useEffect(() => {
    setRoomSelected(roomId);
  }, [roomId]);

  let endMessage = <div />;

  if (!hasNextRoomPage && chat_rooms?.length > 20) {
    endMessage = <div className={styles.endMessage}>No More Records</div>;
  } else if (isRoomLoader) {
    endMessage = (
      <div className={styles.loader}>
        <i className="fa fa-spinner fa-pulse" />
      </div>
    );
  } else if (!chat_rooms?.length && !isRoomLoader) {
    endMessage = <div className={styles.endMessage}>No Record Found</div>;
  }
  const openRoombar = (roomId) => {
    // Your logic for opening the room bar
    console.log('Opening room bar for room:', roomId);
  };

// Function to handle clicking on rooms
const handleRoomsClick = () => {
  // Close the mobile menu
  console.log("handle click")
  setMobileMenuOpen(false);
};

// Function to handle clicking on the selected room
const handleSelectedRoomClick = (roomId, chatbot, category) => {
  // Close the mobile menu
  console.log("clickhandle ")
  setMobileMenuOpen(false);
  // Your existing logic to handle selected room click
};
const navbarRef = useRef(null);


const closeRoombar = (name) => {
  setMobileMenuOpen(false);
};

useEffect(() => {
  const closeMenuOnClickOutside = (event) => {
    // Check if the click occurred outside the navbar
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setMobileMenuOpen(false); // Close the navbar
    }
  };

  // Attach the event listener to the document body
  document.body.addEventListener('click', closeMenuOnClickOutside);

  // Cleanup: remove the event listener when component unmounts
  return () => {
    document.body.removeEventListener('click', closeMenuOnClickOutside);
  };
}, []); // Empty dependency array ensures the effect runs only once

const logoutHandler = () => {
  localStorage.clear();
  window.location.assign("/");
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.oneaichat.com`;
  }

};
const navigate = useNavigate()
const goBack = () => {
  navigate(-1); // Go back to the previous page
};
const closeMessage = () => {
  setShowPopup(false)
  setShowMessage(false);
 };
 const [showMessage, setShowMessage] = useState(false);

 const userAuth = useGetAuthUser();
 //if(userAuth){
  // const email = userAuth.email? userAuth.email : '';
  // const phoneNumber = userAuth.phoneNumber? userAuth.phoneNumber : '';
  // const { data } = useGetLoggedInUser(email, phoneNumber);
  // if(data && data.status){
  //  const isLoggedIn = data.data.token;
  //  if(isLoggedIn === ''){  
  //    localStorage.clear()         
  //    history('/login');
  //  }     
  // }   
 //}

useEffect(() => {
  axiosInstance.get(`/subscriptions`)
  .then((response) => {
    console.log('Response data:', response.data);
  if (response.data.status) {
  setSubscriptions(response.data.subscriptions);
  } else {
  console.error('Failed to fetch subscriptions');
  }
  })
  .catch((error) => {
  console.error('Error fetching subscriptions:', error);
  });
  }, [])
  // // const currentauthUser = JSON.parse(localStorage.getItem("authUser"));
  // const handlePayClick = async (e, planId, planName) => {
  //   // // Check for subscription
  //   // try {
  //   //   let authUser = '';
  //   //   const authUserString = localStorage.getItem("authUser");
  //   //   if (authUserString) {
  //   //     authUser = JSON.parse(authUserString);
  //   //   }
  //   //   const { email } = authUser;
  
  //   //   const response = await axiosInstance.post(`/check-subscription`, { user_email: email });
      
  //   //   if (response.data.hasSubscription) {
  //   //     // Display a user-friendly message to inform the user.
      
  //   //     setSubscriptionPrompt(true);  // Show the subscription prompt message
  //   //     setShowMessage(true);
      
  //   //     e.preventDefault(); // Prevent further processing or redirection
  //   //     setTimeout(() => { 
  //   //       window.location.href = '/payment_success'; // Redirect to login page after 3 seconds
  //   //     }, 0.900);
  //   //     return; // Stop the function here if the user has a subscription
  //   //   } else {
  //   //     // Proceed with the payment logic
  //   //     // (Your Stripe payment logic can be placed here if needed)
  
  //   //     const subscriptionData = { 
  //   //       plan_id: planId,
  //   //       plan_name: planName,
  //   //       user_email: email
  //   //     };
  
  //   //     const saveResponse = await axiosInstance.post(`/save-subscription`, subscriptionData);
  //   //     if (saveResponse.data.success) {
  //   //       console.log('Data saved successfully');
  //   //     } else {
  //   //       console.error('Failed to save subscription');
  //   //       e.preventDefault();
  //   //     }
  //   //   }
  //   // } catch (err) {
  //   //   console.error(err);
  //   //   e.preventDefault(); // Prevent further processing in case of an error
  //   // }
  //   // setShowPaymentSuccess(true);
  // }
  const [loginPrompt, setLoginPrompt] = useState(false);

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
// 
// const handlePayClick = async (e, planId, planName, planPrice, stripe_id) => {
//   // Check if the user is already subscribed
//   const authUser = JSON.parse(localStorage.getItem("authUser"));

//   try {
//     const subscriptionCheckResponse = await axiosInstance.post('/check-user-subscription', { user_id: authUser?._id });

//     if (subscriptionCheckResponse?.data?.hasSubscription) {
//       setSubscriptionPrompt(true);  // Show the subscription prompt message
//          setShowMessage(true);

         
// return;
//     }

const handlePayClick = async (e, planId, planName, planPrice, stripe_id) => {
  // Check if the user is already subscribed
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  try {
    const subscriptionCheckResponse = await axiosInstance.post('/check-user-subscription', { user_id: authUser?._id });
    if (subscriptionCheckResponse?.data?.hasSubscription) {
      setSubscriptionPrompt(true);  // Show the subscription prompt message
         setShowMessage(true);
         setTimeout(()=>
         {
          setShowMessage(false);
         },2000)
return;
    }

    // User is not subscribed, proceed with payment
    const isPurchaseCompleted = localStorage.getItem('purchaseCompleted');
    if (!isPurchaseCompleted) {
      localStorage.setItem('stripeId', stripe_id);
      localStorage.setItem('selectedPlanName', planName);
      localStorage.setItem('selectedPlanId', planId);
      localStorage.setItem('selectedPlanPrice', planPrice);
    }

    if (!authUser) {
      console.error('AuthUser not found in local storage');
      setLoginPrompt(true); // Show the login prompt message
      setShowMessage(true);
      e.preventDefault(); // Prevent form submission
      setTimeout(() => {
        window.location.href = '/login'; // Redirect to login page after 3 seconds
      }, 1000);
      return;
    } else {
      // Open the modal when pay button is clicked
      setShowPopup(true);
      setSelectedPaymentOption(null); // Reset selected payment option
    }
  } catch (error) {
    console.error('Error checking user subscription:', error);
    // Handle the error (show a message, log, etc.)
  }
};
 
const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
const [showPopup, setShowPopup] = useState(false);

const handlePaymentOptionClick = (paymentOption) => {
  setSelectedPaymentOption(paymentOption);
};

const handleProceed = () => {
  if (selectedPaymentOption === "Stripe") {
    handleStripePayment();
  } else if (selectedPaymentOption === "BillDesk") {
    handleBilldeskPayment();
  } else if (selectedPaymentOption === "PhonePe") {
    handlePhonePePayment();
  }
};

const handlePhonePePayment = async () => {
  try {
    const selectedPlanId = localStorage.getItem('selectedPlanId');
    const selectedPlanName = localStorage.getItem('selectedPlanName');
    const selectedPlanPrice = localStorage.getItem('selectedPlanPrice');
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    const email = authUser.email
    const userid=authUser._id;
   const url = `https://db.oneaichat.com/pay?planId=${selectedPlanId}&planName=${selectedPlanName}&planPrice=${selectedPlanPrice}&email=${email}&userid=${userid}`;
  const response = await fetch(url, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`Error initiating payment: ${response.statusText}`);
    }

    const responseData = await response.json();

    // Check if responseData has the expected structure
    if (responseData && responseData.redirectUrl) {
      window.location.href = responseData.redirectUrl;
    } else {
      console.error('Unexpected response format:', responseData);
      // Handle unexpected response format, show a message, or redirect to an error page
    }
  } catch (error) {
    console.error('Payment initiation error:', error);
    // Handle the error, show a message, or redirect to an error page
  }
};

  {/*Billdesk starts  */}
  const [amount, setAmount] = useState('');
  const [orderId, setOrderId] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');

 const handleBilldeskPayment = async () => {
  const selectedPlanId = localStorage.getItem('selectedPlanId');
  const selectedPlanName = localStorage.getItem('selectedPlanName');
  const selectedPlanPrice = localStorage.getItem('selectedPlanPrice');
  const authUserString = localStorage.getItem('authUser');


  const authUser = authUserString ? JSON.parse(authUserString) : null;

  // Access user information from the authUser object
  const userEmail = authUser.email;
  const userid=authUser._id;
 console.log(userid)
  try { 
    const response = await fetch(`https://db.oneaichat.com/api/initiate-payment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({selectedPlanPrice: parseFloat(selectedPlanPrice), orderId, customerName, customerEmail: userEmail, selectedPlanId: selectedPlanId,  selectedPlanName: selectedPlanName,userid:userid }),
    });

    const result = await response.json();
    
    const authToken = result.flowConfig.authToken;
    const bdOrderId = result.flowConfig.bdOrderId;

    const flow_config = {
      merchantId: "KALETECHPL",
      bdOrderId: bdOrderId,
      authToken: authToken,
      childWindow: false,
      returnUrl: "https://db.oneaichat.com/api/callback",
      retryCount: 0
    };
    var responseHandler = function(txn) {
      if (txn.response) {
          alert("callback received status:: ", txn.status);
          alert("callback received response:: ", txn.response)//response handler to be implemented by the merchant
        }
        console.log("txn.response"+txn.response)
      };
    const config = {
      responseHandler: responseHandler,
   
      flowConfig: flow_config,
      flowType: "payments"
    };

    window.loadBillDeskSdk(config);

    console.log("loadinggg");
  } catch (error) {
    console.error('Error initiating payment:', error);
    // alert('Internal server error. Please try again later.');
  }
}
{/*Billdesk ends */}


const handleStripePayment = () => {
const stripeId = localStorage.getItem('stripeId');

const form = document.createElement('form');
form.action = 'https://stripe.oneaichat.com/create-checkout-session';
form.method = 'POST';

const input = document.createElement('input');
input.type = 'hidden';
input.name = 'stripeId';
input.value = stripeId;

form.appendChild(input);

document.body.appendChild(form);

form.submit();
};


    return(
        <div className={styles.topHeader} style={{ boxShadow: "1px 1px #ccc",backgroundColor:"#f8f9fa"}}>
        <Link to="/">
            <img src="/assets/img/logo/2.jpeg" alt=""  style={{ width: '70px', height: '60px', display: 'block',marginLeft:"-18px",borderRadius:"35px" }} />
           </Link>
           <li className="Staticnm" style={{
    color: "black",
    position: "absolute",
   
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "40px",
    fontWeight: "bold",
    top:"3.5%",
    listStyleType: "none", // Add this line to remove the marker
}}>
    OneAIChat
</li>
          <div className={styles.navIitem} >
             <div>
               <Link to ="/profile">
              <i className="fa-regular fa-user fa-lg"></i> 
                  </Link>
            </div>
       
            </div>
            <div className={styles.navItem} onClick={logoutHandler}  >
              <i className="fa-solid fa-right-from-bracket fa-lg"></i> 
            </div>
           
            <div className={styles.sidebar}>
            <div />
            <div  className={styles.sidebar}>
              <div>
              <Link to="/chat">
                <img src="/assets/img/chat/chat.png" alt="Chat Logo" />
                </Link>
              </div>
              <div className={styles.iconWrapper} onClick={() => setPopupVisible(!isPopupVisible)} style={{marginLeft:"1rem",marginTop:"15px"}}>
              <i class="fa-solid fa-dollar-sign fa-xl"></i>
             </div>
               <div  className={`iconWrapper`}  style={{marginTop:"15px",position:"relative",marginLeft:"1rem"}}>
               <Link to="/setting">
                <i className="fa-solid fa-gear fa-lg"></i>
                </Link>
            </div>
            </div> 
          </div>
    {/*Mobile View  */}
          <header className={styles.toppHeader} style={{ boxShadow: "1px 1px #ccc", backgroundColor: "#f8f9fa" }}  ref={navbarRef}>
      <div className="container">
        <div className="navbar navbar-expand-xl justify-content-between">
          {/* <Link to="/">
            <img src="/assets/img/logo/2.jpeg" alt="" style={{ width: '70px', height: '71px', display: 'none', marginLeft: "-5px" }} />
          </Link> */}
          <div className={"goback"} style={{ position: "relative",marginLeft:"19rem"}} onClick={goBack}>
            <i className="fa-solid fa-arrow-left fa-xl"></i>
          </div>
          <div className={`navbar-toggler d-block d-xl-none`} onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? (
            
          <span>&times;</span>
        ) : (
          <span>&#9776;</span>
        )}
                </div>

          <div className={`collapse navbar-collapse ${isMobileMenuOpen ? 'show' : ''}`}>
          <Link to="/">
            <img src="/assets/img/logo/2.jpeg" alt="" style={{ width: '70px', height: '71px', display: 'block', marginLeft: "10px",position:"absolute",marginTop:"10px",borderRadius:"35px" }} />
          </Link>
            <div className="navbar-right d-flex align-items-center gap-4" style={{flexDirection:"column",marginTop:"8rem"}}>
            {/* <InfiniteTopScroll
        id="chat-rooms"
        inverse={false}
        endMessage={endMessage}
        // items={(rooms ?? [])?.map((room) => {
          items={(chat_rooms ?? [])?.map((room) => {
          return (
            <div
              key={room?._id}
               className={`${styles.roomTitle} ${
                isRoomSelected === room?._id ? "bg-success" : '' }`}
              onClick={() => openRoomHandler(room?._id, room?.chatbot, room?.category)}
            >
              {room?.title}
            </div>
          );
        })}
        next={fetchNextRoomPage}
        hasMore={hasNextRoomPage}
      /> */}
      <Rooms userId={currentauthUser._id} category={selectedCategory}  ai={aiName}   onRoomClick={handleSelectedRoomClick}  onRoomsClick={handleRoomsClick}  onClose={closeRoombar}/>
      {/* Rendering rooms based on user and category */}
              <div className={styles.iconWrapper} onClick={() => setPopupVisible(!isPopupVisible)}>
               <p>Subscriptions</p>
              </div>
              
              <div className={styles.iconWrapper} style={{ position: "relative",marginLeft:"-185px" }}>
                <Link to="/setting">
                  <p>Setting</p>
                </Link>
              </div>
              <div className={styles.iconWrapper} style={{ position: "relative",marginLeft:"-205px" }}>
              <Link to="/chat">
                  <p>Chat</p>
                </Link>
              </div>
              <div className={styles.iconWrapper} style={{ position: "relative",marginLeft:"-190px" }}>
              <Link to="/profile">
                  <p>Profile</p>
                </Link>
              </div>
              <div className={"User"} style={{ position: "relative",paddingTop:"12px",marginLeft:"-5rem" }} onClick={logoutHandler}>
             <p>Logout</p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </header>
  {/*Mobile View  */}
           {/* Popup subscription open */}
           
      {
  isPopupVisible && (
    
    <div className={styles.overlay}>
    <div className={styles.popup}>
    {/* <button className={styles.popp}  onClick={() => setPopupVisible(false)} >
    <i className="fas fa-times"></i>X
    </button> */}


<button className={styles.popp}  onClick={() => {
       setShowPopup(false)
       setPopupVisible(false)
    }} >
    {/* <i className="fas fa-times"></i> */}X
    </button>


    {subscriptionPrompt && 
    <div className={`subscription-box ${showMessage ? 'active' : ''}`}>
        <button className="close-subscription-btn" onClick={closeMessage}>&times;</button>
        User already has a subscription!
    </div>
    }
      {subscriptions.map((subscription) => (
        <div
          className="aai-price-table mb-4"
          data-aos="fade-up"
          data-aos-delay="100"
          key={subscription._id}
        >
          <div className="row g-4 align-items-center justify-content-between">
            <div className="col-xl-4 col-lg-6">
              <div className="aai-price-pack">
                <h3 className="aai-price-pack-name">{subscription.plan_name}</h3>
                <p className="aai-price-pack-desc">
                  {subscription.plan_description}
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <ul className="aai-price-lists list-unstyled">
                {/* ... your list items with SVGs ... */}
              </ul>
            </div>
            <div className="col-xl-3 col-lg-6">
              <div className="aai-price-value" style={{marginLeft:"-10px",fontSize:"2rem"}}>
                <h2 className="aai-price"style={{fontSize:"1.5rem"}} >₹{subscription.plan_price}</h2>
              </div>
            </div>
            <div className="col-xl-2 col-lg-6">
            <div>
               
               <input type="hidden" name="stripeId" value={subscription.stripe_id} />
               <button 
                 className="aai-btn btn-pill-solid" 
                 id="submit" 
                 role="link" 
                onClick={(e) => handlePayClick(e, subscription.plan_id, subscription.plan_name, subscription.plan_price,subscription.stripe_id)}
               >
                 PAY
               </button>
           
               </div>
            </div>
          </div>
        </div>
      ))}
      {/* Popup for payment options */}
      {showPopup && (
         
         <div className="popup">
           <h2>Select Payment Option</h2>
           <br/>
           <button className="popup-close" onClick={closeMessage} style={{fontSize:"larger",
          //  marginTop:"-150px",
           marginLeft:"355px",
           position:"fixed",
           color:'black'}}>X</button>
           <button onClick={() => handlePaymentOptionClick("PhonePe")}
            className={selectedPaymentOption === "PhonePe" ? "selected" : ""}
           >PhonePe</button>
         <button
           onClick={() => handlePaymentOptionClick("BillDesk")}
           className={selectedPaymentOption === "BillDesk" ? "selected" : ""}
         >
           BillDesk
         </button>
         <button
           onClick={() => handlePaymentOptionClick("Stripe")}
           className={selectedPaymentOption === "Stripe" ? "selected" : ""}
         >
           Stripe
         </button>
         <br/>
         <br/>
         <br/>
         
         <center>
           <button onClick={handleProceed}>Proceed</button>
         </center>
         </div>
       )}
      <button className="aai-btn btn-pill-solid"  onClick={() => setPopupVisible(false)}>Close</button>
    </div>
    </div>
  )
} 
{/* Popup subscription close */} 
        </div>
      
    )
}
export default Chatheader;