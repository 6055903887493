import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";

import { slides, slidesTwo } from "data/testimonials";

const Testimonials = () => {
  const breakpoints = {
    1: {
      slidesPerView: 1.1,
    },
    768: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 2.5,
    },
    1200: {
      slidesPerView: 3,
    },
    1400: {
      slidesPerView: 3.5,
    },
    1600: {
      slidesPerView: 4,
    },
    1900: {
      slidesPerView: 4.5,
    },
  };

  const getSlideUI = (slide) => {
    return (
      <SwiperSlide className="swiper-slide" key={slide?.id}>
        <div className="aai-testimonial-item">
          <div className="aai-testimonial-item-top">
            <div className="d-flex align-items-center justify-content-between">
              <div className="aai-testimonial-quoate">
                <svg
                  width="15"
                  height="11"
                  viewBox="0 0 15 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.13566 10.6719C8.42933 9.30632 8.07617 7.82304 8.07617 6.22204C8.07617 4.5975 8.50585 3.25548 9.36521 2.196C10.2246 1.13652 11.5607 0.606773 13.3736 0.606773V2.83169C12.7379 2.83169 12.2729 2.97296 11.9786 3.25548C11.6843 3.53801 11.5372 4.07953 11.5372 4.88003V5.23319H14.1152V10.6719H9.13566ZM1.82511 10.6719C1.11878 9.30632 0.76562 7.82304 0.76562 6.22204C0.76562 4.5975 1.1953 3.25548 2.05466 2.196C2.91402 1.13652 4.25015 0.606773 6.06304 0.606773V2.83169C5.42735 2.83169 4.96236 2.97296 4.66805 3.25548C4.37376 3.53801 4.2266 4.07953 4.2266 4.88003V5.23319H6.80468L6.80468 10.6719H1.82511Z"
                    fill="#747681"
                  />
                </svg>
              </div>
              <div className="aai-awards-stars">
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </div>
            </div>
            <p className="aai-testimonial-desc">{slide?.description}</p>
          </div>
          <div className="aai-testimonial-item-bottom d-flex align-items-center">
            <div>
              <img
                src={slide?.authorPic}
                className="aai-testimonial-author-img"
                alt=""
              />
            </div>
            <div>
              <h4 className="aai-testimonial-author-name">
                {slide?.authorName}
              </h4>
              <span className="aai-testimonial-author-title">
                {slide?.authorTitle}
              </span>
            </div>
          </div>
        </div>
      </SwiperSlide>
    );
  };

  return (
    <section
      className="aai-testimonials pb-120"
      style={{
        backgroundColor:"hsl(151.3deg 88.46% 89.8%)"
      }}
    >
      <div className="row justify-content-center">
        <div className="col-xl-8">
          <div className="aai-section-title mb-3 text-center mb-5">
            <h2 className="section-title" style={{color:"black"}}>Meet our Happy Clients</h2>
          </div>
        </div>
      </div>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        freeMode={true}
        speed={10000}
        autoplay={{
          delay: 1,
          reverseDirection: false,
          disableOnInteraction: false,
        }}
        loop={true}
        modules={[Autoplay]}
        slidesPerView={1}
        allowTouchMove={false}
        breakpoints={breakpoints}
        className="swiper testimoniaslSlider mb-4"
      >
        <div className="swiper-wrapper">
          {slides?.map((slide) => getSlideUI(slide))}
        </div>
      </Swiper>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        freeMode={true}
        speed={8000}
        autoplay={{
          delay: 1,
          reverseDirection: true,
          disableOnInteraction: false,
        }}
        loop={true}
        modules={[Autoplay]}
        slidesPerView={1}
        allowTouchMove={false}
        breakpoints={breakpoints}
        className="swiper testimoniaslSliderTwo"
      >
        <div className="swiper-wrapper">
          {slidesTwo?.map((slide) => getSlideUI(slide))}
        </div>
      </Swiper>
    </section>
  );
};

export default Testimonials;
