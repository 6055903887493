import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { axiosInstance } from 'config/axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const CheckToken = () => {
  const [tokenExists, setTokenExists] = useState(false);

  useEffect(() => {
    // Function to check if token exists
    const checkTokenExists = async () => {
      try {
        // Get the token from local storage
        const token = localStorage.getItem('token');

        // Make a POST request to the API endpoint to check if the token exists
        const response = await axiosInstance.post('/check-token', { token });

        // Update state based on the response
        setTokenExists(response.data.exists);
        if (response.data.exists) {
       localStorage.clear();
    window.location.assign("/");
         
          } 
        
      } catch (error) {
        console.error('Failed to check token:', error);
      }
    };

    // Call the function to check if token exists
    checkTokenExists();
  }, []);
  

  return null;
};


export default CheckToken;
