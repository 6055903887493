import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import './Login.css';


 const PrivacyPolicy = () => {
return(
 <div style={{width: "100%",float:"left"}}>
    <Header />
     <main className="wrapper">
      {/* Breadcrumb Start */}
      <section
        className="aai-breadcrumb"
        // style={{
        //   background: "url('assets/img/bg/bread-crumb-bg.jpeg') no-repeat center center/cover"
        // }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-auto">
              <div className="text-center">
                <h2 className="aai-page-title">Privacy Policy</h2>
                <ul className="aai-breadcrumb-nav d-flex align-items-center justify-content-center">
                  <li>
                  <a href="/" className="aai-breadcrumb-link text-decoration-underline">
                      Home
                    </a>
                  </li>
                  <li>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 16L18 12M18 12L14 8M18 12L6 12"
                        stroke="#DADADA"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </li>

                  <li>
                    <a href="" className="aai-breadcrumb-link"> Privacy Policy </a>
                  </li>
                </ul>
                <div className="privacy-policy-class">
                  <div style={{width: "100%",float:"left"}}>
                <h3 style={{float:"left"}}>OneAIChat Privacy Policy</h3>
                </div>
                  <div style={{width: "100%",float:"left"}}>This OneAIChat Privacy Policy (“Privacy Policy”) describes how your personal information is processed when you use OneAIChat, a platform that lets you communicate with bots powered by LLMs. This Privacy Policy applies to activities by Kaletech, Private limited. and its affiliates and subsidiaries (collectively “Kaletech,” “we” or “us”). The Kaletech Privacy Policy is Private limitedorporated by reference. Kaletech Private limited. is the data controller of your personal information and is responsible for providing you with this Privacy Policy.</div>


                  <h>Information We Collect, How It Is Used, & The Legal Basis for Processing</h>

                  <div style={{width: "100%",float:"left"}}><h>Account Information</h> 
                  We collect your email address and phone number to create an account and verify your identity. We also create a user ID for your OneAIChat account. If you are an existing Kaletech user, your name, Kaletech-associated email address, profile photo, and Kaletech user ID will be used to link your Kaletech account to your OneAIChat account. We may use this information to communicate with you about your account. This is so we can comply with our contractual obligations to you.</div>
                  <div style={{width: "100%",float:"left"}}><h>Contact List</h>. With your consent, OneAIChat may access and periodically sync your phone's contact list to make it easy for you to find and connect with your friends on OneAIChat. It is in our legitimate business interests to provide you with a customized experience.
                  <div style={{width: "100%",float:"left"}}><h>Device Information.</h> We collect your device type and operating system details for analytics and to troubleshoot product issues. This is so we can comply with our contractual obligations to you.</div>
                  <div style={{width: "100%",float:"left"}}><h>Third Party Application Login.</h> You can log into your OneAIChat account using third party applications (e.g., Google and Apple). When you log in using this method, we collect your name and email address associated with that third party application for authentication and identity verification purposes. This processing is necessary to comply with our contractual obligations to you.</div>

                  <div style={{width: "100%",float:"left"}}><h>Subscription Billing Data.</h> We use a third party service provider to collect and process your billing information when you subscribe to OneAIChat. The information our billing provider collects may Private limitedlude your name, billing address, your credit or debit card details, phone number, and email address. This processing is necessary to comply with our contractual obligations to you.</div>

                  <div style={{width: "100%",float:"left"}}><h>Advertising.</h> We collect information about you when you interact with OneAIChat ads to measure the effectiveness of our ad campaigns. This information Private limitedludes your IP address, advertising ID, and ad interaction information. It is in our legitimate business interest to advertise our platform. Where necessary, we will obtain your consent before engaging in this processing.</div>

                  <div style={{width: "100%",float:"left"}}><h>User Research.</h> We may ask you to help us test new features or participate in surveys to help enhance your OneAIChat experience. Your participation is voluntary and the data we collect is anonymized. With your consent, we may use your personal information to communicate with you.</div>

                  <div style={{width: "100%",float:"left"}}><h>Interactions with OneAIChat.</h> Our third party LLM providers and third party bot developers may receive details about your interactions with OneAIChat (Private limitedluding the contents of your chats, upvotes, etc.) to provide and generally improve their services, which they may process in their legitimate business interests. Your OneAIChat user account information (e.g., username and email) is not shared with any third party LLMs or developers. Third party developers that create bots on OneAIChat using APIs may view and store your chats on their servers in order to train their models.

                  Keep in mind, any information you provide to the bots on OneAIChat will be shared with third party LLM providers and developers powering the bots, and there is no need to share sensitive personal information with the bots (e.g., credit card information, social security information, etc.). For more information about the LLM providers and bot developers, please see the respective bot profiles.

                  For more information about our privacy and data protection practices, Private limitedluding how to exercise your privacy rights, please visit the Kaletech Privacy Policy.</div>
                  </div>
                
                </div>
                <h5>Contact Us</h5>
                  <p>Please contact our Data Protection Officer at support@oneaichat.com if you have questions about this Privacy Policy.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Breadcrumb End */}
      </main>
      <Footer />
 </div>
    )
 }
 export default PrivacyPolicy;