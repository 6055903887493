import useGetRequest from "api/useGetRequest";

const useGetLoggedInUser = (email, phoneNumber, onSuccess, onError) => {
    const successHandler = (res) => {
      if (onSuccess instanceof Function) {
        onSuccess(res);
      }
    };
  
    const errorHandler = (res) => {
      if (onError instanceof Function) {
        onError(res);
      }
    };
  
    return useGetRequest({
      key: ["USER_LOGGEDIN", email, phoneNumber],
      url: "/getuserbyemail",
      variables: { email, phoneNumber },
      method: "post",
      onSuccess: successHandler,
      onError: errorHandler,
    });
  };

export default useGetLoggedInUser;
