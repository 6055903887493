import React, { useState, useEffect } from "react";
import ChatBoard from "./ChatBoard";
import ChatTextbox from "./ChatTextbox";
import FreeChat from "./ChatAttempt";
import { publicReply } from "data/pubilcChat";
import styles from "./Chat.module.css";
import useCreateChatWithRoom from "hooks/useCreateChatWithRoom";
import { axiosInstance } from "config/axios";
import { useNavigate } from "react-router-dom";

const Chat = () => {
  const [chats, setChats] = useState([]);
  const [showMessages, setShowMessages] = useState(false);
  const [chatcount, setChatCount] = useState(0);
  const [isSubscribed, setSubscribed] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isResponsePending, setIsResponsePending] = useState(false); // Added state
  let currentauthUser = '';
  const authUserString = localStorage.getItem("authUser");
  if (authUserString) {
    currentauthUser = JSON.parse(authUserString);
  }

  const checkUserSubscription = async () => {
    const userSubscriptionResponse = await axiosInstance.post(`/check-user-subscription`, { user_id: currentauthUser._id });
    console.log(isSubscribed, "subcribed-landingpage-------------");
    if((userSubscriptionResponse?.data?.hasSubscription) && (userSubscriptionResponse?.data?.content?.payment_status != 'pending')){
      console.log(userSubscriptionResponse,"userSubscriptionResponse");
      setSubscribed(true);
    }   
  }
  const closeMessage = () => {
    setShowMessages(false);
   };
   let deviceId;
   const fetchDeviceId = async () => {
    try {
      const response = await axiosInstance.post(`/freechatcount`);      
      if (response.status) {
        setChatCount(response.data.chatcount);
        console.log(response.data.chatcount,"chatcount----------------");
      } else {
        console.error('Failed to fetch device ID');
      }
    } catch (error) {
      console.error('Error fetching device ID:', error);
    }
  };
  const onSuccess = async (res) => {
    console.log(res?.data?.chat, "frontend-chat-response-----------------------");
    const apiAnswer=res?.data?.chat;  
    const newChat = {
      sender: "system",
      message: apiAnswer,
      id: res?.data?.chat?._id,
      receiver: "user",
      timestamp: new Date(res?.data?.chat?.createdAt).getTime(),
    };     
    if ((res?.data?.status == 'success')) {
      setLoading(false);
      setIsResponsePending(false)
      setChats((oldChats) => [...oldChats, newChat]);
    }
    else if(res?.data?.status == 'error'){
      setLoading(false);
      setShowMessages(true);

    }
  };


  const chatWithRoomMutate = useCreateChatWithRoom(onSuccess);

  const getReplyOfMessage = (message) => {
    chatWithRoomMutate.mutate({ question: message, chatbot: "oneAi" ,category: "Somebody", chatImage: false, chatType: 'guest', isSubscribed }); 
    // const res = publicReply?.find(
    //   (e) => e?.message?.toLowerCase() === message?.toLowerCase()
    // );
    // if (typeof res?.reply === "string") {
    //   setTimeout(() => {
    //     const newChat = {
    //       message: res?.reply,
    //       sender: "system",
    //       receiver: "user",
    //       timestamp: new Date().getTime(),
    //     };
    //     setChats((oldChats) => [...oldChats, newChat]);
    //   }, 1000);
    // } else if (Array.isArray(res?.reply)) {
    //   setTimeout(() => {
    //     const newChat = res?.reply?.map((reply) => ({
    //       message: reply,
    //       sender: "system",
    //       receiver: "user",
    //       timestamp: new Date().getTime(),
    //     }));
    //     setChats((oldChats) => [...oldChats, ...newChat]);
    //   }, 1000);
    // }
  };
  console.log("chats - ", chats);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await checkUserSubscription();
        await fetchDeviceId();
        // Additional asynchronous calls if needed
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
    const element = document.getElementById("public-chat-wrapper");
    console.log(element);
    if (element?.scrollHeight) {
      element.scrollTop = element.scrollHeight;
    }
  }, [chats?.length]);

  const chatHandler = (newChat) => {
    console.log("home-page-chat", newChat.message);
    if(newChat.message != 'Context Cleared'){
      setLoading(true);
      setChats((oldChats) => [...oldChats, newChat]);
      getReplyOfMessage(newChat?.message);
      }
      else{
        setChats((oldChats) => [...oldChats, newChat]);
      }
    }

  return (
    <div className={`${styles.wrapper} text-white position-relative`}>
      {/* <ChatBoard chats={chats} scrollId="public-chat-wrapper" /> */}
      <ChatTextbox chatHandler={chatHandler} isResponsePending={isResponsePending} setIsResponsePending={setIsResponsePending} />
      <ChatBoard chats={chats} scrollId="public-chat-wrapper" />
      <FreeChat chatcount={chatcount} isSubscribed={isSubscribed}/>
      {isLoading && (
      <label htmlFor="serach-chat" class="search-chat-loading">
        <i className="fa fa-spinner fa-spin" style={{color: "black"}} />
      </label>
      )}
      <div className={`freechatmessage-box ${showMessages ? 'active' : ''}`}>
          <button className="close-btn" onClick={closeMessage}>&times;</button>
          Please logIn to continue chat.
      </div>
    </div>
  );
};

export default Chat;
