// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SingleAI_card__2xkaD {
  background-color: #fff;
  color: rgb(71, 70, 70);
  margin: 12px;
  width: 130px;
  border-radius: 10px;
  text-align: center;
  padding: 12px;
}

.SingleAI_title__QN27V {
  margin-top: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PublicChat/AIList/SingleAI.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,sBAAsB;EACtB,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".card {\n  background-color: #fff;\n  color: rgb(71, 70, 70);\n  margin: 12px;\n  width: 130px;\n  border-radius: 10px;\n  text-align: center;\n  padding: 12px;\n}\n\n.title {\n  margin-top: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `SingleAI_card__2xkaD`,
	"title": `SingleAI_title__QN27V`
};
export default ___CSS_LOADER_EXPORT___;
