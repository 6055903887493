import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import './Login.css';


 const TermsUse = () => {
return(
 <div>
    <Header />
     <main className="wrapper">
      {/* Breadcrumb Start */}
      <section
        className="aai-breadcrumb"
        // style={{
        //   background: "url('assets/img/bg/bread-crumb-bg.jpeg') no-repeat center center/cover"
        // }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-auto">
              <div className="text-center">
                <h2 className="aai-page-title">Terms Of Use</h2>
                <ul className="aai-breadcrumb-nav d-flex align-items-center justify-content-center">
                  <li>
                  <a href="/" className="aai-breadcrumb-link text-decoration-underline">
                      Home
                    </a>
                  </li>
                  <li>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 16L18 12M18 12L14 8M18 12L6 12"
                        stroke="#DADADA"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </li>

                  <li>
                    <a href="" className="aai-breadcrumb-link"> Terms Of Use </a>
                  </li>
                </ul>
                <div className="terms-use-class">

                <h>OneAIChat Terms of Use</h>
      <p>Welcome to OneAIChat!</p>

      <p>These OneAIChat Terms of Use (“Terms”) are an agreement entered between you and Kaletech, Private limited. and its affiliates (collectively Kaletech,” “we,” or “us”) in connection with your use of the OneAIChat service ("OneAIChat"). In these Terms, "you" refers both to you as an individual and to the entity you represent. By using OneAIChat, you consent to these OneAIChat Terms of Service.</p>
      <p>Kaletech's Kaletech Platform Terms of Service also apply to your use of OneAIChat. In the event of a conflict between the Kaletech Platform Terms of Service and the OneAIChat Terms of Service, the OneAIChat Terms of Service will apply. If you purchase a subscription to OneAIChat, then the Subscriber Terms shall apply as well. OneAIChat is considered part of the “Kaletech Platform” for purposes of the Kaletech Platform Terms of Service. Capitalized terms used but not defined in these OneAIChat Terms of Service have the meanings defined in the Kaletech Platform Terms of Service.</p>
      <p>IMPORTANT ARBITRATION NOTICE: IF YOU ARE IN THE INDIA, YOU AGREE THAT DISPUTES BETWEEN YOU AND kaletech WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION. WE EXPLAIN THIS PROCESS, SOME EXCEPTIONS, AND HOW YOU CAN OPT OUT OF ARBITRATION IN SECTION 10 OF THE kaletech PLATFORM TERMS OF SERVICE.</p>

      <ol>
          <li><h>A Platform for Open Exploration (OneAIChat).</h>
          <p>OneAIChat is a platform that enables you to explore and interact with various bots powered by third-party Large Language Models (“LLMs”) and developers, Kaletech Private limitedluding OpenAI and Anthropic. OneAIChat may also allow you to create your own bots powered by these third-party LLMs. You can use Bots for a variety of purposes, from learning, writing help, translation, programming help, summarization, entertainment, to many other things. Each bot has its own description in its profile and the name of the company, developer, or individual who created the bot. Usage guidelines for all bots can be found by visiting https://oneaichat.com/ or in your settings. OneAIChat is a platform that enables users to access various third-party bots, but OneAIChat does not provide and is not not responsible for the content or services available from these bots. </p>
          </li>
          <li><h>Age Requirements.</h>
          <p>Use of OneAIChat by anyone under 13 years of age is prohibited. You represent that you are at least the age of majority in the jurisdiction where you live or, if you are not, your parent or legal guardian must consent to these OneAIChat Terms of Service and affirm that they accept these Terms on your behalf and bear responsibility for your use. Bots accessed via OneAIChat may produce content that is not suitable for minors. If you are accepting these OneAIChat Terms of Service on behalf of someone else or an entity, you confirm that you have the legal authority to bind that person or entity to these Terms. </p>
          </li>
          <li><h>Your Use of OneAiChat.</h>
        <p>Subject to your compliance with these Terms, we grant you a personal, non-exclusive, non-transferable right to use OneAiChat and its bots.</p>
        <strong className="para">3.1 User Acknowledgments</strong></li>
        <ol>
          <li>Bots accessed via OneAiChat should not be relied upon during emergencies; bots may claim to perform actions for you in the real world but may have not taken any action besides responding to you;</li>
          <li>Bots accessed via OneAiChat may not generate accurate information and information provided may be out of date. You should independently verify responses or advice provided by any bots on OneAiChat. Kaletech does not provide any warranties or guarantees as to the accuracy of any information provided via OneAiChat;</li>
          <li>OneAiChat and bots on OneAiChat are subject to modification and alteration, and may contain errors, design flaws, or other issues;</li>
          <li>Use of OneAiChat or bots on OneAiChat may result in unexpected results, loss of data or communications, or other anticipated or unanticipated damage or loss to you;</li>
          <li>OneAiChat or bots on OneAiChat may not operate properly, be in final form, or be fully functional; your access to OneAiChat or the bots is not guaranteed and OneAiChat and the bots are provided on an AS IS basis;</li>
        </ol>

          <strong className="para">3.2 Restricted Uses.</strong> <span> You agree not to use OneAiChat or bots on OneAiChat to </span>:
        <ol>
          <li>Violate the rights of another party or any applicable laws;</li>
          <li>Violate any of these Terms or our policies, or policies of third-party providers that power the bots on OneAiChat which can be found in the bot profile or by visiting OneAiChat.com/about</li>
          <li>Reverse assemble, reverse compile, decompile, translate or otherwise attempt to discover the source code or underlying components of models, algorithms, and systems of OneAiChat, the bots, or their underlying technologies;</li>
          <li>Abuse, harm, interfere with, reverse engineer, or disrupt OneAiChat, the bots, or their underlying technologies, such as by accessing or using them in fraudulent or deceptive ways, introducing malware, or spamming, hacking, or bypassing any protective measures;</li>
          <li>Use OneAiChat or the bots in an automated fashion, such as by exceeding any rate limits set forth by us from time to time;</li>
          <li>Use OneAiChat or the bots to develop products, applications, services, foundation models or other large scale models that compete with OneAiChat, the bots, and their underlying technologies;</li>
          <li>Use any method to extract data from OneAiChat or the bots, Kaletech Private limitedluding web scraping, web harvesting, or web data extraction methods, other than as permitted by these OneAiChat Terms of Service; or</li>
          <li>Represent that Bot-Generated Content (as defined below) is human-generated.</li>
        </ol>
              
        <li><h>Your Content</h>
        <p className="para"><strong>4.1 Your Content.</strong>  <span> OneAiChat may allow you to ask questions, create bookmarks, vote or like, post, or share bot conversations within OneAiChat or outside of OneAiChat. All material that you upload, publish, or display within OneAiChat, and any results that you receive in response to your prompts (or other inputs) from bots accessible via OneAiChat (“Bot-Generated Content”), will collectively be considered “Your Content.”
          Other users may ask questions and receive responses from bots on OneAiChat that are similar to or the same as yours. The content that other users receive are not considered Your Content </span>:</p>
        <p className="para">4.2 Your Bots  <p>  OneAiChat may allow you to create bots powered by third-party LLMs or add your own bot to the OneAiChat platform 
        for users to interact with (each, a “Your Bots”). Your Bots will be associated with your OneAiChat profile name. You have no obligation to create a bot,
        but if you choose to create a bot you are responsible for ensuring the content is lawful and does not violate these Terms, our policies or the policies of any 
        third-party LLMs which power your bot. Kaletech may, in its sole discretion, remove your bot if it violates these Terms, our policies or the policies of any third-party LLMs which power your bot 
        or applicable law. Kaletech takes no responsibility for your bots, any of its content, or others use of your bots. If you choose to connect Your Bots to OneAiChat using our APIs, then the OneAiChat API Terms apply
        to your use of such APIs. If you choose to participate in the Creator Monetization Program, then the program terms will apply to your participation in the program. </p></p>

        <p className="para"><strong>4.3 Ownership.</strong> <span>You retain ownership of Your Content, subject to the non-exclusive rights granted below.</span></p>

        <p className="para"><strong>4.4 Your Responsibility.</strong>  <p>  You acknowledge and agree bots accessible via OneAiChat answer your questions based on knowledge derived from a variety of sources, and that Kaletech does not create or generate any Bot-Generated Content. OneAiChat provides access to several underlying technologies, Kaletech Private limitedluding third-party
          providers that use LLMs. An LLM is a machine learning system that processes and generates text. You agree that you are responsible for Your Content and Your Bots, Kaletech Private limitedluding for ensuring that they do not
          violate any applicable law, these Terms (Kaletech Private limitedluding the Restricted Uses in Section 3.3 above), our policies, or the policies of any third-party LLMs which power bots within OneAiChat.
          We reserve the right to block, remove, and/or permanently delete Your Content or Your Bots if they are in breach of these Terms, our policies, the policies of any third-party LLMs which power bots within OneAiChat, or violate any applicable law or regulation, or if it creates risk for Kaletech or OneAiChat or negatively impacts the experience other OneAiChat users. </p></p>
          <p className="para"><strong>4.5 Our Use of Your Content.</strong>  <p>  We may use Your Content and Your Bots to provide and improve OneAiChat, Kaletech Private limitedluding: </p></p>
        <ol>
          <li>Providing your questions and prompts to third-party LLMs to generate responses</li>
          <li>Displaying Your Content to others if you use the sharing features in OneAiChat</li>
          <li>Making available Your Bots for others to use and interact with within OneAiChat</li>
          <li>Promoting your shared content or Your Bots to others</li>
          <li>Understanding your use of OneAiChat to generally improve the OneAiChat services</li>
        </ol>
        <p>We may also need to use or disclose Your Content to comply with applicable laws, enforce these OneAiChat Terms of Service and our policies, and to detect and prevent fraud, security, or technical issues.

          By using OneAiChat, you grant us a worldwide, non-exclusive, royalty-free, transferable, and perpetual license to use Your Content and Your Bots as stated above.</p>

          <li>
            <h>Termination</h>
            <p>You may cease your use of OneAiChat or terminate these OneAiChat Terms of Service at any time for any reason or no reason by deleting your account in your settings. We may terminate your access to OneAiChat and/or these OneAiChat Terms of Service at any time for any reason or no reason. Any Data collected prior to termination may continue to be used in accordance with these Terms. The following provisions will survive expiration of termination of these Terms, in addition to the provisions set forth in Section 11(e) of the Kaletech Platform Terms of Service: Section 3.4 (Feedback), Section 4.3, 4.4 and 4.5 (Your Content), this Section 5 (Termination), Section 7 (Disclaimers and Limitation of Liability) and Section 8 (General).</p>
          </li>

          <li>
            <h>Privacy</h>
            <p>As detailed in the OneAiChat Privacy Policy, we may collect certain personal information related to your use of OneAiChat (Kaletech Private limitedluding contact information, etc.). Your account information is anonymized before being shared with third party LLM providers and developers. The third party developers and LLM providers may receive details about your interactions with bots on OneAiChat (Kaletech Private limitedluding the contents of your chats, upvotes, etc.) to provide responses and to generally improve the services. There is no need to share sensitive personal information with the bots (such as credit card information, social security information, etc.). For more information about the third party LLM providers, please see the respective bot profiles. For more information about OneAiChat’s privacy practices, please visit the OneAiChat Privacy Policy.</p>
          </li>

          <li>
            <h>DISCLAIMERS AND LIMITATION OF LIABILITY</h>
            <p>Section 8 of the Kaletech Platform Terms of Service is Kaletech Private limitedorporated herein by reference, except that Section 8(e) of the Kaletech Platform Terms of Service is replaced with the following: WITHOUT LIMITING THE FOREGOING, Kaletech’S MAXIMUM AGGREGATE LIABILITY TO YOU FOR LOSSES OR DAMAGES THAT YOU SUFFER RELATED TO OR ARISING OUT OF YOUR USE OF THE OneAiChat SERVICES OR THESE TERMS IS LIMITED THE AMOUNT YOU PAID TO Kaletech IN THE PRIOR TWELVE (12) MONTHS FOR THE OneAiChat SERVICES GIVING RISE TO THE CLAIM.

          WE CANNOT GUARANTEE THE AVAILABILITY OF ANY BOTS, FEATURES OR SERVICE OF OneAiChat OR AVAILABLE THROUGH OneAiChat. WHILE WE ARE CONTINUING TO DEVELOP NEW FEATURES, ADD BOTS AND IMPROVE OneAiChat, WE MAY, WITHOUT NOTICE TO YOU, ADD OR REMOVE FEATURES OR BOTS, LIMIT USE OR AVAILABILITY OF FEATURES OR BOTS ENTIRELY (OR JUST IN CERTAIN PLACES OR FOR SOME USERS) IF THEY CREATE RISK TO Kaletech, USERS OF OneAiChat, THIRD PARTIES POWERING THE BOTS, NO LONGER MAKE SENSE FROM A BUSINESS PERSPECTIVE OR FOR ANY REASON IN OUR SOLE DISCRETION. WE ALSO CANNOT GUARANTEE THAT YOUR USE OF OneAiChat WILL BE ERROR FREE, DISRUPTED OR THAT YOU WILL NOT EXPERIENCE SERVICE OUTAGES. WE ARE NOT LIABLE FOR ANY ERRORS, DISRUPTIONS OR SERVICE OUTAGES THAT MAY OCCUR.
          </p>
          </li>

          <li>
            <h>General</h>
            <p>You agree that any claim not subject to Arbitration as set forth in Section 10 of the Kaletech Platform Terms of Service must be resolved in a competent court in India (excluding conflict of laws rules) will govern these Terms and any claim. These Terms will be binding upon and will inure to the benefit of your and our heirs, successors, and/or assignees.</p>
          </li>
              <p>Use of OneAIChat by anyone under 13 years of age is prohibited. You represent that you are at least the age of majority in the jurisdiction where you live or, if you are not, your parent or legal guardian must consent to these OneAIChat Terms of Service and affirm that they accept these Terms on your behalf and bear responsibility for your use. Bots accessed via OneAIChat may produce content that is not suitable for minors. If you are accepting these OneAIChat Terms of Service on behalf of someone else or an entity, you confirm that you have the legal authority to bind that person or entity to these Terms. </p>
              </li>
          </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Breadcrumb End */}
  </main>
  <Footer />
 </div>
    )
 }
 export default TermsUse;