import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "components/Header";
import Footer from "components/Footer";
import Link from "components/Link";
import RightArrowIcon from "components/Icons/RightArrow";
import CheckIcon from "components/Icons/Check";
import PublicChat from "components/PublicChat";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "config/axios";

const Home = () => {
  const navigate = useNavigate();
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionPrompt, setSubscriptionPrompt] = useState(false);
  const [loginPrompt, setLoginPrompt] = useState(false);
  const [categoryIndex, setCategoryIndex] = useState(0);
  const categories = ["Technical Writing","Health","Marketing","Coding","Audio/Music","Video","Mathematics","Faith","Science","Art/Design","Writing","Finance"];
  useEffect(() => {
   
    const intervalId = setInterval(() => {
      setCategoryIndex((prevIndex) => (prevIndex + 1) % categories.length);
    }, 1000);

    return () => clearInterval(intervalId); // Clear the interval on component unmount
  }, []); // Empty dependency array to run the effect only once

{/*Apple sign up start--------- */}
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};
const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status
      //console.log(getCookie("token"), "cookie-Token-------");
      //console.log(email, "Email-------");
      useEffect(() => {
      // Check if the user is logged in
      const token = getCookie("token");
      const email = getCookie("email");
      const isLoggedIn = !!token && !!email;
      console.log(token, "Token-------");
      if(token != ''){
     // const updatedEmail = email.replace(/%/g, '@');
    //  console.log(updatedEmail, "Email-------");
      const fetchData = async () => {
        try {
          const response = await axiosInstance.post(`/user-data`, { token });
          // const response = await axiosInstance.post('/user-data', {
          //   headers: {
          //     Authorization: token,
          //   },
          // });
          console.log(response, "user-apple-response--------------");

        if (response.data) {
        const { _id, email, token, deleted, createdAt, updatedAt } = response.data;
           const authUser = {
              _id,
              email,
              token,
              deleted,
              createdAt,
              updatedAt,
            };
            setIsLoggedIn(true);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem('authUser', JSON.stringify(authUser));     
            localStorage.setItem("loggedIn", true);   
            const cookies = document.cookie.split(";");

            for (let i = 0; i < cookies.length; i++) {
              const cookie = cookies[i];
              const eqPos = cookie.indexOf("=");
              const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
              document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.oneaichat.com`;
            }
            window.location.href = '/chat' 

          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };

      if (isLoggedIn) {
        fetchData();
      }
    }
      }, []);
      {/*Apple sign up close--------- */}



        const closeMessage = () => {
          setShowPopup(false);
          setShowMessage(false);
        };
        const [showMessage, setShowMessage] = useState(false);
        useEffect(() => {
          axiosInstance.get(`/subscriptions`)
          .then((response) => {
            console.log('Response data:', response.data);
          if (response.data.status) {
          setSubscriptions(response.data.subscriptions);
          } else {
          console.error('Failed to fetch subscriptions');
          }
          })
          .catch((error) => {
          console.error('Error fetching subscriptions:', error);
          });
          }, [])


//  const handlePayClick = async (e, planId, planName,planPrice) => {
//   setShowMessage(true);
//   setLoginPrompt(true);  
//   setTimeout(() => {
//     setShowMessage(false);
//     setLoginPrompt(false);  
//         }, 3000);
// //   const isPurchaseCompleted = localStorage.getItem('purchaseCompleted');
// //   if (!isPurchaseCompleted) {
// //     localStorage.setItem('selectedPlanName', planName);
// //     localStorage.setItem('selectedPlanId', planId);
// //     localStorage.setItem('selectedPlanPrice', planPrice);
// // }

// //       // Extract user details from local storage
// //   const authUser = JSON.parse(localStorage.getItem("authUser"));

  
// //   if (!authUser) {
// //     console.error('AuthUser not found in local storage');
// //     setLoginPrompt(true);  // Show the login prompt message
// //     setShowMessage(true);
// //     e.preventDefault();  // Prevent form submission
// //     setTimeout(() => {
// //       window.location.href = '/login'; // Redirect to login page after 3 seconds
// //     }, 4000);
// //     return;
// //   } else {
// //     // If the user is logged in, check for subscription
// //     try {
// //       const email = authUser.email;
// //       const response = await axiosInstance.post(`/check-subscription`, { user_email: email });
// //       if (response.data.hasSubscription) {
// //         // Display a user-friendly message to inform the user.
      
// //         setSubscriptionPrompt(true);  // Show the subscription prompt message
// //         setShowMessage(true);
      
// //         e.preventDefault(); // Prevent further processing or redirection
// //         setTimeout(() => { 
// //           window.location.href = `/payment_success`; 
// //       }, 0.900);
// //         return; // Stop the function here if the user has a subscription
// //       } 


// //     } catch (err) {
// //       console.error(err);
// //       e.preventDefault(); // Prevent further processing in case of an error
// //       return;
// //     }
// //   }
// //   const email = authUser.email;
// //   const firstName = authUser.firstName;
// //   const lastName = authUser.lastName;

// //   const subscriptionData = { 
// //         plan_id:planId,
// //         plan_name:planName,
// //         user_first_name: firstName,
// //         user_last_name: lastName,
// //         user_email: email
// //       };
// //       try {
// //         const response = await axiosInstance.post(`/save-subscription`,subscriptionData );
    
// //         if (response.data.success) {
// //         console.log('Data saved sucessfully')
// //         } else {
// //           console.error('Failed to save subscription');
// //           e.preventDefault();
// //         }
// //       } catch (error) {
// //         console.error('Axios Error:', error.response.data);
// //         e.preventDefault();
// //       }      
//     };

const [plans, setPlans] = useState([]);
const [userCountry, setUserCountry] = useState(null); // Initialize userCountry state
useEffect(() => {
  async function fetchData() {
    try {
      const country = await getUserCountry();
      setUserCountry(country); // Set userCountry state after fetching
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  fetchData();
}, []);
const getUserCountry = async () => {
  try {
    const response = await axios.get('https://ipapi.co/json/');
    console.log(response,"country code ipppppp")
    return response.data.country_code;
   
  } catch (error) {
    console.error('Error fetching user country:', error);
    return null;
  }
};

const getExchangeRates = async (userCountry) => {
try {
  const response = await axios.get(`https://api.exchangerate-api.com/v4/latest/${userCountry}`);
  return response.data.rates;
} catch (error) {
  console.error('Error fetching exchange rates:', error);
  return null;
}
};

const updatePrices = (exchangeRates) => {
if (!exchangeRates) return [];

const pricesInLocalCurrency = {
  'USD': 1,  // Default currency is USD
  'EUR': exchangeRates.EUR,
  // Add more currencies as needed
};

const subscriptionPlans = [
  { name: 'Basic', price: 10 },
  { name: 'Standard', price: 20 },
  { name: 'Premium', price: 30 }
];

return subscriptionPlans.map(plan => {
  const priceInLocalCurrency = plan.price * pricesInLocalCurrency['USD'];
  const formattedPrice = new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency: navigator.language
  }).format(priceInLocalCurrency);
  return { ...plan, price: formattedPrice };
});
};

const handlePayClick = async (e, planId, planName, planPrice, stripe_id) => {
  let selectedStripeId;

  // Extract the country code from userCountry
  const countryCode = userCountry.toLowerCase(); // Assuming userCountry is in uppercase

  // Access the corresponding property from the stripe_id object based on the country code
  if (countryCode === 'us') {
    selectedStripeId = stripe_id.stripe_id_us;
  } else if (countryCode === 'gb') {
    selectedStripeId = stripe_id.stripe_id_uk;
  } else if (countryCode === 'ru') {
    selectedStripeId = stripe_id.stripe_id_ru;
  }else if (countryCode === 'fr') {
      selectedStripeId = stripe_id.stripe_id_fr;
  } else if (countryCode === 'nl') {
    selectedStripeId = stripe_id.stripe_id_fr;
}
else if (countryCode === 'de') {
  selectedStripeId = stripe_id.stripe_id_fr;
}  
else if (countryCode === 'it') {
selectedStripeId = stripe_id.stripe_id_fr;
}  
else if (countryCode === 'es') {
selectedStripeId = stripe_id.stripe_id_fr;
} 
else if (countryCode === 'fi') {
selectedStripeId = stripe_id.stripe_id_fr;
} 
else if (countryCode === 'gr') {
selectedStripeId = stripe_id.stripe_id_fr;
} 
else if (countryCode === 'be') {
selectedStripeId = stripe_id.stripe_id_fr;
} 
else if (countryCode === 'nz') {
selectedStripeId = stripe_id.stripe_id_nz;
} 
else if (countryCode === 'sg') {
  selectedStripeId = stripe_id.stripe_id_sg;
}
else if (countryCode === 'cn') {
  selectedStripeId = stripe_id.stripe_id_cn;
}
else if (countryCode === 'jp') {
  selectedStripeId = stripe_id.stripe_id_jp;
}
else if (countryCode === 'au') {
  selectedStripeId = stripe_id.stripe_id_au;
}
else if (countryCode === 'kr') {
  selectedStripeId = stripe_id.stripe_id_kr;
}
else if (countryCode === 'tw') {
  selectedStripeId = stripe_id.stripe_id_tw;
}
else if (countryCode === 'ae') {
  selectedStripeId = stripe_id.stripe_id_ae;
}
else if (countryCode === 'ca') {
  selectedStripeId = stripe_id.stripe_id_ca;
}
else if (countryCode === 'id') {
  selectedStripeId = stripe_id.stripe_id_id;
}
else if (countryCode === 'il') {
  selectedStripeId = stripe_id.stripe_id_il;
}
  else {
    selectedStripeId = stripe_id.stripe_id; // Fallback to a default stripe id
  }

  console.log('Selected Stripe ID:', selectedStripeId);
  // Check if the user is already subscribed
  const authUserString = localStorage.getItem("authUser");
  const authUser = authUserString ? JSON.parse(authUserString) : null;
  try {
    const subscriptionCheckResponse = await axiosInstance.post('/check-user-subscription', { user_id: authUser?._id });

    if (subscriptionCheckResponse?.data?.hasSubscription) {
      setSubscriptionPrompt(true);  // Show the subscription prompt message
         setShowMessage(true);

         setTimeout(()=>
        {
          setShowMessage(false);
        },2000)
              return;
    }

    // User is not subscribed, proceed with payment
    const isPurchaseCompleted = localStorage.getItem('purchaseCompleted');
    if (!isPurchaseCompleted) {
      localStorage.setItem('stripeId', selectedStripeId);
      localStorage.setItem('selectedPlanName', planName);
      localStorage.setItem('selectedPlanId', planId);
      localStorage.setItem('selectedPlanPrice', planPrice);
    }

    if (!authUser) {
      console.error('AuthUser not found in local storage');
      setLoginPrompt(true); // Show the login prompt message
      setShowMessage(true);
      e.preventDefault(); // Prevent form submission
      setTimeout(() => {
        window.location.href = '/login'; // Redirect to login page after 3 seconds
      }, 1000);
      return;
    } else {
      // Open the modal when pay button is clicked
      setShowPopup(true);
      setSelectedPaymentOption(null); // Reset selected payment option
    }
  } catch (error) {
    console.error('Error checking user subscription:', error);
    // Handle the error (show a message, log, etc.)
  }
};

const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
const [showPopup, setShowPopup] = useState(false);

const handlePaymentOptionClick = (paymentOption) => {
  setSelectedPaymentOption(paymentOption);
 
};
const handleProceed = () => {
  if (selectedPaymentOption === "Stripe") {
    handleStripePayment();
  } else if (selectedPaymentOption === "BillDesk") {
    handleBilldeskPayment();
  } else if (selectedPaymentOption === "PhonePe") {
    handlePhonePePayment();
  }
  // Add other payment options as needed
};

const handlePhonePePayment = async () => {
  try {
    const selectedPlanId = localStorage.getItem('selectedPlanId');
    const selectedPlanName = localStorage.getItem('selectedPlanName');
    const selectedPlanPrice = localStorage.getItem('selectedPlanPrice');
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    const email = authUser.email
    const userid=authUser._id;
    const url = `https://db.oneaichat.com/pay?planId=${selectedPlanId}&planName=${selectedPlanName}&planPrice=${selectedPlanPrice}&email=${email}&userid=${userid}`;
  const response = await fetch(url, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error(`Error initiating payment: ${response.statusText}`);
    }

    const responseData = await response.json();

    // Check if responseData has the expected structure
    if (responseData && responseData.redirectUrl) {
      window.location.href = responseData.redirectUrl;
    } else {
      console.error('Unexpected response format:', responseData);
      // Handle unexpected response format, show a message, or redirect to an error page
    }
  } catch (error) {
    console.error('Payment initiation error:', error);
    // Handle the error, show a message, or redirect to an error page
  }
};


{/*Billdesk starts  */}
const [amount, setAmount] = useState('');
const [orderId, setOrderId] = useState('');
const [customerName, setCustomerName] = useState('');
const [customerEmail, setCustomerEmail] = useState('');

const handleBilldeskPayment = async () => {
const selectedPlanId = localStorage.getItem('selectedPlanId');
const selectedPlanName = localStorage.getItem('selectedPlanName');
const selectedPlanPrice = localStorage.getItem('selectedPlanPrice');
const authUserString = localStorage.getItem('authUser');


const authUser = authUserString ? JSON.parse(authUserString) : null;

// Access user information from the authUser object
const userEmail = authUser.email;
const userid=authUser._id;
console.log(userid)
try { 
  const response = await fetch(`https://db.oneaichat.com/api/initiate-payment`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({selectedPlanPrice: parseFloat(selectedPlanPrice), orderId, customerName, customerEmail: userEmail, selectedPlanId: selectedPlanId,  selectedPlanName: selectedPlanName,userid:userid }),
  });

  const result = await response.json();
  
  const authToken = result.flowConfig.authToken;
  const bdOrderId = result.flowConfig.bdOrderId;

  const flow_config = {
    merchantId: "KALETECHPL",
    bdOrderId: bdOrderId,
    authToken: authToken,
    childWindow: false,
    returnUrl: "https://db.oneaichat.com/api/callback",
    retryCount: 0
  };
  var responseHandler = function(txn) {
    if (txn.response) {
        alert("callback received status:: ", txn.status);
        alert("callback received response:: ", txn.response)//response handler to be implemented by the merchant
      }
      console.log("txn.response"+txn.response)
    };
  const config = {
    responseHandler: responseHandler,
 
    flowConfig: flow_config,
    flowType: "payments"
  };

  window.loadBillDeskSdk(config);

  console.log("loadinggg");
} catch (error) {
  console.error('Error initiating payment:', error);
  // alert('Internal server error. Please try again later.');
}
}
{/*Billdesk ends */}



const handleStripePayment = () => {
const stripeId = localStorage.getItem('stripeId');

const form = document.createElement('form');
form.action = 'https://stripe.oneaichat.com/create-checkout-session';
form.method = 'POST';

const input = document.createElement('input');
input.type = 'hidden';
input.name = 'stripeId';
input.value = stripeId;

form.appendChild(input);

document.body.appendChild(form);

form.submit();
};





  return (
    <div>
      <Header />
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
         
      <PublicChat/>
      
      <main className="wrapper">
      <div className="container">
  <p className="sectionn" style={{paddingTop:"10px",textSize:"15px",fontWeight:"bold"}}>
  OneAIChat aggregates open-source and copyrighted content including image/video/audio/document/text generation for user convenience. We don't own or transfer ownership of any provided content. Users are advised to fact-check information, respect copyrights, and use generated content responsibly. For more details, visit our <a href="/terms-use"style={{textDecoration:"underline"}} >Terms of Service</a>  and <a href="/privacy-policy" style={{textDecoration:"underline"}}>Privacy Policy</a>.
    </p>
</div>

        {/* <!-- Hero Start --> */}
        <section
          className="aai-hero-one position-relative"
          // style={{
          //   background:
          //     "url('assets/img/hero/hero-bg-1.jpeg') no-repeat center center/cover",
          // }}
        >
          
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-xl-7">
                <div className="aai-hero-one-content">
              
                  {/* <h1 className="aai-hero-one-title">
                    High-Quality Way to Write your <br />
                              <span
                    className="gradient-text typing-animation"
                    data-strings={JSON.stringify(categories)}
                  >
                    {categories[categoryIndex]}
                  </span> */}


                   <h1 className="aai-hero-one-title">
                  High-quality way to write your <br />
                    <span
                      className="gradient-text typing-animation"
                      data-strings={JSON.stringify(categories)}
                    >
                      {categories[categoryIndex]}
                    </span>
                  </h1>
                  <br/>
                  <div className="mt-5 d-flex flex-column flex-md-row aai-btns-group">
                    
                    <Link to="/pricing" className="aai-gradient-outline-btn">
                      Get Started
                    </Link>
                    <Link to="/about" className="aai-btn btn-pill-solid">
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-10 col-xl-5 mt-5 mt-lg-0">
                <div className="aai-hero-one-img">
                  <img
                    src="assets/img/hero/dashboard-img-3.png"
                    className="img-fluid aai-hero-img d-none d-xl-block"
                    alt=""
                    width={"45%"}
                  />
                  <img
                    src="assets/img/hero/dashboard-img-3.png"
                    className="img-fluid aai-hero-img d-xl-none"
                    alt=""
                   
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Hero End --> */}


        {/* <!-- Writing Step --> */}
        <section className="writing-steps-area py-5">
          <div className="container">
            <div className="writing-steps-wrapper" data-aos="fade-up">
              <div className="row" style={{width:"99%"}}>
        
                  <div className="writing-step">
                   
                    <h3 className="writing-step-title">
                    Simplicity and Power of Machine Learning
                    </h3>
                    {/* <p className="writing-step-desc">
                    Transforming data into knowledge with the precision of Machine Learning.
                    </p> */}
                    <div className="writing-step-arrow d-none d-lg-grid">
                      <RightArrowIcon />
                    </div>
                  </div>
        
            
            
              </div>
            </div>
          </div>
        </section>
        <section className="writing-steps-area py-5">
          <div className="container">
            <div className="writing-steps-wrapper" data-aos="fade-up">
              <div className="row" style={{width:"99%"}}>
               
                  <div className="writing-step">
                
                   <h3 className="writing-step-title">Complexity of Deep Learning</h3>
                    {/* <p className="writing-step-desc">
                    Unraveling profound insights through the intricate layers of Deep Learning.
                    </p> */}
                    <div className="writing-step-arrow d-none d-lg-grid">
                      <RightArrowIcon />
                    </div>
                  </div>
              
            
            
              </div>
            </div>
          </div>
        </section>
        <section className="writing-steps-area py-5">
          <div className="container">
            <div className="writing-steps-wrapper" data-aos="fade-up">
              <div className="row" style={{width:"99%"}}>
                
                  <div className="writing-step">
                  
                    <h3 className="writing-step-title">
                    Speed of Artificial Intelligence
                    </h3>
                    {/* <p className="writing-step-desc">
                    Unleashing boundless potential with the dynamic intelligence of artificial minds.
                    </p> */}
                    <div className="writing-step-arrow d-none d-lg-grid">
                      <RightArrowIcon />
                    </div>
                  </div>
               
            
              </div>
            </div>
          </div>
        </section>
       
        <section className="writing-steps-area py-5">
          <div className="container">
            <div className="writing-steps-wrapper" data-aos="fade-up">
              <div className="row" style={{width:"99%"}}>
                
                  <div className="writing-step">
                  
                    <h3 className="writing-step-title">
                    One AI Chat - “ Discipline your focus ”
                    </h3>
                   
                    <div className="writing-step-arrow d-none d-lg-grid">
                      <RightArrowIcon />
                    </div>
                  </div>
               
            
              </div>
            </div>
          </div>
        </section>
        <section className="writing-steps-area py-5">
          <div className="container">
            <div className="writing-steps-wrapper" data-aos="fade-up">
              <div className="row" style={{width:"99%"}}>
                
                  <div className="writing-step">
                  
                    <h3 className="writing-step-title">
                      Everything Unlimited – No Baggage
                    </h3>
                   
                    <div className="writing-step-arrow d-none d-lg-grid">
                      <RightArrowIcon />
                    </div>
                  </div>
               
            
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Writing Step End --> */}

        {/* <!-- Service Start --> */}
        {/* <section className="aai-services">
          <div
            // style={{
            //   background:
            //     "url('assets/img/bg/service-1.jpeg') no-repeat center center/cover",
            // }}
            className="pt-120 pb-60"
          >
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-xl-6 col-lg order-2 order-lg-1 mt-5 mt-lg-0"
                  data-aos="fade-right"
                >
                  <div>
                    <img
                      src="assets/img/services/service-1.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg order-1 order-lg-2">
                  <div className="singleclick">
                    <h2 className="section-title mb-4">
                      Create Content in Single Click with Oneaichat Power
                    </h2>
                    <p className="section-desc">
                      Give our Oneaichat a few descriptions and we'll automatically
                      create blog articles, product descriptions and more for
                      you within just few second.
                    </p>
                    <ul className="aai-list-items mt-4">
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                          Let's Communicate with your customers with emotions
                        </span>
                      </li>
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                          Start creating powerful content, for your next ads
                        </span>
                      </li>
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                          Settling In Country
                        </span>
                      </li>
                    </ul>
                    <div className="mt-5">
                      <Link
                        to="/pricing"
                        className="aai-gradient-outline-btn"
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="pt-60 pb-60"
            // style={{
            //   background:
            //     "url('assets/img/bg/service-2.jpeg') no-repeat center center/cover",
            // }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg">
                  <div>
                    <h2 className="section-title mb-4">
                      Write Blog Posts, Stories, & Even Books
                    </h2>
                    <p className="section-desc">
                      Just let me know the topic or genre you have in mind, and
                      I'll assist you in creating engaging content. Feel free to
                      provide any specific instructions or ideas you'd like me
                      to incorporate.
                    </p>
                    <ul className="aai-list-items mt-4">
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                          The Oneaichat-generated text is always fresh and relevant.
                        </span>
                      </li>
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                          The Oneaichat-generated text is guaranteed to be unique and
                          original.
                        </span>
                      </li>
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                          The Oneaichat-generated text is SEO-friendly and
                          authoritative.
                        </span>
                      </li>
                    </ul>
                    <div className="mt-5">
                      <Link
                        to="/pricing"
                        className="aai-gradient-outline-btn"
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-6 col-lg mt-5 mt-lg-0"
                  data-aos="fade-left"
                >
                  <div>
                    <img
                      src="assets/img/services/service-2.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="pt-60 pb-120"
            // style={{
            //   background:
            //     "url('assets/img/bg/service-1.jpeg') no-repeat center center/cover",
            // }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-xl-6 col-lg order-2 order-lg-1 mt-5 mt-lg-0"
                  data-aos="fade-right"
                >
                  <div>
                    <img
                      src="assets/img/services/service-3.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg order-1 order-lg-2">
                  <div>
                    <h2 className="section-title mb-4">
                      Instruct to our Oneaichat and generate copy
                    </h2>
                    <p className="section-desc">
                      Give our Oneaichat a few descriptions and we'll automatically
                      create blog articles, product descriptions and more for
                      you within just few second.
                    </p>
                    <ul className="aai-list-items mt-4">
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                          Entering & Leaving From Country
                        </span>
                      </li>
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                          Country Citizenship
                        </span>
                      </li>
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                          Settling In Country
                        </span>
                      </li>
                    </ul>
                    <div className="mt-5">
                      <Link
                        to="pricing"
                        className="aai-gradient-outline-btn"
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- Service End --> */}

        {/* <!-- Aai Feature  --> */}

        {/* <!-- Aai Feature  End--> */}
       
     

        {/* <!-- Testimonials Start--> */}
        {/* <Testimonials /> */}
        {/* <!-- Testimonials End --> */}

        {/* <!-- Pricing Table Start --> */}
                <br/>
                <br/>
                <section className="aai-price-tables pb-120">
        <div className="container">
          <div
            className="row align-items-center mb-5"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <div className="col-xl-5">
              {/* <div className="secttionn">
                <h2 className="section-title">
                  The Right Plan for Transparent Pricing
                </h2>
              </div> */}
            </div>
            <div className="col-xl-7">
              {/* <div>
                <p className="section-desc">
                  We have several powerful plans to showcase your business and
                  get discovered as a creative entrepreneurs. Everything your
                  need.
                </p>
              </div> */}
            </div>
          </div>
          {loginPrompt && 
        <div className={`message-box ${showMessage ? 'active' : ''}`}>
          <button className="close-btn" onClick={closeMessage}>&times;</button>
        Please Login First.
        </div>
      }
              {subscriptionPrompt && 
    <div className={`subscription-box ${showMessage ? 'active' : ''}`}>
        <button className="close-subscription-btn" onClick={closeMessage}>&times;</button>
        User already has a subscription!
    </div>
    }
              {subscriptions.map((subscription)=> (
          <div
            className="aai-price-table mb-4"
            data-aos="fade-up"
            data-aos-delay="100"
            key={subscription._id}
          >
            <div className="row g-4 align-items-center justify-content-between">
              <div className="col-xl-4 col-lg-6">
                <div className="aai-price-pack">
                  <h3 className="aai-price-pack-name">{subscription.plan_name}</h3>
                  <p className="aai-price-pack-desc">
                  {subscription.plan_description}
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <ul className="aai-price-lists list-unstyled">
                  {/* <li className="aai-price-list-item d-flex align-items-center"> */}
                  <li className="aai-price-list-item d-flex align-items-center" style={{borderBottom:"1px solid grey",paddingBottom:"5px"}}>
                  <p style={{color:"black",fontWeight:"bolder",padding:"1px",border:"1px solid black",borderRadius:"50%",fontSize:"12px"}}>✓</p>
                  {/* <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.4839 10.0673C15.907 9.64423 16.5929 9.64423 17.016 10.0673C17.4065 10.4578 17.4366 11.0723 17.1061 11.4973L17.016 11.5994L12.6827 15.9327C12.2921 16.3232 11.6776 16.3533 11.2527 16.0228L11.1506 15.9327L8.98393 13.766C8.56086 13.343 8.56086 12.657 8.98393 12.234C9.37445 11.8434 9.98895 11.8134 10.4139 12.1438L10.516 12.234L11.9166 13.6338L15.4839 10.0673Z"
                        fill="#DADADA"
                      />
                      <g opacity="0.5">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13 2.16667C7.01687 2.16667 2.16663 7.01692 2.16663 13C2.16663 18.9831 7.01687 23.8333 13 23.8333C18.983 23.8333 23.8333 18.9831 23.8333 13C23.8333 7.01692 18.983 2.16667 13 2.16667ZM13 4.33333C17.7864 4.33333 21.6666 8.21353 21.6666 13C21.6666 17.7865 17.7864 21.6667 13 21.6667C8.21349 21.6667 4.33329 17.7865 4.33329 13C4.33329 8.21353 8.21349 4.33333 13 4.33333Z"
                          fill="#DADADA"
                        />
                      </g>
                    </svg> */}
                    {/* <span className="aai-price-list-item-text"
                      > All Paid Models: ChatGPT - Claude - Mistral</span
                    > */}

{/* <span className="aai-price-list-item-text"  >All Pro models: OpenAI - Anthropic - Google</span> */}

<span className="aai-price-list-item-text"  >All Pro models: OpenAI - Anthropic - Stability AI - Google</span>

                  </li>
                  {/* <li className="aai-price-list-item d-flex align-items-center"> */}
                  <li className="aai-price-list-item d-flex align-items-center" style={{borderBottom:"1px solid grey",paddingBottom:"5px"}}>
                  <p style={{color:"black",fontWeight:"bolder",padding:"1px",border:"1px solid black",borderRadius:"50%",fontSize:"12px"}}>✓</p>
                  {/* <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.4839 10.0673C15.907 9.64423 16.5929 9.64423 17.016 10.0673C17.4065 10.4578 17.4366 11.0723 17.1061 11.4973L17.016 11.5994L12.6827 15.9327C12.2921 16.3232 11.6776 16.3533 11.2527 16.0228L11.1506 15.9327L8.98393 13.766C8.56086 13.343 8.56086 12.657 8.98393 12.234C9.37445 11.8434 9.98895 11.8134 10.4139 12.1438L10.516 12.234L11.9166 13.6338L15.4839 10.0673Z"
                        fill="#DADADA"
                      />
                      <g opacity="0.5">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13 2.16667C7.01687 2.16667 2.16663 7.01692 2.16663 13C2.16663 18.9831 7.01687 23.8333 13 23.8333C18.983 23.8333 23.8333 18.9831 23.8333 13C23.8333 7.01692 18.983 2.16667 13 2.16667ZM13 4.33333C17.7864 4.33333 21.6666 8.21353 21.6666 13C21.6666 17.7865 17.7864 21.6667 13 21.6667C8.21349 21.6667 4.33329 17.7865 4.33329 13C4.33329 8.21353 8.21349 4.33333 13 4.33333Z"
                          fill="#DADADA"
                        />
                      </g>
                    </svg> */}
                    {/* <span className="aai-price-list-item-text"
                      >Generate: Image-Audio-Document-(Video Coming Soon)</span
                    > */}

<span className="aai-price-list-item-text"
                      >Generate: Image-Audio-Document</span>

                  </li>
                  {/* <li className="aai-price-list-item d-flex align-items-center"> */}
                  <li className="aai-price-list-item d-flex align-items-center" style={{borderBottom:"1px solid grey",paddingBottom:"5px"}}>
                  <p style={{color:"black",fontWeight:"bolder",padding:"1px",border:"1px solid black",borderRadius:"50%",fontSize:"12px"}}>✓</p>
                    {/* <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.4839 10.0673C15.907 9.64423 16.5929 9.64423 17.016 10.0673C17.4065 10.4578 17.4366 11.0723 17.1061 11.4973L17.016 11.5994L12.6827 15.9327C12.2921 16.3232 11.6776 16.3533 11.2527 16.0228L11.1506 15.9327L8.98393 13.766C8.56086 13.343 8.56086 12.657 8.98393 12.234C9.37445 11.8434 9.98895 11.8134 10.4139 12.1438L10.516 12.234L11.9166 13.6338L15.4839 10.0673Z"
                        fill="#DADADA"
                      />
                      <g opacity="0.5">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13 2.16667C7.01687 2.16667 2.16663 7.01692 2.16663 13C2.16663 18.9831 7.01687 23.8333 13 23.8333C18.983 23.8333 23.8333 18.9831 23.8333 13C23.8333 7.01692 18.983 2.16667 13 2.16667ZM13 4.33333C17.7864 4.33333 21.6666 8.21353 21.6666 13C21.6666 17.7865 17.7864 21.6667 13 21.6667C8.21349 21.6667 4.33329 17.7865 4.33329 13C4.33329 8.21353 8.21349 4.33333 13 4.33333Z"
                          fill="#DADADA"
                        />
                      </g>
                    </svg> */}

                    {/* <span className="aai-price-list-item-text">Access OneAIChat’s: Focused Categories</span> */}

                    <span className="aai-price-list-item-text"> Access OneAIChat’s Focused-Categories</span>

                  </li>
                  {/* <li className="aai-price-list-item d-flex align-items-center"> */}
                  <li className="aai-price-list-item d-flex align-items-center" style={{borderBottom:"1px solid grey",paddingBottom:"5px"}}>
                  <p style={{color:"black",fontWeight:"bolder",padding:"1px",border:"1px solid black",borderRadius:"50%",fontSize:"12px"}}>✓</p>
                  {/* <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.4839 10.0673C15.907 9.64423 16.5929 9.64423 17.016 10.0673C17.4065 10.4578 17.4366 11.0723 17.1061 11.4973L17.016 11.5994L12.6827 15.9327C12.2921 16.3232 11.6776 16.3533 11.2527 16.0228L11.1506 15.9327L8.98393 13.766C8.56086 13.343 8.56086 12.657 8.98393 12.234C9.37445 11.8434 9.98895 11.8134 10.4139 12.1438L10.516 12.234L11.9166 13.6338L15.4839 10.0673Z"
                        fill="#DADADA"
                      />
                      <g opacity="0.5">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13 2.16667C7.01687 2.16667 2.16663 7.01692 2.16663 13C2.16663 18.9831 7.01687 23.8333 13 23.8333C18.983 23.8333 23.8333 18.9831 23.8333 13C23.8333 7.01692 18.983 2.16667 13 2.16667ZM13 4.33333C17.7864 4.33333 21.6666 8.21353 21.6666 13C21.6666 17.7865 17.7864 21.6667 13 21.6667C8.21349 21.6667 4.33329 17.7865 4.33329 13C4.33329 8.21353 8.21349 4.33333 13 4.33333Z"
                          fill="#DADADA"
                        />
                      </g>
                    </svg> */}
                    {/* <span className="aai-price-list-item-text"
                      >Zero limit on prompts, for all subscriptions</span
                    > */}

                     <span className="aai-price-list-item-text"
                      > Zero limit on prompts.
                      </span>

                  </li>
                </ul>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="aai-price-value">
                  <h2 className="aai-price">
                  {console.log('User Country:', userCountry)}
      {userCountry === 'US' && `${subscription.plan_price_us}`} {/* Adding '$' symbol for US */}
      {userCountry === 'GB' && `${subscription.plan_price_uk}`} {/* Adding '£' symbol for UK */}
      {userCountry === 'RU' && `${subscription.plan_price_ru}`} {/* Adding '£' symbol for UK */}
      {userCountry === 'FR' && `${subscription.plan_price_fr}`} {/* Adding '£' symbol for UK */}
      {userCountry === 'NL' && `${subscription.plan_price_fr}`} {/* Adding '£' symbol for France */}
      {userCountry === 'DE' && `${subscription.plan_price_fr}`} {/* Adding '£' symbol for Germany */}
      {userCountry === 'IT' && `${subscription.plan_price_fr}`} {/* Adding '£' symbol for Italy */}
      {userCountry === 'ES' && `${subscription.plan_price_fr}`} {/* Adding '£' symbol for Spain */}
      {userCountry === 'FI' && `${subscription.plan_price_fr}`} {/* Adding '£' symbol for Finland */}
      {userCountry === 'GR' && `${subscription.plan_price_fr}`} {/* Adding '£' symbol for Greece */}
      {userCountry === 'BE' && `${subscription.plan_price_fr}`} {/* Adding '£' symbol for Greece */}
      {userCountry === 'SG' && `${subscription.plan_price_sg}`} {/* Adding '£' symbol for Singapore */}
      {userCountry === 'CN' && `${subscription.plan_price_cn}`} {/* Adding '£' symbol for China */}
      {userCountry === 'JP' && `${subscription.plan_price_jp}`} {/* Adding '£' symbol for UK */}
      {userCountry === 'NZ' && `${subscription.plan_price_nz}`} {/* Adding '£' symbol for UK */}
      {userCountry === 'AU' && `${subscription.plan_price_au}`} {/* Adding '£' symbol for Australia */}
      {userCountry === 'KR' && `${subscription.plan_price_kr}`} {/* Adding '£' symbol for South Korea */}
      {userCountry === 'TW' && `${subscription.plan_price_tw}`} {/* Adding '£' symbol for taiwan*/}
      {userCountry === 'AE' && `${subscription.plan_price_ae}`} {/* Adding '£' symbol for Dubai*/}
      {userCountry === 'CA' && `${subscription.plan_price_ca}`} {/* Adding '£' symbol for Canada*/}
      {userCountry === 'ID' && `${subscription.plan_price_id}`} {/* Adding '£' symbol for Indonesia*/}


      {userCountry === 'IN' && `₹${subscription.plan_price}`} {/* Adding '₹' symbol for India */}
      
      
                    </h2>
                </div>
              </div>
              <div className="col-xl-2 col-lg-6">
                <div>
               
                <input type="hidden" name="stripeId" value={subscription[`stripe_id_${userCountry ? userCountry.toLowerCase() : ''}`]}  />
                <button 
                  className="aai-btn btn-pill-solid" 
                  id="submit" 
                  role="link" 
                 onClick={(e) => handlePayClick(e, subscription.plan_id, subscription.plan_name, subscription.plan_price,subscription)}
                >
                  PAY
                </button>
            
                </div>
              </div>
            </div>    
          </div>
          ))}
           {/* Popup for payment options */}
        {showPopup && (
         
          <div className="popup">
            <h2>Select Payment Option</h2>
            <br/>
            <button className="popup-close" onClick={closeMessage} style={{fontSize:"larger",
            // marginTop:"-150px",
            marginLeft:"355px",position:"fixed",color:'black'}}>X</button>
            <button onClick={() => handlePaymentOptionClick("PhonePe")}
             className={selectedPaymentOption === "PhonePe" ? "selected" : ""}
            >PhonePe</button>
          <button
            onClick={() => handlePaymentOptionClick("BillDesk")}
            className={selectedPaymentOption === "BillDesk" ? "selected" : ""}
          >
            BillDesk
          </button>
          <button
            onClick={() => handlePaymentOptionClick("Stripe")}
            className={selectedPaymentOption === "Stripe" ? "selected" : ""}
          >
            Stripe
          </button>
          <br/>
          <br/>
          <br/>
          
          <center>
            <button onClick={handleProceed}>Proceed</button>
          </center>
          </div>
        )}
          {/* <!-- Single Price Table --> */}
         
          {/* <!-- Single Price Table --> */}
     </div>
      </section>
        {/* <!-- Pricing Table End --> */}

  {/* Cta Start */}
  <section
        className="aai-cta pb-120"
        
        // style={{
        //   background: "url('assets/img/bg/cta-bg.jpeg') no-repeat center center/cover"
        // }}
      >
        <div className="container" style={{"border":"1px solid #28c434","borderRadius":"25px"}}>
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg">
              <div className="secttionn">
                <h2 className="section-title mb-4">
                The Future Isn't What It Used To Be!&nbsp;
                  <span className="position-relative">
                  
                  </span>
                  
                </h2>
            
                <div className="mt-5 d-flex flex-column flex-md-row aai-btns-group">
                  <a href="/pricing" className="aai-btn btn-pill-solid">
                    Get Started
                  </a>
                  <a href="/about" className="aai-gradient-outline-btn">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-xl-7 col-lg mt-5 mt-xl-0"
              data-aos="fade-up"
              data-aos-delay="80"
            >
              <div className="aai-cta-img">
                <img
                  src="assets/img/logo/2.jpeg"
                  className="img-fluuid"
                  alt=""
                  
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Cta End */}

      </main>
      <Footer />
    </div>
  );
};

export default Home;
