import usePaginatedRequest from "api/usePaginatedRequest";

const useGetPaginatedRooms = (chatbot, onSuccess, onError) => {
  const successHandler = (res) => {
    if (onSuccess instanceof Function) {
      onSuccess(res);
    }
  };

  const errorHandler = (res) => {
    if (onError instanceof Function) {
      onError(res);
    }
  };

  const select = (res) => {
    const rooms = [];
    res?.pages?.forEach((page) => {
      if (page?.data?.status !== "success") {
        return [];
      }
      rooms.push(...page?.data?.results);
    });

    // console.log("Rooms - ", rooms);
    return rooms;
  };

  const getPreviousPageParam = (firstPage) => {
    const currPage = firstPage?.data?.page;
    return currPage > 1 ? currPage - 1 : 1;
  };

  const getNextPageParam = (lastPage) => {
    const currPage = lastPage?.data?.page;
    const totalPage = lastPage?.data?.totalPages;
    const nextPage = currPage < totalPage ? currPage + 1 : undefined;
    // console.log("Next Page - ", nextPage);
    return nextPage;
  };

  return usePaginatedRequest({
    key: ["PAGINATED_ROOMS", chatbot],
    url: "/room/paginated-room",
    variables: { chatbot },
    method: "post",
    getPreviousPageParam,
    getNextPageParam,
    select: select,
    onSuccess: successHandler,
    onError: errorHandler,
  });
};

export default useGetPaginatedRooms;
