import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import Link from "components/Link";
import Header from "components/Header";
import GoogleIcon from "components/Icons/Google";
import AppleIcon from "components/Icons/Apple";
import CloseEye from "components/Icons/CloseEye";
import OpenEye from "components/Icons/OpenEye";
import useRegister from "hooks/useRegister";
import Alert from "components/common/Alert";
import { initialAlert } from "constants/general";

const Register = () => {
  const [alert, setAlert] = useState({ ...initialAlert });
  const navigate = useNavigate();
  const handleSkipRegistration = () => {
    
    navigate('/');
  };
  
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);

  const alertHandler = (open, type = "", message = "") => {
    setAlert({ open, type, message });
  };

  const onSuccess = (res) => {
    reset();
    if (res?.data?.status) {
      const authUserWithToken = {
        ...res.data.user, // Spread the user details
        token: res.data.token // Add token property
      };
      // Assuming the API response includes a token and user details
      localStorage.setItem("token", res?.data?.token);
      localStorage.setItem("authUser", JSON.stringify(authUserWithToken));
      // Update any state you may have to indicate that the user is logged in
      // This could be a context or a state in a parent component
      
      alertHandler(true, "success", "Registered successfully! Logging you in...");
      navigate("/chat"); // Or wherever you want to redirect the user after registration
    } else {
      alertHandler(true, "danger", res?.data?.message ?? "Registration Failed!");
    }
  };
  
  const onError = (err) => {
    alertHandler(true, "danger", err?.response?.data?.message);
  };
  const { mutateAsync: signUp } = useRegister(onSuccess, onError);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log(codeResponse);
      navigate("/chat");
    },
    flow: "auth-code",
  });

  const togglePassword = () => {
    setShowPassword((e) => !e);
  };

  const onSubmit = async (data) => {
    if (!data.acceptTerms) {
      // Checkbox not checked, display an error message or take action
      console.log("You must accept the terms and conditions.");
      
      return;
    }
    try {
      await signUp(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div>
    <Header className="position-absolute top-0 left-0 w-100 pt-4" showMenu={false} />
    <main className="wrapper">
      <section className="aai-signup-in">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg-4 d-none d-xl-block order-2 order-lg-1">
              <div className="position-relative">
                <img
                  src="assets/img/others/ai-ills.svg"
                  className="img-fluid aai-signup-in-img"
                  alt=""
                />
              </div>
            </div>
            <div className="col-xl-7 order-1 ps-xl-5 order-lg-2 mb-5 mb-lg-0">
              <div className="aai-form-wrapper">
                <div className="">
                  <div className="aai-form-header d-flex justify-content-center text-center flex-column align-items-center">
                    <h2 className="aai-form-title">Sign up for an account</h2>
                    <p className="aai-form-desc">
                      Send, spend, and save smarter
                    </p>
                  </div>
                  <div className="row aai-auth-links">
                    <div className="col-lg-6 mb-3 mb-lg-0">
                      <button
                        onClick={() => login()}
                        className="aai-auth-link w-100 justify-content-center d-flex align-items-center"
                      >
                        <GoogleIcon />
                        <span>Sign Up with Google</span>
                      </button>
                    </div>
                    <div className="col-lg-6">
                      <Link
                        to="#"
                        className="aai-auth-link w-100 d-flex justify-content-center align-items-center"
                      >
                        <AppleIcon />
                        <span>Sign Up with Apple</span>
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-md-row aai-option-bar justify-content-center align-items-center">
                    <span className="aai-auth-line"> </span>
                    <span className="aai-auth-option flex-shrink-0">
                      Or with mobile
                    </span>
                    <span className="aai-auth-line"></span>
                  </div>

                  <div className="row g-3">
                    <div className="col-lg-12">
                      <div className="aai-form-container">
                        <input
                          type="tel"
                          className={`form-control shadow-none ${errors?.phoneNumber ? "is-invalid" : ""}`}
                          placeholder="Phone Number"
                          aria-invalid={errors?.phoneNumber ? "true" : "false"}
                          {...register("phoneNumber", {
                            required: {
                              value: true,
                              message: "Phone number is required!",
                            },
                            pattern: {
                              value: /^[+]?[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/i,
                              message: "Invalid phone number",
                            },
                            minLength: {
                              value: 10,
                              message: "Phone number must be at least 10 digits long",
                            },
                            maxLength: {
                              value: 10,
                              message: "Phone number must not be longer than 10 digits",
                            },
                          })}
                        />
                        {errors?.phoneNumber && (
                          <div className="mx-4 invalid-feedback">
                            {errors?.phoneNumber?.message ?? ""}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="d-grid">
                        <button className="aai-btn btn-pill-solid">
                          Generate Otp
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-column flex-md-row aai-option-bar justify-content-center align-items-center mt-3">
                    <span className="aai-auth-line"> </span>
                    <span className="aai-auth-option flex-shrink-0">
                      Or with email
                    </span>
                    <span className="aai-auth-line"></span>
                  </div>

                  <div className="row g-3">
                    <div className="col-lg-12">
                      <div className="aai-form-container">
                        <input
                          type="text"
                          className={`form-control shadow-none ${errors?.email ? "is-invalid" : ""}`}
                          placeholder="Email"
                          aria-invalid={errors?.email ? "true" : "false"}
                          {...register("email", {
                            required: {
                              value: true,
                              message: "Email is required!",
                            },
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid email address",
                            },
                          })}
                        />
                        {errors?.email && (
                          <div className="mx-4 invalid-feedback">
                            {errors?.email?.message ?? ""}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="d-grid">
                        <button className="aai-btn btn-pill-solid">
                        Generate Otp
                        </button>
                      </div>
                    </div>
                  </div>

                  {alert?.open && (
                    <Alert
                      type={alert?.type}
                      message={alert?.message}
                      onClose={alertHandler}
                    />
                  )}
                  <div className="text-center mt-3">
                    <p className="aai-form-support-text">
                      Already have an account?
                      <Link
                        to="/login"
                        className="aai-form-support-link text-decoration-underline"
                      >
                        Sign In
                      </Link>
                      <div className="aai-form-support-link text-decoration-underline">
                        <a href="" onClick={handleSkipRegistration}> Not Now</a>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
    )
  };

export default Register;
