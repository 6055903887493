// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .wrapper {
  background-color: #fff;
  border-radius: 12px;
  min-height: 40vh;
  max-height: 40vh;
  margin: 60px auto 12px;
  max-width: 50vw;
  padding: 0;
} */

/* previous code  */

/* .wrapper {
  background-color: #fff;
  border-radius: 12px; */
  /* min-height: 40vh; */
  /* max-height: 40vh;
  margin: 60px auto 12px;
  max-width: 50vw;
  padding: 0;
} */

/* experimented code  */

.Chat_wrapper__p90C0 {
  background-color: #FBF9F9;
  border-radius: 12px;
  /* min-height: 40vh; */
  max-height: 40vh;
  margin: 60px auto 12px;
  max-width: 80vw;
  padding: 0;
}



/* Styles for mobile devices */
@media (max-width: 767px) {
  .Chat_wrapper__p90C0 { 
    max-width: 90vw;
  }
}


`, "",{"version":3,"sources":["webpack://./src/components/Chat/Chat.module.css"],"names":[],"mappings":"AAAA;;;;;;;;GAQG;;AAEH,mBAAmB;;AAEnB;;wBAEwB;EACtB,sBAAsB;EACtB;;;;GAIC;;AAEH,uBAAuB;;AAEvB;EACE,yBAAyB;EACzB,mBAAmB;EACnB,sBAAsB;EACtB,gBAAgB;EAChB,sBAAsB;EACtB,eAAe;EACf,UAAU;AACZ;;;;AAIA,8BAA8B;AAC9B;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":["/* .wrapper {\n  background-color: #fff;\n  border-radius: 12px;\n  min-height: 40vh;\n  max-height: 40vh;\n  margin: 60px auto 12px;\n  max-width: 50vw;\n  padding: 0;\n} */\n\n/* previous code  */\n\n/* .wrapper {\n  background-color: #fff;\n  border-radius: 12px; */\n  /* min-height: 40vh; */\n  /* max-height: 40vh;\n  margin: 60px auto 12px;\n  max-width: 50vw;\n  padding: 0;\n} */\n\n/* experimented code  */\n\n.wrapper {\n  background-color: #FBF9F9;\n  border-radius: 12px;\n  /* min-height: 40vh; */\n  max-height: 40vh;\n  margin: 60px auto 12px;\n  max-width: 80vw;\n  padding: 0;\n}\n\n\n\n/* Styles for mobile devices */\n@media (max-width: 767px) {\n  .wrapper { \n    max-width: 90vw;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Chat_wrapper__p90C0`
};
export default ___CSS_LOADER_EXPORT___;
