// PaymentSuccessPage.js
import React from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { axiosInstance } from 'config/axios';
const PaymentSuccessPage = ({ location }) => {
  // Access details from the URL query parameters

const [transactionData, setTransactionData] = useState(null);
// Extracted detail
useEffect(() => {
  // Make a POST request to the backend API
  axiosInstance.post('/api/retrieve')
    .then(response => {
      // Handle the response from the backend
      console.log('Transaction response from backend:', response.data);
      setTransactionData(response.data);
    })
    .catch(error => {
      console.error('Error fetching transaction response:', error);
    });
}, []); // Empty dependency array to ensure the effect runs only once


  return (
    <div>
      <center>
      <h1>Payment Successful</h1>
      <h2>Transaction Data:</h2>
            <pre>{JSON.stringify(transactionData, null, 2)}</pre>
      <p>Thank you for your payment!</p>
      </center>
    </div>
  );
};

export default PaymentSuccessPage;
