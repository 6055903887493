import React from "react";
import AIList from "./AIList";
import Chat from "components/Chat";

const PublicChat = () => {
  // useEffect(() => {
  //  // fetchDeviceId();
  // }, []);
  // const fetchDeviceId = async () => {
  //   try {
  //     const response = await axiosInstance.post(`/deviceid`);
  //     if (response.data && response.data.deviceId) {
  //       const deviceId = response.data.deviceId;
  //       console.log(deviceId,"deviceId");
  
  //       // Set the expiration time to 365 days from the current date
  //       // const expirationDate = new Date();
  //       // expirationDate.setDate(expirationDate.getDate() + 365);
  
  //       // // Store the device ID in a cookie
  //       // // console.log(getCookie('deviceId'),"deviceId-cookie---------------------------------------");
  //       // document.cookie = `deviceId=${deviceId}; expires=${expirationDate.toUTCString()}; domain=oneaichat.com; path=/; secure; samesite=strict; httponly`;

  
  //       // console.log('Device ID:', deviceId);
  //     } else {
  //       console.error('No device ID found in the response');
  //     }
  //     console.log(response,"deviceData--------------------------");
  //   } catch (error) {
  //     console.error('Error fetching device ID:', error);
  //   }
  // };


  return (
    <section
      className="aai-features"
      // style={{
      //   background:
      //     "url('assets/img/bg/aai-feature-bg.jpeg') no-repeat center center/cover",
      // }}
    >
      <div className="containerrrr" style={{backgroundColor:"#d5f7e6",borderRadius:"25px"}}>
        <div className="row justify-content-center">
          <div className="col-xl-8">
            {/* <div className="aai-section-title mb-3 text-center mb-5"> */}
              {/* <h2 className="section-title">AI Generate content in seconds </h2> */}
              {/* <h2 className="section-title" >Your data is  <span style={{ color: "rgb(252, 179, 22)" }}>YOUR</span>{" "} */}
  {/* <span style={{ color: "#09BD3E" }}>DATA</span>,  your data is not our business model. </h2> */}
             
            {/* </div> */}


            <div className="aai-section-title  text-center mt-2">
  {/* <h2 className="section-title" style={{fontSize:"39px"}} ><span style={{ color: "rgb(252, 179, 22)" }}>Experience Compounding Intelligence</span>{" "}
  </h2> */}

<h2 className="section-title" style={{fontSize:"35px"}} >Experience compounding intelligence.</h2>
            </div>


          </div>
        </div>
        <div className="row justify-content-center">
         

          <Chat />
        </div>
      </div>
    </section>
  );
};

export default PublicChat;
