import { useMutation } from "react-query";
import { axiosInstance } from "config/axios";

const usePostRequest = ({ key, url, ...rest }) => {
  const mutationFn = async (variables) => {
    console.log(variables);
    return await axiosInstance.post(url, variables);
  };
  return useMutation({
    mutationKey: key,
    mutationFn,
    ...rest,
  });
};

export default usePostRequest;
