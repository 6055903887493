import usePostRequest from "api/usePostRequest";

const useRegister = (onSuccess, onError) => {
  return usePostRequest({
    key: "REGISTER_USER",
    url: "/signup",
    onSuccess,
    onError,
  });
};

export default useRegister;
