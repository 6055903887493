import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Link from "components/Link";
import Header from "components/Header";
import CloseEye from "components/Icons/CloseEye";
import OpenEye from "components/Icons/OpenEye";
import useChangePassword from "hooks/useChangePassword";
import Alert from "components/common/Alert";
import { initialAlert } from "constants/general";


const ChangePass = () => {
  const [alert, setAlert] = useState({ ...initialAlert });
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);

  const alertHandler = (open, type = "", message = "") => {
    setAlert({ open, type, message });
  };
  const token = localStorage.getItem('token'); // Get the token from local storage
console.log(token);
  const onSuccess = (res) => {
    reset();
    if (res?.data?.status) {
      alertHandler(
        true,
        "success",
        "Password Changed Successfully! Please login."
      );

      // Redirect to the login page after changing the password
      setTimeout(() => {
        navigate("/login");
      }, 4000); // Redirect after 2 seconds
    } else {
      alertHandler(true, "danger", res?.data?.message ?? "Password Change Failed!");
    }
  };

  const onError = (err) => {
    if (err.response && err.response.data && err.response.data.message) {
      alertHandler(true, "danger", err.response.data.message);
    } else {
      alertHandler(true, "danger", "Password reset request failed. Please try again later.");
    }
  };

  const { mutateAsync: changePassword } = useChangePassword(onSuccess, onError, token);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  
  const onSubmit = async (data) => {
    try {
      await changePassword(data);
    } catch (err) {
      console.log(err.message);
    }
  };
  return (
    <div>
      {/* Header */}
      <Header className="position-absolute top-0 left-0 w-100 pt-4" showMenu={false} />
      <main className="wrapper">
        {/* Change Password Form */}
        <section className="aai-signup-in">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-5 col-lg-4 d-none d-xl-block order-2 order-lg-1">
                <div className="position-relative">
                  <img src="assets/img/others/ai-ills.svg" className="img-fluid aai-signup-in-img" alt="" />
                </div>
              </div>
              <div className="col-xl-7 order-1 ps-xl-5 order-lg-2 mb-5 mb-lg-0">
                <div className="aai-form-wrapper">
                  <div className="">
                    <div className="aai-form-header d-flex justify-content-center text-center flex-column align-items-center">
                      <h2 className="aai-form-title">Change Password</h2>
                      <p className="aai-form-desc">Send, spend and save smarter</p>
                    </div>
                  </div>
                  <div>
                    {alert?.open && (
                      <Alert
                        type={alert?.type}
                        message={alert?.message}
                        onClose={alertHandler}
                      />
                    )}
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row g-3" style={{ marginTop: "10px" }}>
                        <div className="col-lg-12 mb-2">
                          <div className="aai-form-container position-relative">
                            <input
                              type={showPassword ? "text" : "password"}
                              className={`form-control password shadow-none ${
                                errors?.newPassword ? "is-invalid" : ""
                              }`}
                              placeholder="New Password"
                              aria-invalid={errors?.newPassword ? "true" : "false"}
                              {...register("newPassword", {
                                required: {
                                  value: true,
                                  message: " New Password is required!",
                                },
                                minLength: {
                                  value: 8,
                                  message: "Password must be at least 8 characters long!",
                                },
                              })}
                            />
                            {errors?.newPassword && (
                              <div className="mx-4 invalid-feedback">
                                {errors?.newPassword?.message ?? ""}
                              </div>
                            )}
                            <button
                              type="button"
                              onClick={togglePassword}
                              className="aai-form-eye"
                            >
                              {showPassword ? <OpenEye /> : <CloseEye />}
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-12 mb-2">
                          <div className="aai-form-container position-relative">
                            <input
                              type={showPassword ? "text" : "password"}
                              className={`form-control password shadow-none ${
                                errors?.confirmPassword ? "is-invalid" : ""
                              }`}
                              placeholder="Confirm Password"
                              aria-invalid={errors?.confirmPassword ? "true" : "false"}
                              {...register("confirmPassword", {
                                required: {
                                  value: true,
                                  message: "Confirm Password is required!",
                                },
                                validate: (value) =>
                                  value === watch("newPassword") ||
                                  "Passwords do not match!",
                              })}
                            />
                            {errors?.confirmPassword && (
                              <div className="mx-4 invalid-feedback">
                                {errors?.confirmPassword?.message ?? ""}
                              </div>
                            )}
                            <button
                              type="button"
                              onClick={togglePassword}
                              className="aai-form-eye"
                            >
                              {showPassword ? <OpenEye /> : <CloseEye />}
                            </button>
                          </div>
                        </div>
                        
                        <div className="col-lg-12">
                          <div className="d-grid">
                            <button className="aai-btn btn-pill-solid" type="submit">
                              Change Password
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt-3">
                        <p className="aai-form-support-text">
                          Already have an account?
                          <Link
                            to="/login"
                            className="aai-form-support-link text-decoration-underline"
                          >
                            Sign In
                          </Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ChangePass;