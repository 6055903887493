import React from 'react';
import './Loader.css'; // Make sure to create a Loader.css file with your spinner styles

const Loader = () => (
  <div className="loader-backdrop">
    <div className="loader">
      <div className="spinner"></div> {/* This will be your circular spinner */}
   
    </div>
  </div>
);

export default Loader;
