import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
const ServiceDetails=()=>{
const serviceDetailsStyle = {
 background: "url('assets/img/bg/cta-bg.jpeg') no-repeat center center/cover",
  };
    
    return(
        <div>
            <Header />
            <main class="wrapper">
    {/* <!-- Breadcrumb Start --> */}
     <section className="aai-breadcrumb"
      // style={{
      //   background: "url('assets/img/bg/bread-crumb-bg.jpeg') no-repeat center center/cover",
      // }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-auto">
            <div className="text-center">
              <h2 className="aai-page-title">Service Details</h2>
              <ul className="aai-breadcrumb-nav d-flex align-items-center justify-content-center">
                <li>
                  <a href="/" className="aai-breadcrumb-link text-decoration-underline">Home</a>
                </li>
                <li>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 16L18 12M18 12L14 8M18 12L6 12"
                      stroke="#DADADA"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </li>
                <li>
                  <a href="" className="aai-breadcrumb-link">Service Details</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!-- Breadcrumb End --> */}
     

      {/* About Service Start */}
      <section className="aai-about-service">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div>
                <h2 className="section-title">About this service</h2>
                <br/>
                <p className="section-desc mb-4">
                  One AI Chat empowers boundless creativity by seamlessly integrating the most advanced
                  AI engines in image, video, audio, document and text, offering an unparalleled platform
                  for users to bring their most ambitious visions to life through a sleek, intuitive interface.
                </p>
                
                <div className="py-5">
                  <div className="aai-about-service-cta">
                    <div className="row justify-content-between align-items-center">
                      <div className="col-lg-6" style={{width:"100%"}} >
                        <div>
                          <h3 className="aai-about-service-title" style={{"fontSize":"30px"}}>
                          Uncover the laws of truth by dispelling illusions with OneAIChat. 
                          </h3>
                         
                        </div>
                      </div>
                      <div className="col-lg-6 mt-5 mt-lg-0">
                        <div className="d-flex justify-content-lg-end" style={{marginTop:"-55px",marginRight:"-620px"}}>
                          <a href="/pricing" className="aai-btn btn-pill-solid"
                            >Purchase Now!</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h3 className="aai-about-service-title" style={{"fontSize":"40px",textAlign:"center"}}>
                Focused Categories: </h3>
                         
                <p className="section-desc mb-4" style={{"textAlign":"justify"}}>
                With OneAIChat you can discipline your focus and cut the vastness of AI from distracting
                you to create work which give your life meaning, regardless of your profession.
                </p>
                <h3 className="aai-about-service-title" style={{"fontSize":"40px",textAlign:"center"}}>
                AI aggregator: </h3>
                <h3 className="aai-about-service-title" style={{"fontSize":"30px",textAlign:"center"}}>
                Somebody : Your one-stop portal for top-tier AI </h3>
                <p className="section-desc" style={{"textAlign":"justify"}}>
                Unleash your creative potential with OneAIChat’s, the premier AI platform that unites the world's
                most advanced generative models. Experience the magic of OpenAI's DALL-E and ChatGPT,
                the eloquence of Anthropic's Claude, the versatility of Mistral's generative AI, the knowledge
                of Google's Bard, and the artistry of Gemini's image generation. This extraordinary alliance enables
                you to explore new dimensions of creativity, pushing the boundaries of what's possible in the realm
                of generative art. OneAIChat’s ‘Somebody’ is your gateway to a future where imagination and innovation
                collide, transforming your wildest dreams into reality.
                </p>
                <br/>
                <p className="section-desc" style={{"textAlign":"justify"}}>
                No task is too intricate or lofty for OneAIChat's powerful aggregation of generative AI models. 
                Our platform brings together the most advanced and innovative AI systems, capable of manifesting
                your boldest ideas. Whether generating breathtaking visuals that push the boundaries of imagination,
                crafting compelling narratives that inspire and captivate audiences, or composing transcendent
                melodies that stir the soul, OneAIChat unites the best-in-class generative AI platforms to bring
                your creative visions to life.
              </p>
              <br/>
                <p className="section-desc" style={{"textAlign":"justify"}}>
                Through a seamless and intuitive interface, OneAIChat guides you in harnessing the full potential 
                of generative AI, turning even the most abstract concepts into tangible creations. Our platform stands
                at the forefront of innovation, bridging the gap between cutting-edge technology and artistic
                expression. With OneAIChat's aggregation of AI mastery, the possibilities are truly limitless,
                setting a new standard for what can be achieved when human ingenuity meets artificial intelligence.
              </p>
              <div className="py-5">
                  <div className="aai-about-service-cta">
                    <div className="row justify-content-between align-items-center">
                      <div className="col-lg-6" style={{width:"100%"}}>
                        <div>
                          <h3 className="aai-about-service-title" style={{"fontSize":"28px"}}>
                          Explore OneAIChat's cutting-edge Native MultiModal and 'Somebody' features by signing up or
                          logging in for a free trial before subscribing.
                          </h3>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-5 mt-lg-0">
                        <div className="d-flex justify-content-lg-end" style={{marginTop:"-30px",marginRight:"-620px"}}>
                          <a href="/pricing" className="aai-btn btn-pill-solid"
                            >Purchase Now!</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About Service End */}

      {/* Related Service */}
      <section className="aai-features pt-120" style={{"marginTop":"20px"}}>
        <div className="container">
          <div className="row">
            <div className="col-xl-8">
              <div className="aai-section-title mb-5">
                <h2 className="section-title">Related Services</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-4 col-md-6 mb-4">
              {/* <div className="aai-feature-box">
                <div
                  className="aai-feature-box-inner d-flex flex-column align-items-center"
                >
                  <div>
                    <img
                      src="assets/img/features/fi-4.svg"
                      className="aai-feature-icon img-fluid"
                      alt=""
                    />
                  </div>
                  <h3 className="aai-feature-title">Digital name generator</h3>
                  <p className="aai-feature-desc">
                    Convert data noise intelligent for a quis lorem.
                  </p>
                  <a href="services-details.html" className="aai-feature-link">
                    <svg
                      width="37"
                      height="13"
                      viewBox="0 0 37 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M29.375 1L35.055 6.27428L29.375 11.9543"
                        stroke="currentColor"
                        strokeOpacity="0.80"
                        strokeWidth="1.52"
                        strokeLinecap="round"
                      />
                      <path
                        d="M34 6.25L1 6.25"
                        stroke="currentColor"
                        strokeOpacity="0.80"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  </a>
                </div>
              </div> */}
<div className="aai-feature-box">
    <div className="aai-feature-box-inner d-flex justify-content-center">
    <a href="/pricing" >
        <div className="container">
            {/* First Row */}
          
            <div className="row">
            
                <div className="col-sm-4">
                    <img src="assets/img/logo/2.jpeg" className="aai-feature-icon img-fluid" alt="ChatGPT" />
                </div>
                <div className="col-sm-4">
                    <img src="assets/img/chat/chatgpt.svg" className="aai-feature-icon img-fluid" alt="Claude" />
                </div>
                <div className="col-sm-4">
                    <img src="assets/img/chat/mistral.jpeg" className="aai-feature-icon img-fluid" alt="Mistral" />
                </div>
             
            </div>

            {/* Second Row */}
            <div className="row">
                <div className="col-sm-4">
                    <img src="assets/img/chat/gemini.webp" className="aai-feature-icon img-fluid" alt="Gemingi" />
                </div>
                <div className="col-sm-4">
                    <img src="assets/img/chat/claude.webp" className="aai-feature-icon img-fluid" alt="Another Image" />
                </div>
                <div className="col-sm-4">
                    <img src="assets/img/chat/stability.png" className="aai-feature-icon img-fluid" alt="Yet Another Image" />
                </div>
            </div>

            {/* Third Row */}
            <div className="row">
                <div className="col-sm-4">
                    <img src="assets/img/chat/googlebard.png" className="aai-feature-icon img-fluid" alt="One More Image" />
                </div>
                <div className="col-sm-4">
                    <img src="assets/img/chat/palm.jpeg" className="aai-feature-icon img-fluid" alt="Last Image" />
                </div>
                <div className="col-sm-4">
                    <img src="assets/img/chat/dalle.jpg" className="aai-feature-icon img-fluid" alt="Final Image" />
                </div>
            </div>
        </div>
        </a>
    </div>
</div>


            </div>
            <div className="col-xl-4 col-md-6 mb-4">
            <div className="aai-feature-box">
    <div className="aai-feature-box-inner d-flex justify-content-center">
    <a href="/pricing" >
        <div className="container">
            {/* First Row */}
            
            <div className="row">
                <div className="col-sm-4">
                    <img src="assets/img/logo/2.jpeg" className="aai-feature-icon img-fluid" alt="ChatGPT" />
                </div>
                <div className="col-sm-4">
                    <img src="assets/img/chat/chatgpt.svg" className="aai-feature-icon img-fluid" alt="Claude" />
                </div>
                <div className="col-sm-4">
                    <img src="assets/img/chat/mistral.jpeg" className="aai-feature-icon img-fluid" alt="Mistral" />
                </div>
            </div>

            {/* Second Row */}
            <div className="row">
                <div className="col-sm-4">
                    <img src="assets/img/chat/gemini.webp" className="aai-feature-icon img-fluid" alt="Gemingi" />
                </div>
                <div className="col-sm-4">
                    <img src="assets/img/chat/claude.webp" className="aai-feature-icon img-fluid" alt="Another Image" />
                </div>
                <div className="col-sm-4">
                    <img src="assets/img/chat/stability.png" className="aai-feature-icon img-fluid" alt="Yet Another Image" />
                </div>
            </div>

            {/* Third Row */}
            <div className="row">
                <div className="col-sm-4">
                    <img src="assets/img/chat/googlebard.png" className="aai-feature-icon img-fluid" alt="One More Image" />
                </div>
                <div className="col-sm-4">
                    <img src="assets/img/chat/palm.jpeg" className="aai-feature-icon img-fluid" alt="Last Image" />
                </div>
                <div className="col-sm-4">
                    <img src="assets/img/chat/dalle.jpg" className="aai-feature-icon img-fluid" alt="Final Image" />
                </div>
            </div>
        </div>
        </a>
    </div>
</div>
            </div>
            <div className="col-xl-4 col-md-6 mb-4">
            <div className="aai-feature-box">
    <div className="aai-feature-box-inner d-flex justify-content-center">
    <a href="/pricing" >
        <div className="container">
            {/* First Row */}
            
            <div className="row">
                <div className="col-sm-4">
                    <img src="assets/img/logo/2.jpeg" className="aai-feature-icon img-fluid" alt="ChatGPT" />
                </div>
                <div className="col-sm-4">
                    <img src="assets/img/chat/chatgpt.svg" className="aai-feature-icon img-fluid" alt="Claude" />
                </div>
                <div className="col-sm-4">
                    <img src="assets/img/chat/mistral.jpeg" className="aai-feature-icon img-fluid" alt="Mistral" />
                </div>
            </div>

            {/* Second Row */}
            <div className="row">
                <div className="col-sm-4">
                    <img src="assets/img/chat/gemini.webp" className="aai-feature-icon img-fluid" alt="Gemingi" />
                </div>
                <div className="col-sm-4">
                    <img src="assets/img/chat/claude.webp" className="aai-feature-icon img-fluid" alt="Another Image" />
                </div>
                <div className="col-sm-4">
                    <img src="assets/img/chat/stability.png" className="aai-feature-icon img-fluid" alt="Yet Another Image" />
                </div>
            </div>

            {/* Third Row */}
            <div className="row">
                <div className="col-sm-4">
                    <img src="assets/img/chat/googlebard.png" className="aai-feature-icon img-fluid" alt="One More Image" />
                </div>
                <div className="col-sm-4">
                    <img src="assets/img/chat/palm.jpeg" className="aai-feature-icon img-fluid" alt="Last Image" />
                </div>
                <div className="col-sm-4">
                    <img src="assets/img/chat/dalle.jpg" className="aai-feature-icon img-fluid" alt="Final Image" />
                </div>
            </div>
        </div>
        </a>
    </div>
</div>
            </div>
          </div>
        </div>
      </section>
      {/* Aai Feature  End*/}

      {/* Cta Start */}
      <section
        className="aai-cta py-120"
        // style={{
        //   background: "url('assets/img/bg/cta-bg.jpeg') no-repeat center center/cover",
        // }}
      >
        <div className="container" style={{"border":"1px solid #28c434","borderRadius":"25px"}}>
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg">
              <div className="secttionn">
                <h2 className="section-title mb-4">
                The Future Isn't What It Used To Be!&nbsp;
                  <span className="position-relative">
                  
                  </span>
                  
                </h2>
               
                <div className="mt-5 d-flex flex-column flex-md-row aai-btns-group">
                  <a href="/pricing" className="aai-btn btn-pill-solid">
                    Get Started
                  </a>
                  <a href="/about" className="aai-gradient-outline-btn">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-xl-7 col-lg mt-5 mt-xl-0"
              data-aos="fade-up"
              data-aos-delay="80"
            >
              <div className="aai-cta-img">
                <img
                  src="assets/img/logo/2.jpeg"
                  className="img-fluuid"
                  alt=""
                
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Cta End */}






     </main>
    <Footer />
 </div>
    )
}
export default ServiceDetails;