import axios from "axios";

const authUserString = localStorage.getItem("authUser");
let authUser = '';

if (authUserString) {
    authUser = JSON.parse(authUserString);
  // Now you can check if authUser has the expected properties or values
}
console.log(authUser, "authUser-----------------------------------");
export const axiosInstance = axios.create({
  baseURL: "https://db.oneaichat.com/",
  headers: { Authorization: `Bearer ${authUser?.token}` },
});
let phoneNumber = '';
let email = '';
if (authUser) {
    if (authUser.email) {
        email = authUser.email;
    }
    if (authUser.phoneNumber) {
        phoneNumber = authUser.phoneNumber; // Fix: Assign to phoneNumber instead of email
    }
    const response = await axiosInstance.post(`/getuserbyemail`, { email, phoneNumber });
    try {
        // Your logic with the result goes here...
        if (response.data.token) {
            localStorage.setItem("loggedIn", true);
        } else {
            localStorage.setItem('token', '');
            localStorage.setItem('authUser', '');
            localStorage.setItem("loggedIn", false);
        }
    } catch (error) {
        console.error('Error fetching user data:', error);
    }
}
