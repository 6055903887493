import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import CheckIcon from "components/Icons/Check";
import Link from "components/Link";
 const About = () => {
return(
 <div>
    <Header />
     <main className="wrapper">
      {/* Breadcrumb Start */}
      <section
        className="aai-breadcrumb"
        // style={{
        //   background: "url('assets/img/bg/bread-crumb-bg.jpeg') no-repeat center center/cover"
        // }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-auto">
              <div className="text-center">
                <h2 className="aai-page-title">About Us</h2>
                <ul className="aai-breadcrumb-nav d-flex align-items-center justify-content-center">
                  <li>
                  <a href="/" className="aai-breadcrumb-link text-decoration-underline">
                      Home
                    </a>
                  </li>
                  <li>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 16L18 12M18 12L14 8M18 12L6 12"
                        stroke="#DADADA"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </li>
                  <li>
                    <a href="" className="aai-breadcrumb-link"> About Us </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Breadcrumb End */}
         {/* Service Start */}
         
         <section className="aai-services">
           
         <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg">
                  <div>
                    {/* <h2 className="section-title mb-4">
                    Create Stunning Content in Single Click with Oneaichat's Advanced AI
                    </h2> */}
                    <p className="section-desc"style={{"textAlign":"justify"}}>
                    OneAIChat is built upon Native models and our bot is called ‘Somebody’ which is master in
                   Generative AI text to Text, text to Image, text to Video, text to Document and text to Music. 
                   ‘Somebody’ provides ultra focused, knowledge specific 11 categories, enabling users to cut noise
                    while doing domain specific tasks.
                  In this process we have made ’Somebody’ a thorough aggregator by powering it with world class LLM,
                   this allow users to comfortably access Generative AI’s might at one single subscription without
                    any limits on prompts.
                    </p>
              
                    <div className="mt-5">
                      <Link
                        to="/pricing"
                        className="aai-gradient-outline-btn"
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-6 col-lg mt-5 mt-lg-0"
                  data-aos="fade-left"
                >
                  <div>
                    <img
                      src="assets/img/logo/2.jpeg"
                      className="img-fluid"
                      alt=""
                      width={"80%"}
                      style={{"borderRadius":"25px"}}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br/>
               <br/>
               <br/>
               <br/>
               <br/>
               <br/>
        <div className="pb-80">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6" data-aos="fade-right">
                <div>
                  <img
                    src="assets/img/about/abt.jpg"
                    className="img-fluid"
                    alt=""
                    style={{"border-top-right-radius": "46px","border-top-left-radius":" 19px", "border-bottom-right-radius": "45px",
                    "border-bottom-left-radius": "19px","marginTop":"-60px"}}
                  />
                </div>
              </div>
              <div className="col-lg-6 mt-5 mt-lg-0">
                <div className="aboutsect" >
                  <h2 className="section-title mb-4">
                   At OneAIChat, we live by one unwavering principle
                  </h2>
                  <p className="section-desc" style={{"textAlign":"justify","wordSpacing":"6px"}}>
                  Never settle for good enough. We're committed to delivering an unparalleled AI experience
                  that elevates your online interactions. Our relentless pursuit of excellence drives us to seek
                  out the most advanced technologies, methodologies, and user-centric designs. We refine, iterate,
                   and perfect every detail, ensuring that our product exceeds your expectations.
                  </p>
              
                  <div className="mt-5" style={{"backgroundColor":"white"}}>
                    <a href="/pricing" className="aai-gradient-outline-btn" style={{"marginTop":"1rem"}}>Get Started</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="py-80"
          // style={{
          //   background: "url('assets/img/bg/about-bg.jpeg') no-repeat center center/cover"
          // }}
        >
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6">
                <div className="secttion" >
                  <h2 className="sectionn-title mb-4">
                     OneAIChat's Commitment to Continuous Improvement.
                  </h2>
                  <p className="section-desc" style={{"textAlign":"justify","wordSpacing":"6px"}}>
                  We believe in the power of continuous improvement, and we work tirelessly 
                  to better our platform in every way possible. By pushing boundaries and reimagining
                  possibilities, we create an AI chat experience that breaks new ground in communication,
                  connection, and creativity. Every day, with every conversation, OneAIChat strives to be 
                  the best version of itself, so you can enjoy an AI companion like no other.

                  </p>
                  
                  <div className="mt-5" style={{"backgroundColor":"white"}}>
                    <a href="/pricing" className="aai-gradient-outline-btn"style={{"marginTop":"1rem"}}>Get Started</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-5 mt-lg-0" data-aos="fade-right">
                <div className="position-relative aai-about-video">
                  <img
                    src="assets/img/others/mac.png"
                    className="img-fluid"
                    alt=""
                  />
                  <a
                    href="https://m.youtube.com/watch?v=2IK3DFHRFfw"
                    className="aai-about-play-icon video-popup"
                  >
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5 25.0179V10.9821C10.5 9.43849 12.1744 8.47675 13.5077 9.25452L25.5385 16.2724C26.8615 17.0442 26.8615 18.9558 25.5385 19.7276L13.5077 26.7455C12.1744 27.5233 10.5 26.5615 10.5 25.0179Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <br/>
          <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            
          <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-xl-6 col-lg order-2 order-lg-1 mt-5 mt-lg-0"
                  data-aos="fade-right"
                >
                  <div>
                    <img
                      src="assets/img/services/service-1.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg order-1 order-lg-2">
                  <div className="singleclick">
                    <h2 className="section-title mb-4">
                    Oneaichat: Powerful Content Creation
                    </h2>
                    <p className="section-desc" style={{"textAlign":"justify"}} >
                    Oneaichat Streamline your content efforts. Provide brief details and Oneaichat generates blogs,
                    ads within moments. Beyond words, it crafts visuals, audio, documents, music for communicating
                    concepts distinctly.
                    </p>
                    <br/>
                    <p className="section-desc" style={{"textAlign":"justify"}} >
                    Its abilities include imaging, videoing, podcasting without design toil. The AI understands ideas,
                     brings them to life visually, audibly for engagement. It also informs narratives, characters with 
                     suitable sentiment for connecting with others rationally.
                    </p>
                    <ul className="aai-list-items mt-4">
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                        Generate Images - Audio - Video - Documents
                        </span>
                      </li>
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                        User world class services like ChatGPT - Claude - Gemini - Mistral - Somebody
                        </span>
                      </li>
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                        Discipline your focus with our curated categories
                        </span>
                      </li>
                    </ul>
                    <div className="mt-5">
                      <Link
                        to="/pricing"
                        className="aai-gradient-outline-btn"
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg">
                  <div>
                    <h2 className="section-title mb-4">
                    Create Stunning Content in Single Click with Oneaichat's Advanced AI
                    </h2>
                    <p className="section-desc"style={{"textAlign":"justify"}}>
                    Give Oneaichat a brief description and it will automatically generate high-quality blog articles,
                     product listings, social media posts and more. But that's not all, with Oneaichat you can also 
                     effortlessly create vivid images, engaging videos, informative audio clips, professional
                     documents and even personalized music - all within seconds!

                    </p>
                    <ul className="aai-list-items mt-4">
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                        Generate Images - Audio - Video - Documents
                        </span>
                      </li>
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                        User world class services like Chat GPT - Claude - Gemini - Mistral - Somebody 
                        </span>
                      </li>
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                        Discipline your focus with our curated categories
                        </span>
                      </li>
                    </ul>
                    <div className="mt-5">
                      <Link
                        to="/pricing"
                        className="aai-gradient-outline-btn"
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-6 col-lg mt-5 mt-lg-0"
                  data-aos="fade-left"
                >
                  <div>
                    <img
                      src="assets/img/services/service-2.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-xl-6 col-lg order-2 order-lg-1 mt-5 mt-lg-0"
                  data-aos="fade-right"
                >
                  <div>
                    <img
                      src="assets/img/services/service-3.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg order-1 order-lg-2">
                  <div>
                    <h2 className="section-title mb-4">
                    Leverage the Power of Visual & Audio AI
                    </h2>
                    <p className="section-desc"  style={{"textAlign":"justify"}}>
                    Take your content to the next level with Oneaichat's cutting-edge visual and audio
                    AI capabilities. Generate customized graphics, memes, presentations, podcasts, soundtrack
                    clips and other multimedia content without any design or editing skills. Our advanced AI
                    understands concepts and can bring your words to life through stunning visuals and audio.
                    </p>
                    <ul className="aai-list-items mt-4">
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                        Generate Images - Audio - Video - Documents
                        </span>
                      </li>
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                        User world class services like Chat GPT - Claude - Gemini - Mistral - Somebody
                        </span>
                      </li>
                      <li className="aai-list-item d-flex align-items-center">
                        <CheckIcon />
                        <span className="aai-list-item-text">
                        Discipline your focus with our curated categories
                        </span>
                      </li>
                    </ul>
                    <div className="mt-5">
                      <Link
                        to="pricing"
                        className="aai-gradient-outline-btn"
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>
      {/* Service End */}
 {/* Faq Start */}
       <section className="aai-faq py-120">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-5 col-lg">
              <div className="secttionn">
                <h2 className="section-title mb-4">
                  Any <span className="gradient-text">Questions?</span> <br />
                  We have Answers!
                </h2>
                <p className="section-desc" style={{textAlign:"center"}}>
                 <b>Don’t find answers here? </b> 
                </p>
                <div className="mt-5"style={{marginLeft:"12rem"}}>
                  <a href="/contact" className="aai-gradient-outline-btn">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-xl-6 col-lg mt-5 mt-xl-0"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="aai-accordions">
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        What is OneAI-Chat?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <p className="aai-accordion-text">
                        Deep learning, Machine learning and Artificial 
                        Intelligence service company. Give a spin to 
                        our native bot ‘Somebody’.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        Tell me more about OneAI-Chat?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <p className="aai-accordion-text">
                        OneAI-Chat is a AI aggregation platform with 
                        multimodal offerings, distributed in focused 
                        categories. 
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                      Why do I need this?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <p className="aai-accordion-text">
                        To get brief answers to the big questions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour"
                      >
                      Why do I pay for this? 
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <p className="aai-accordion-text">
                        Can get answers from OneAI’s category 
                        focused services and best in class global AI 
                        services, along with necessary features, all on 
                        one platform. 
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFive"
                        aria-expanded="false"
                        aria-controls="flush-collapseFive"
                      >
                        Tell me more about features?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFive"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <p className="aai-accordion-text">
                        Subscription service offers you to upload a 
                        document, copy a message, share a message 
                        from focused category.  <br/>
                        ‘Uploading a document’ provides you to 
                          extract important outputs from your own huge 
                          datasets or files. 
                          ‘Copying a message’ adds on to your 
                          productivity. 
                          <br/>
                          ‘Sharing a message’ adds on to your comfort. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
{/* Faq End */}
    {/* Cta Start */}
     {/* Cta Start */}
     <section
        className="aai-cta pb-120"
        // style={{
        //   background: "url('assets/img/bg/cta-bg.jpeg') no-repeat center center/cover"
        // }}
      >
        <div className="container" style={{"border":"1px solid #28c434","borderRadius":"25px"}}>
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg">
              <div className="secttionn">
                <h2 className="section-title mb-4">
                The Future Isn't What It Used To Be!&nbsp;
                  <span className="position-relative">
                  
                  </span>
                  
                </h2>
            
                <div className="mt-5 d-flex flex-column flex-md-row aai-btns-group">
                  <a href="/pricing" className="aai-btn btn-pill-solid">
                    Get Started
                  </a>
                  <a href="/about" className="aai-gradient-outline-btn">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-xl-7 col-lg mt-5 mt-xl-0"
              data-aos="fade-up"
              data-aos-delay="80"
            >
              <div className="aai-cta-img">
                <img
                  src="assets/img/logo/2.jpeg"
                  className="img-fluuid"
                  alt=""
                
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Cta End */}










      </main>
      <Footer />
 </div>
    )
 }
 export default About;