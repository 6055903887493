import { createBrowserRouter } from "react-router-dom";
import Home from "pages/Home";
import Login from "pages/Login";
import Register from "pages/Register";
import About from "pages/About";
import Services from "pages/Services";
import Contact from "pages/Contact";
import Blog from "pages/Blog";
import BlogD from "pages/BlogD";
import ServiceDetails from "pages/ServiceDetails";
import Pricing from "pages/Pricing";
import ChangePass from "pages/ChangePass";
import ForgotPass from "pages/ForgotPass";
import Payment from "pages/PaymentSucess";
import ChangePassword from "pages/ChangePass";
import ChatPage from "pages/Chat/Chat";
import Authenticated from "components/Authenticated";
import ResetPassword from "pages/Resetpassword"
import PrivacyPolicy from "pages/PrivacyPolicy"
import TermsUse from "pages/TermsUse"
import RefundAndCancellation from "pages/RefundAndCancellation"
import Phone from "../pages/Phone";
import PaymentForm from "../pages/PaymentForm";
import PaymentSuccessPage from "pages/Returnbilldesk";
import Setting from "pages/Setting";
import Profile from "pages/Profile";
import Billdesk from "pages/Billdesk";
import RedirectPage from "pages/Redirect";
import  Callback from "pages/Callback"
// import Testing from "pages/Testing";
// import TTesting from "pages/TTesting";
// import Invoice from "pages/Invoice";
import CheckToken from "pages/CheckToken";

const router = createBrowserRouter([
  {
    path: "/check",
    element: <CheckToken />,
  },
  // {
  //   path: "/invoice",
  //   element: <Invoice />,
  // },
  // {
  //   path: "/ttesting",
  //   element: <TTesting />,
  // },
  // {
  //   path: "/testing",
  //   element: <Testing />,
  // },
  {
    path: "/success",
    element: <Callback />,
  }, 
  {
    path: "/setting",
    element: <Setting/>,
  }, 
  {
    path: "/redirect",
    element: <RedirectPage/>,
  }, 
  {
    path: "/apicall",
    element: <Billdesk/>,
  }, 
  {
    path: "/payment",
    element: <PaymentForm />,
  }, 
  {
    path: "/returnbill",
    element: <PaymentSuccessPage />,
    method:"POST"
  }, 
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/phone",
    element: 
    <Phone />,
  },
  {
    path: "/",
    element:
    
    <Home />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "/blogd",
    element: <BlogD />,
  },

  {
    path: "/servicedetails",
    element: <ServiceDetails />,
  },
  {
    path: "/pricing",
    element: <Pricing />,
  },
  {
    path: "/change-password",
    element: <ChangePass />,
  },
  {
    path: "/password-reset",
    element: <ForgotPass />,
  },
  {
    path: "/chat",
    element: (
      <Authenticated>
        <ChatPage />
      </Authenticated>
    ),
  },
  {
    path: "/chat/:roomId",
    element: (
      <Authenticated>
        <ChatPage />
      </Authenticated>
    ),
  },
  {
    path: "/payment_success",
    element: <Payment />,
  },
  {
    path: "/reset-password/:token",
    element: < ResetPassword/>,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms-use",
    element: <TermsUse />,
  },
  {
    path: "/refund-and-cancellation",
    element: <RefundAndCancellation />,
  },
]);

export default router;
