import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Link from "components/Link";
import Header from "components/Header";
import Alert from "components/common/Alert";
import useForgotPassword from "hooks/useForgotPassword";
import { useNavigate } from "react-router-dom";

function ForgotPass() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [alert, setAlert] = useState({ open: false, type: "", message: "" });
  
  const onSuccess = (res) => {
    reset(); // Reset the form fields
    if (res?.data?.status) {
      alertHandler(true, "success", "Password reset email sent successfully!"); // Show a success message
    } else {
      alertHandler(true, "danger", res?.data?.message ?? "Password reset failed!"); // Show an error message
    }
  };
  const onError = (err) => {
    if (err.response && err.response.data && err.response.data.message) {
      alertHandler(true, "danger", err.response.data.message);
    } else {
      alertHandler(true, "danger", "Password reset request failed. Please try again later.");
    }
  };
  

const alertHandler = (open, type = "", message = "") => {
    setAlert({ open, type, message });
  };

  const { mutateAsync: forgotPassword } = useForgotPassword(onSuccess, onError);
 
  
  const onSubmit = async (data) => {
    try {
      await forgotPassword(data);
   // After successful submission, navigate to the login page after a delay of 3 seconds
      setTimeout(() => {
        navigate("/login");
      }, 4000);
    } catch (err) {
      console.log(err.message);
    }
  };
  

  return (
    <div>
      {/* Header */}
      <Header className="position-absolute top-0 left-0 w-100 pt-4" showMenu={false} />
      <main className="wrapper">
        {/* Reset Password Form */}
        <section
          className="aai-signup-in"
          // style={{
          //   background:
          //     "url('assets/img/bg/sign-in-up.jpeg') no-repeat center center/cover",
          // }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-5 col-lg-4 d-none d-xl-block order-2 order-lg-1">
                <div className="position-relative">
                  <img
                    src="assets/img/others/ai-ills.svg"
                    className="img-fluid aai-signup-in-img"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-xl-7 order-1 ps-xl-5 order-lg-2 mb-5 mb-lg-0">
                <div className="aai-form-wrapper">
                  <div>
                    <div className="aai-form-header d-flex justify-content-center text-center flex-column align-items-center">
                      <h2 className="aai-form-title">Reset Password</h2>
                      <p className="aai-form-desc">Send a reset link to your email</p>
                    </div>
                  </div>
                  <div>
                    {alert?.open && (
                      <Alert
                        type={alert?.type}
                        message={alert?.message}
                        onClose={alertHandler}
                      />
                    )}
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row g-3" style={{ marginTop: "10px" }}>
                        <div className="col-lg-12 mb-2">
                          <div className="aai-form-container position-relative">
                            <input
                              type="email"
                              className={`form-control shadow-none ${
                                errors?.email ? "is-invalid" : ""
                              }`}
                              placeholder="Email Address"
                              {...register("email", {
                                required: {
                                  value: true,
                                  message: "Email is required!",
                                },
                                pattern: {
                                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  message: "Invalid email address",
                                },
                              })}
                            />
                            {errors?.email && (
                              <div className="mx-4 invalid-feedback">
                                {errors?.email?.message ?? ""}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="d-grid">
                            <button className="aai-btn btn-pill-solid" type="submit">
                              Send Reset Email
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt-3">
                        <p className="aai-form-support-text">
                          Remember your password?
                          <Link
                            to="/login"
                            className="aai-form-support-link text-decoration-underline"
                          >
                            Sign In
                          </Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  
  );
};

export default ForgotPass;
