import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "../pages/ThemeContext";
import Chatheader from "components/Header/ChatHeader/Chatheader";
import { axiosInstance } from "config/axios";
import "./Setting.css";
import { aiList as importedAiList, subcategoryList } from "data/ailist";
import styles from "./Chat.module.css";
function Setting() {
  const [transactionDetails, setTransactionDetails] = useState(null);

  // useEffect(() => {
  //   const fetchTransactionDetails = async () => {
  //     try {
  //       // Get the transaction ID from the cookie
  //       const transactionId = getCookie("transactionId");
  //       const authUser = JSON.parse(localStorage.getItem("authUser"));
  //       // Make a POST request to the backend to get transaction details
  //       const response = await axiosInstance.post("/api/transactions/details", {
  //         transactionId,
  //         user_id: authUser?._id,
  //       });
  //       console.log("Response from /api/transactions/details:", response.data);
  //       // Set the transaction details in the state
  //       setTransactionDetails(response.data);
  //     } catch (error) {
  //       console.error("Error fetching transaction details:", error);
  //     }
  //   };

  //   fetchTransactionDetails();
  // }, []);

  // Function to get cookie by name
  const getCookie = (name) => {
    const match = document.cookie.match(
      new RegExp("(^| )" + name + "=([^;]+)")
    );
    return match ? match[2] : null;
  };

  const [aiName, setAIName] = useState("OneAI Chat");
  const aiNameHandler = (name, status) => {
    setAIName(name);
    navigate(`/chat`, { replace: true, state: { selectedCategory: name } });
  };

  let authUser = "";
  const authUserString = localStorage.getItem("authUser");
  if (authUserString) {
    authUser = JSON.parse(authUserString);
    //  const authUser = JSON.parse(localStorage.getItem('authUser'));
  }
  //  const authUser = JSON.parse(localStorage.getItem('authUser'));
  const firstName = authUser ? authUser.firstName : "";
  const lastName = authUser ? authUser.lastName : "";
  const email = authUser ? authUser.email : "";
  const phoneNumber = authUser ? authUser.phoneNumber : "";
  const closeMessage = () => {
    setShowMessages(false);
  };
  const [showMessages, setShowMessages] = useState(false);
  const [Messages, setMessages] = useState("");
  const [aiList, setAiList] = useState(subcategoryList); // Default to the full AI list

  const goToHomePage = () => {
    navigate("/"); // Assuming that your home page route is '/'
  };
  const logoutHandler = () => {
    localStorage.clear();
    window.location.assign("/");
  };
  const navigate = useNavigate();
  const [theme, setTheme] = useState("system"); // default to system
  const handleThemeChange = (e) => {
    const selectedTheme = e.target.value;

    setTheme(selectedTheme);

    if (selectedTheme === "light") {
      document.documentElement.setAttribute("data-theme", "light");
    } else if (selectedTheme === "dark") {
      document.documentElement.setAttribute("data-theme", "dark");
    } else {
      document.documentElement.removeAttribute("data-theme");
    }
  };
  const logoutAllDevicesHandler = async () => {
    try {
      const userId = authUser?._id;
      if (userId) {
        // Make a request to the logout API
        const response = await axiosInstance.post(`/signout`, {
          userId,
          userId,
        });
        if (response.status) {
          // Clear localStorage on successful logout
          localStorage.clear();
          // Redirect to the home page
          window.location.assign("/");
        } else {
          console.error("Logout unsuccessful");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const userId = authUser?._id;
  const deletAllChats = async () => {
    const confirmDelete = window.confirm(
      "You cannot undo this action. This will delete all your chats with all bots on oneAiChat's servers."
    );
    if (userId && confirmDelete) {
      // Make a request to the logout API
      const response = await axiosInstance.post(`/chat/deleteallchat`, {
        userId,
      });
      if (response.data.status) {
        setMessages(response.data.message);
        setShowMessages(true);
      } else {
        console.error("Logout unsuccessful");
      }
    }
  };
  const deleteAccount = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete your account?"
    );
    if (userId && confirmDelete) {
      // Make a request to the logout API
      const response = await axiosInstance.post(`/chat/deleteaccount`, {
        userId,
      });
      if (response.data.status) {
        localStorage.clear();
        navigate("/");
      } else {
        console.error("Logout unsuccessful");
      }
    }
  };

  return (
    <ThemeProvider>
      <Chatheader />
      <div className={styles.wrapper}>
        <div className={styles.tools}>
          <div className={styles.search}>
            <div className={styles.textboxWrapper}>
              <input
                type="text"
                placeholder="Search for Bots"
                className={styles.textbox}
                id="search-bots"
              />
              <label htmlFor="search-bots">
                <i className="fa fa-search" />
              </label>
            </div>
          </div>

          {/* ... (other JSX) */}

          {/* Chat tools */}
          <div>
            <div className={styles.chatsTools}>
              {aiList?.map((ai) => {
                return (
                  <div
                    className={`${styles.aiCategory} ${
                      aiName === ai?.title ? styles.active : ""
                    }`}
                    key={ai?.id}
                    onClick={() => aiNameHandler(ai?.title, true)}
                    // style={{
                    //   border: ai?.name === "oneaichat" ? "2px solid #0f0" : "unset",
                    //   backgroundColor: ai?.name === "oneaichat" ? "#ddd" : "unset",
                    //   marginTop:ai?.name === "oneaichat" ? "1rem":"unset",// Add background color condition
                    //   borderRadius: ai?.name === "oneaichat" ? "10px" : "unset", // Adjust the radius as needed
                    // }}
                  >
                    <div className={styles.aiLogoWrapper}>{ai?.icon}</div>
                    <div className={styles.aiToolInfo}>
                      <div className={styles.aiTitle}>{ai?.title}</div>
                      <div className={styles.aiSubTitle}>{ai?.subTitle}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <center>
        <div className="payment-success-page">
          <div className="containerr">
            <div className="box">
              <div className="label label-subscription">Subscription</div>
              <div className="block1">
                <div className="label">Subscribe</div>

                <p>
                  &nbsp; &nbsp; You are using Oneaichat with Subscription.
                  Subscribe to get access all bots on
                  &nbsp;&nbsp;&nbsp;&nbsp;Oneaichat instantly, including GPT-4
                  by OpenAI{" "}
                </p>
              </div>
              <br />
              <div className="block2">
                <div className="label">FirstName:</div>
                <div className="value">{firstName}</div>
              </div>
              <br />
              <div className="block3">
                <div className="label">LastName:</div>
                <div className="value">{lastName}</div>
              </div>
              <div className="block4">
                <div className="label">Subscription:</div>
                <div className="value">
                  {transactionDetails?.plan_name ||
                    transactionDetails?.planName ||
                    ""}
                </div>
              </div>
              <div className="block5">
                <div className="label">Plan Amount:</div>
                <div className="value">
                  {transactionDetails?.amount || ""}
                </div>
              </div>

              <br />
              <div className="block6">
                <div className="label">Start Date:</div>
                <div className="value">
                  {transactionDetails?.startDate || ""}
                </div>
              </div>

              <div className="block7">
                <div className="label">End Date:</div>
                <div className="value">
                  {transactionDetails?.endDate || ""}
                </div>
              </div>
              <br />
              <div className="block8">
                <div className="label">Plan Currency:</div>
                <div className="value">INR</div>
              </div>
              <div className="block9">
                <div className="label">Phone Number:</div>
                <div className="value">{phoneNumber}</div>
              </div>
              <br />
              <br />
              <br />
              <br />
              <div className="block10">
                <div className="label">Email:&nbsp;</div>
                <div className="value">{email}</div>
              </div>
              <br />
              <br />
              <div className="block11">
                <div className="theme-selector">
                  <div className="label">Appearance</div>
                  <label>
                    <input
                      type="radio"
                      value="light"
                      checked={theme === "light"}
                      onChange={handleThemeChange}
                    />
                    Light theme
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="dark"
                      checked={theme === "dark"}
                      onChange={handleThemeChange}
                    />
                    Dark theme
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="system"
                      checked={theme === "system"}
                      onChange={handleThemeChange}
                    />
                    System (automatic)
                  </label>
                </div>
              </div>
              <p className="choice">
                Your choice will only affect this device.
              </p>
              <br />
              <div className="blocckk">
                <div className="info-section">
                  <a href="/about" className="info-link">
                    <h2 style={{ marginLeft: "-530px" }}>About</h2>
                  </a>
                  <hr className="line" />
                  <a href="/services" className="info-link">
                    <h2 style={{ marginLeft: "-465px" }}>Privacy policy</h2>
                  </a>
                  <hr className="line" />
                  <a href="/services" className="info-link">
                    <h2 style={{ marginLeft: "-452px" }}>Terms of service</h2>
                  </a>
                  <hr className="line" />
                  <a href="/about" className="info-link">
                    <h2 style={{ marginLeft: "-330px" }}>
                      Cancellation and Refund Policy
                    </h2>
                  </a>
                  <hr className="line" />
                  <a href="/contact" className="info-link">
                    <h2 style={{ marginLeft: "-500px" }}>Contact us</h2>
                  </a>
                </div>
              </div>

              <button className="buttonn" onClick={() => goToHomePage()}>
                Go to Home
              </button>
              <br />
              <br />
              <div className="text-cennter">
                <button onClick={deletAllChats}>
                  <div style={{ color: "#ad1840" }}>
                    <p>
                      <b>Delete All Chats</b>
                    </p>
                  </div>
                </button>
                <br />
                <button onClick={logoutHandler}>
                  <div style={{ color: "#ad1840" }}>
                    <p>
                      <b>Log out</b>
                    </p>
                  </div>
                </button>
                <br />
                <button onClick={logoutAllDevicesHandler}>
                  <div style={{ color: "#ad1840" }}>
                    <p>
                      <b>Log out of all devices</b>
                    </p>
                  </div>
                </button>
                <br />
                <button onClick={deleteAccount}>
                  <div style={{ color: "#ad1840" }}>
                    <p>
                      <b>Delete Account</b>
                    </p>
                  </div>
                </button>
              </div>
              <br />
            </div>
          </div>
        </div>
      </center>
      <div className={`message-box ${showMessages ? "active" : ""}`}>
        <button className="close-btn" onClick={closeMessage}>
          &times;
        </button>
        {Messages}
      </div>
    </ThemeProvider>
  );
}

export default Setting;
