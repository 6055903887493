import { useInfiniteQuery } from "react-query";
import { axiosInstance } from "config/axios";

const usePaginatedRequest = ({
  key,
  url,
  variables = {},
  method,
  getNextPageParam,
  getPreviousPageParam,
  select,
  ...rest
}) => {
  const queryFn = async ({ pageParam = 1 }) => {
    if (method === "post") {
      return await axiosInstance.post(url, { ...variables, page: pageParam });
    }
    return await axiosInstance.get(url);
  };

  return useInfiniteQuery({
    queryKey: key,
    queryFn,
    getPreviousPageParam,
    getNextPageParam,
    select,
    retry: false,
    refetchOnWindowFocus: false,
    ...rest,
  });
};

export default usePaginatedRequest;
