import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "./InfiniteTopScroll.module.css";

const InfiniteTopScroll = (props) => {
  const { id, classes, hasMore, inverse, next, items, ...rest } = props;
  return (
    <div id={id} className={classes?.root ?? styles.root}>
      <InfiniteScroll
        dataLength={items?.length}
        next={next}
        inverse={inverse}
        className={inverse ? styles.infiniteTopScroll : styles.infiniteScroll}
        hasMore={hasMore}
        loader={
          <div className={styles.loader}>
            <i className="fa fa-spinner fa-pulse" />
          </div>
        }
        scrollableTarget={id}
        {...rest}
      >
        {items}
      </InfiniteScroll>
    </div>
  );
};

InfiniteTopScroll.defaultProps = {
  id: `top-scroll-${Math.floor(Math.random() * 1000)}`,
  classes: {},
  inverse: true,
  endMessage: <div className={styles.endMessage}>No More Record</div>,
};

export default InfiniteTopScroll;
