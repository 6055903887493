import React from "react";
import SingleAI from "./SingleAI";
import { aiList } from "data/ailist";

const AIList = (props) => {
  return aiList?.map((ai) => (
    <SingleAI icon={ai?.icon} name={ai?.name} key={ai?.id} />
  ));
};

export default AIList;
