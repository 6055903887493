import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { axiosInstance } from "config/axios";
import Alert from "components/common/Alert";
import CloseEye from "components/Icons/CloseEye";
import OpenEye from "components/Icons/OpenEye";
function ResetPassword() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [alert, setAlert] = useState({ open: false, type: "", message: "" });
 
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const alertHandler = (open, type = "", message = "") => {
    setAlert({ open, type, message });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      alertHandler(true, "danger", "Passwords do not match");
      return;
    }

    try {
      const response = await axiosInstance.post(`/reset-password/${token}`, {
        newPassword: newPassword
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    
      // Assuming your server sends a status flag in the response
      if (response.data.status) {
        alertHandler(true, "success", "Password reset successfully!");
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        // Handle a successful response from the server, but with a false status
        alertHandler(true, "danger", response.data.message || "Password reset failed!");
      }
    } catch (error) {
      // Only enter this block if an actual error occurred during the request
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        alertHandler(true, "danger", error.response.data.message || "An error occurred during the request.");
      } else if (error.request) {
        // The request was made but no response was received
        alertHandler(true, "danger", "No response was received.");
      } else {
        // Something happened in setting up the request that triggered an error
        alertHandler(true, "danger", "Error setting up the request.");
      }
    }
    
  };

  return (
    <div>
    
      <main className="wrapper">
        <section className="aai-signup-in">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-7 order-1 ps-xl-5 order-lg-2 mb-5 mb-lg-0" style={{marginInlineStart:"250px"}}>
                <div className="aai-form-wrapper">
                  <div>
                    <div className="aai-form-header d-flex justify-content-center text-center flex-column align-items-center">
                      <h2 className="aai-form-title">Reset Password</h2>
                      <p className="aai-form-desc">Enter your new password</p>
                    </div>
                  </div>
                  <div>
                    {alert?.open && (
                      <Alert
                        type={alert?.type}
                        message={alert?.message}
                        onClose={() => alertHandler(false)}
                      />
                    )}
                    <form onSubmit={handleSubmit}>
                      <div className="row g-3" style={{ marginTop: "10px" }}>
                        <div className="col-lg-12 mb-2">
                          <div className="aai-form-container position-relative">
                            <input
                                type={showNewPassword ? "text" : "password"}
                              className="form-control shadow-none"
                              placeholder="New Password"
                              value={newPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                              required
                            />
                            <button
                 type="button"
              onClick={() => setShowNewPassword(!showNewPassword)}
              style={{ position: 'absolute', marginTop:'-42px',marginLeft:'550px' }}
             >
              {showNewPassword ?  <OpenEye /> : <CloseEye />}
              </button>
                          </div>
                        </div>
                        <div className="col-lg-12 mb-2">
                          <div className="aai-form-container position-relative">
                            <input
                            className="form-control shadow-none"
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm Password"
              required
            />
                  <button
              type="button"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              style={{ position: 'absolute', marginTop:'-42px',marginLeft:'550px' }}
            >
              {showConfirmPassword ? <OpenEye /> : <CloseEye />}
            </button>       
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="d-grid">
                            <button className="aai-btn btn-pill-solid" type="submit">
                              Reset Password
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ResetPassword;
