// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserInfo_wrapper__KgS2Y .UserInfo_userName__RRZjc {
  margin-right: 30px;
}

.UserInfo_wrapper__KgS2Y .UserInfo_userName__RRZjc::after {
  color: #fff;
  margin-left: 20px;
}

.UserInfo_userInfo__wZkQr {
    max-width: 300px;
}
.UserInfo_dropdown-toggle__3Um6i {
  font-size: 16px;
  color: #333; 
  background-color: #eee;
  /* etc */
}
.UserInfo_customToggle__r4Y6G {
  width: 160px;
  border-radius: 25px;
  background-color: #09bd3e;
  height: 55px;
}
.UserInfo_customToggle__r4Y6G:hover {
  background: #09bd3e !important;
  border: none !important;
}
.UserInfo_customToggle__r4Y6G:focus {
  background-color: #09bd3e !important;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/UserInfo/UserInfo.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;IACI,gBAAgB;AACpB;AACA;EACE,eAAe;EACf,WAAW;EACX,sBAAsB;EACtB,QAAQ;AACV;AACA;EACE,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;AACd;AACA;EACE,8BAA8B;EAC9B,uBAAuB;AACzB;AACA;EACE,oCAAoC;EACpC,YAAY;AACd","sourcesContent":[".wrapper .userName {\n  margin-right: 30px;\n}\n\n.wrapper .userName::after {\n  color: #fff;\n  margin-left: 20px;\n}\n\n.userInfo {\n    max-width: 300px;\n}\n.dropdown-toggle {\n  font-size: 16px;\n  color: #333; \n  background-color: #eee;\n  /* etc */\n}\n.customToggle {\n  width: 160px;\n  border-radius: 25px;\n  background-color: #09bd3e;\n  height: 55px;\n}\n.customToggle:hover {\n  background: #09bd3e !important;\n  border: none !important;\n}\n.customToggle:focus {\n  background-color: #09bd3e !important;\n  border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `UserInfo_wrapper__KgS2Y`,
	"userName": `UserInfo_userName__RRZjc`,
	"userInfo": `UserInfo_userInfo__wZkQr`,
	"dropdown-toggle": `UserInfo_dropdown-toggle__3Um6i`,
	"customToggle": `UserInfo_customToggle__r4Y6G`
};
export default ___CSS_LOADER_EXPORT___;
