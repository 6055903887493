import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import Link from "components/Link";
import Header from "components/Header";
import GoogleIcon from "components/Icons/Google";
import AppleSignIn from 'react-apple-signin-auth';
import AppleIcon from "components/Icons/Apple";
import CloseEye from "components/Icons/CloseEye";
import OpenEye from "components/Icons/OpenEye";
import Alert from "components/common/Alert";
import useLogin from "hooks/useLogin";
import { initialAlert } from "constants/general";
import "./Login.css";
import { FlagIcon } from 'react-flag-kit';
import Loader from "./Loader";
import { axiosInstance } from "config/axios";
const OtpModal = ({
  onClose,
  onVerify,
  phoneNumber,
  otp,
  countryCode,
  email,
  setOtp,
  message,
  closeMessage,
  resending,
  resendMessage,
  onResendOtp,
}) => {
  // handleChange and other functions for OTP modal
  let deliveryMethodMessage;
  if (phoneNumber) {
    deliveryMethodMessage = `OTP sent to ${countryCode}  ${phoneNumber}`;
  } else if (email) {
    deliveryMethodMessage = `OTP sent to ${email}`;
  }
   

  return (
    <div className="otp-modal-backdrop">
      <div className="otp-modal -tex">
        <div className="otp-modal-header">
          <h2>Login or Signup</h2>
          <button className="otp-modal-close" onClick={onClose}>
            &times;
          </button>
        </div>
        <p className="otp-modal-text">{deliveryMethodMessage}</p>

        <div className="otp-modal-body">
          <input
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            maxLength="4"
            placeholder="Enter OTP"
            className="otp-modal-input"
          />
          <div className="otp-modal-actions">
            {message && (
              <div className="otp-modal-message">
                <p>{message}</p>
                <button onClick={closeMessage}>X</button>
              </div>
            )}
            {resendMessage && (
              <p className="otp-resend-message">{resendMessage}</p>
            )}
            <br />
            <button className="otp-modal-button" onClick={onVerify}>
              VERIFY OTP
            </button>
            <button
              className="otp-modal-link"
              onClick={onResendOtp}
              disabled={resending}
            >
              {resending ? "Resending..." : "RESEND OTP"}
            </button>
          </div>
          <p className="otp-modal-or">--OR--</p>
          <Link to="/register">
            <button onClick={onClose} style={{ textDecoration: "underline" }}>
              NEW CUSTOMER? START HERE.
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

const Login = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status
  const [alert, setAlert] = useState({ ...initialAlert });
  const [otpAlert, setOtpAlert] = useState({
    open: false,
    type: "",
    message: "",
  });

  function generateNonce() {
    // Use a library or a secure method to generate a random string
    // This is a simplified example, and you should use a secure random number generator
    const randomString = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    
    return randomString;
  }
// {/*Apple sign up start--------- */}
//   const getCookie = (name) => {
//     const value = `; ${document.cookie}`;
//     const parts = value.split(`; ${name}=`);
//     if (parts.length === 2) return parts.pop().split(";").shift();
//   };
 
//   useEffect(() => {
//   // Check if the user is logged in
//   const token = getCookie("token");
//   const email = getCookie("email");
//   const isLoggedIn = !!token && !!email;
//   console.log(token, "Token-------");
//   console.log(email, "Email-------");

//   // If user is already logged in, redirect to the chat page
//   if (isLoggedIn) {
//     // Optionally include other details in authUser
//     const authUser = {
//       email,
//       token,
//       // Add other details as needed
//     };

//     // Store authUser and isLoggedIn in localStorage
//     localStorage.setItem("authUser", JSON.stringify(authUser));
//     localStorage.setItem("isLoggedIn", "true");
//     navigate("/chat"); // Adjust the path as needed
//     }
//   }, []); // Empty dependency array ensures this effect runs only once

// {/*Apple sign up close--------- */}



  // Usage
  const nonce = generateNonce();

  const handleAppleSignInSuccess = async (user) => {
    try {
      // Use the user information (e.g., user.email, user.name) to authenticate on your server.
      console.log('Apple user:', user);

      // Send the user data to your server for authentication.
      // ...

    } catch (error) {
      console.error('Error during Apple Sign In:', error);
    }
  };

  const handleAppleSignInError = (error) => {
    console.error('Apple Sign In error:', error);
    // Handle the error, if needed
  };

  const appleSignInRender = ({ onClick }) => (
    <button onClick={onClick} style={{color:"grey"}} >Sign In with Apple</button>
  );




  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [resendingOtp, setResendingOtp] = useState(false);
  const [resendMessage, setResendMessage] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [email, setEmail] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const closeOtpAlert = () => {
    setOtpAlert({ ...otpAlert, open: false });
  };
  //const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+91'); // Default country code to India
  const {
    register: registerOtp,
    handleSubmit: handleSubmitOtp,
    formState: { errors: errorsOtp },
    watch,
  } = useForm();

  const phoneNumber = watch("phoneNumber");

  const [showPassword, setShowPassword] = useState(false);
  {
    /* Otp Modal*/
  }
  const [isOtpModalVisible, setOtpModalVisible] = useState(false);

  const handleGenerateOtp = async (data) => {
    if (data.acceptTerms) {
      await sendOtp(data.phoneNumber,countryCode);
    } else {
      console.log("Checkbox is required");
      // Handle the case where checkbox is not accepted
    }
  };

  const handleOtpVerification = (e) => {
    e.preventDefault();
    verifyOtp(); // Call verifyOtp with the current OTP value
  };

  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const handleShowOtpError = (message) => {
    setOtpErrorMessage(message);
  };

  // Call this to close the error message
  const handleCloseOtpError = () => {
    setOtpErrorMessage("");
  };
  const sendOtp = async (phoneNumber,countryCode) => {
    try {
      console.log("Sending OTP with phoneNumber:", phoneNumber, "and countryCode:", countryCode);
      const response = await axiosInstance.post(`generate-otp`, {
        phoneNumber: `${phoneNumber}`,
        countryCode,
      });
      console.log(phoneNumber)
      console.log(response.data.message); // Log the backend response
      console.log("Response from backend:", response.data); // Log the backend response
      setOtpModalVisible(true); // Show OTP input after sending
    } catch (error) {
      if (error.response && error.response.data.message === "User not found") {
        // Handle the "User not found" error specifically
        setOtpAlert({
          open: true,
          type: "danger",
          message: "User not found. Please register.",
        });
      } else {
        console.error("Error generating OTP:", error);
        setOtpAlert({
          open: true,
          type: "danger",
          message: "An error occurred while generating OTP.",
        });
      }
      setOtpModalVisible(false); // Don't show OTP input if there's an error
    }
  };
  const [newCategory, setNewCategory] = useState("");
  const [aiName, setAIName] = useState("");
  const verifyOtp = async () => {
    try {
      let data = {};
      if (phoneNumber) {
        data = { phoneNumber, otp };
      } else if (email) {
        // Use email prop here
        data = { email, emailOtp: otp };
      }
      console.log("email:", email);
      console.log("otp:", otp);
      console.log("Data being sent to server:", data);
      const response = await axiosInstance.post("/verify-otp", data);
      if (response.data.token) {
        // OTP is verified, set the login state to true
        setIsLoggedIn(true);
        const authUser = {
          ...response.data.user, // Spread user details
          token: response.data.token, // Add token
        };

        // Store the token in localStorage
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("authUser", JSON.stringify(authUser));
        localStorage.setItem("loggedIn", true);
       // Check if there is a redirect URL in localStorage
       const redirectUrl = localStorage.getItem("redirectUrl");
              
      // Decode the redirect URL
      const decodedRedirectUrl = decodeURIComponent(redirectUrl);
      console.log("decode",decodedRedirectUrl)
      // // Extract selected category and AI category from the decoded URL
      // const urlSearchParams = new URLSearchParams(decodedRedirectUrl);

      // const selectedCategory = urlSearchParams.get("selectedCategory");
      // const selectedAiCategory = urlSearchParams.get("selectedAiCategory");

      // // Save extracted values in local storage
      // localStorage.setItem("selectedCategory", selectedCategory);
      // localStorage.setItem("selectedAiCategory", selectedAiCategory);
      // Extract selected category and AI category from the decoded URL
      const categoryMatch = decodedRedirectUrl.match(/selectedCategory=([^&]*)/);
      const selectedCategory = categoryMatch && categoryMatch[1];

      const aiCategoryMatch = decodedRedirectUrl.match(/selectedAiCategory=([^&]*)/);
      const selectedAiCategory = aiCategoryMatch && aiCategoryMatch[1];

      const defaultSelectedCategory = "Somebody";
      const defaultSelectedAiCategory = "OneAIChat-Somebody";

      // Save extracted values in local storage or set default values if they are null
localStorage.setItem("selectedCategory", selectedCategory || defaultSelectedCategory);
localStorage.setItem("selectedAiCategory", selectedAiCategory || defaultSelectedAiCategory);
       if (redirectUrl) {
         // Extract the room ID from the redirect URL
      //    const roomId = redirectUrl.split("/").pop();
      //  console.log("room---------",roomId)
     
      const chatIndex = decodedRedirectUrl.indexOf("chat/") + 5;

      // Extract the room ID from the decoded redirect URL
      const roomIdWithQueryParams = decodedRedirectUrl.substring(chatIndex);
    
      // Remove any query parameters from the room ID
      const roomId = roomIdWithQueryParams.split('?')[0];
    
     
      // Navigate to the chat page with the extracted room ID
      navigate(`/chat/${roomId}`);
       
        
       } else {
         // If no redirect URL, navigate to the default chat page
         navigate("/chat");
   
       }
     }else {
        // Handle the case where OTP verification is not successful
        handleShowOtpError(
          response.data.message || "Incorrect OTP. Please try again."
        );
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      handleShowOtpError(
        error.response.data.message ||
          "An error occurred during OTP verification."
      );
    } finally {
      // Clear OTP input after submission attempt
      setOtp("");
      window.location.reload();
    }
  };

  const handleResendOtp = async () => {
    console.log("Resending OTP for:", { phoneNumber, email }); // Debugging line
    setResendingOtp(true);
    setResendMessage(""); // Clear any previous messages
    try {
      const data = phoneNumber ? { phoneNumber } : { email };
console.log("data",data)
      const response = await axiosInstance.post("/resend-otp", data);
      setResendMessage("OTP has been resent successfully."); // Set success message
      // Clear the message after 3 seconds
      setTimeout(() => {
        setResendMessage("");
      }, 3000);
    } catch (error) {
      setResendMessage("Failed to resend OTP. Please try again."); // Set error message
      // Clear the message after 3 seconds
      setTimeout(() => {
        setResendMessage("");
      }, 3000);
    }
    setResendingOtp(false);
  };

  {
    /* Otp Modal end*/
  }

  const alertHandler = (open, type = "", message = "") => {
    setAlert({ open, type, message });
  };

const handleGoogleLoginSuccess = async (response) => {
  console.log("response - ",response)
  console.log("responsecode ",response.code)

   // Send the authorization code to the backend server
   fetch('https://db.oneaichat.com/api/auth/google', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ code: response.code }),
  })
  .then(response => response.json())
  .then(data => {
    console.log('Backend response:', data);
    if (data.token) {
      // OTP is verified, set the login state to true
      setIsLoggedIn(true);
      
      // Create authUser object with email, token, and other user details if available
      const authUser = {
        ...data.user,
        // Add other user details if available
        // For example: name: data.name
      
      };
  
      // Store the token in localStorage
      localStorage.setItem("token", data.token);
      localStorage.setItem("authUser", JSON.stringify(authUser));
      localStorage.setItem("loggedIn", true);
      navigate("/chat");
      window.location.reload();
    }
  })
  .catch(error => {
    console.error('Error:', error);
  });
//   try {
//     // Set the isLoggedIn state to true after successful Google login
//     setIsLoggedIn(true);
  
//     // Store the Google token in localStorage
//     localStorage.setItem("googleToken", response?.code);

//     // Include the Google token in your login request
//     const loginResponse = await axiosInstance.get("signin", { googleToken: response?.code });

//     // Handle the login response as needed
  
//       // Navigate to the chat page or user dashboard
//       navigate("/chat");
    
//   } catch (error) {
//     console.error("Error verifying Google token:", error);
// console.error("Error description:", error.response.data.error_description);

//     // Handle errors, e.g., show an error message to the user
//   }
};


  const onSuccess = (res) => {
    reset();
    if (res?.data?.status) {
      setIsLoggedIn(true); // User is logged in
      localStorage.setItem("token", res?.data?.token); // Store the token
      navigate("/chat");
    } else {
      alertHandler(true, "danger", res?.data?.message ?? "Login Failed!");
    }
  };

  const onError = (err) => {};

  const { mutateAsync: signIn } = useLogin(onSuccess, onError);

  // Hook for Google login
  const login = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess, // Use the function here
    onError: (error) => {
      console.error("Google login error:", error);
    },
    flow: 'auth-code',
    popup: true,
    ux_mode: "popup",
    scope: "email", // Ensure that the "email" scope is included
  });

  const togglePassword = () => {
    setShowPassword((e) => !e);
  };
  const onSubmit = async (data) => {
    if (!data.acceptTerms) {
      console.log("You must accept the terms and conditions.");
      return;
    }

    try {
      setEmail(data.email);
      const response = await signIn(data);
      // Assuming the signIn function or the API call within it returns a response
      // that indicates the next step is to show the OTP modal
      if (response && response.status === 200) {
        // Condition to check if OTP modal should be shown
        // This condition depends on your application logic
        setOtpModalVisible(true);
      }
      // You might handle other responses or errors here
    } catch (err) {
      console.log(err.message);
    }
  };

  const googleLoginHandler = () => {
    console.log("Login google!");
    login();
  };

  return (
    <div>
      {/* <!-- Menu Star --> */}

      <Header
        className="position-absolute top-0 left-0 w-100 "
        showMenu={false}
        isLoggedIn={isLoggedIn} // Pass isLoggedIn to Header
        onLogout={() => setIsLoggedIn(false)} // Function to handle logout
      />
      {/* <!-- Menu End --> */}
      <main className="wrapper">
        {/* <!-- Signup Form Start  --> */}
        <section className="aai-signup-in">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-5 col-lg-4 d-none d-xl-block order-2 order-lg-1">
                <div className="position-relative">
                  <img
                    src="assets/img/others/ai-ills.svg"
                    className="img-fluid aai-signup-in-img"
                    alt=""
                  />
                </div>
              </div>
              {resendingOtp && <Loader />}
              <div className="col-xl-7 order-1 ps-xl-5 order-lg-2 mb-5 mb-lg-0">
                <div className="aai-form-wrapper">
                  <div className="">
                    <div className="aai-form-header d-flex justify-content-center text-center flex-column align-items-center">
                      <h2 className="aai-form-title">Sign in / Sign up</h2>
                      <p className="aai-form-desc"> 
                        Send, spend, and save smarter
                      </p>
                    </div>
                    <div className="row aai-au  th-links">
                      <div className="col-lg-6 mb-3 mb-lg-0">
                        <button
                          onClick={googleLoginHandler}
                          className="aai-auth-link w-100 justify-content-center d-flex align-items-center"
                        >
                          <GoogleIcon />
                          <span>Sign Up with Google</span>
                        </button>
                      </div>
                      <div className="col-lg-6">
                        <Link
                          to="#"
                          className="aai-auth-link w-100 d-flex justify-content-center align-items-center"
                        >
                          <AppleIcon />
                          <AppleSignIn
                          authOptions={{
                            clientId:'com.oneaichat',
                            redirectURI:'https://db.oneaichat.com/callback',
                            scope:"email name",
                            nonce: nonce, // Generate a unique nonce for security
                          }}
                          onSuccess={handleAppleSignInSuccess}
                          onError={handleAppleSignInError}
                          render={appleSignInRender}
                        />
                        </Link>
                      </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row aai-option-bar justify-content-center align-items-center">
                      <span className="aai-auth-line"> </span>
                      <span className="aai-auth-option flex-shrink-0">
                        Or with mobile
                      </span>
                      <span className="aai-auth-line"></span>
                    </div>

                      <form onSubmit={handleSubmitOtp(handleGenerateOtp)}>
                        <div className="col-lg-12">
                          <div className="aai-form-container">
                            <div className="CountryInput">
                          <select
                            value={countryCode}
                            onChange={(e) => setCountryCode(e.target.value)}
                            className=" form-select shadow-none"
                          >
                    
                            <option value="+91">
                                🇮🇳 +91 (India)
                              </option>
                              <option value="+1">
                            🇺🇸 +1 (USA)
                        </option>
                              <option value="+44">
                                🇬🇧 +44 (UK)
                              </option>
                              <option value="+86">
                                🇨🇳 +86 (China)
                              </option>
                              <option value="+81">
                                🇯🇵 +81 (Japan)
                              </option>
                              <option value="+49">
                                🇩🇪 +49 (Germany)
                              </option>
                              <option value="+41">
                                🇩🇪 +41 (Switzerland)
                              </option>
                              
                              <option value="+93">🇦🇫 +93 (Afghanistan)</option>
                              <option value="+355">🇦🇱 +355 (Albania)</option>
                              <option value="+376">🇦🇩 +376 (Andorra)</option>
                              <option value="+244">🇦🇴 +244 (Angola)</option>
                              <option value="+1268">🇦🇬 +1268 (Antigua and Barbuda)</option>
                              <option value="+54">🇦🇷 +54 (Argentina)</option>
                              <option value="+374">🇦🇲 +374 (Armenia)</option>
                              <option value="+297">🇦🇼 +297 (Aruba)</option>
                              <option value="+61">🇦🇺 +61 (Australia)</option>
                              <option value="+43">🇦🇹 +43 (Austria)</option>
                              <option value="+994">🇦🇿 +994 (Azerbaijan)</option>
                              <option value="+1242">🇧🇸 +1242 (Bahamas)</option>
                              <option value="+973">🇧🇭 +973 (Bahrain)</option>
                              <option value="+1246">🇧🇧 +1246 (Barbados)</option>
                              <option value="+32">🇧🇪 +32 (Belgium)</option>
                              <option value="+501">🇧🇿 +501 (Belize)</option>
                              <option value="+229">🇧🇯 +229 (Benin)</option>
                              <option value="+1441">🇧🇲 +1441 (Bermuda)</option>
                              <option value="+975">🇧🇹 +975 (Bhutan)</option>
                              <option value="+591">🇧🇴 +591 (Bolivia)</option>
                              <option value="+387">🇧🇦 +387 (Bosnia and Herzegovina)</option>
                              <option value="+267">🇧🇼 +267 (Botswana)</option>
                              <option value="+55">🇧🇷 +55 (Brazil)</option>
                              <option value="+673">🇧🇳 +673 (Brunei Darussalam)</option>
                              <option value="+359">🇧🇬 +359 (Bulgaria)</option>
                              <option value="+226">🇧🇫 +226 (Burkina Faso)</option>
                              <option value="+257">🇧🇮 +257 (Burundi)</option>
                              <option value="+855">🇰🇭 +855 (Cambodia)</option>
                              <option value="+237">🇨🇲 +237 (Cameroon)</option>
                              <option value="+1">🇨🇦 +1 (Canada)</option>
                              <option value="+238">🇨🇻 +238 (Cape Verde)</option>
                              <option value="+1345">🇰🇾 +1345 (Cayman Islands)</option>
                              <option value="+236">🇨🇫 +236 (Central African Republic)</option>
                              <option value="+235">🇹🇩 +235 (Chad)</option>
                              <option value="+56">🇨🇱 +56 (Chile)</option>
                              <option value="+57">🇨🇴 +57 (Colombia)</option>
                              <option value="+269">🇰🇲 +269 (Comoros)</option>
                              <option value="+243">🇨🇩 +243 (Democratic Republic of the Congo)</option>
                              <option value="+242">🇨🇬 +242 (Congo)</option>
                              <option value="+682">🇨🇰 +682 (Cook Islands)</option>
                              <option value="+506">🇨🇷 +506 (Costa Rica)</option>
                              <option value="+225">🇨🇮 +225 (Côte d'Ivoire)</option>
                              <option value="+385">🇭🇷 +385 (Croatia)</option>
                              <option value="+53">🇨🇺 +53 (Cuba)</option>
                              <option value="+357">🇨🇾 +357 (Cyprus)</option>
                              <option value="+420">🇨🇿 +420 (Czech Republic)</option>
                              <option value="+45">🇩🇰 +45 (Denmark)</option>
                              <option value="+253">🇩🇯 +253 (Djibouti)</option>
                              <option value="+1767">🇩🇲 +1767 (Dominica)</option>
                              <option value="+1809">🇩🇴 +1809 (Dominican Republic)</option>
                              <option value="+593">🇪🇨 +593 (Ecuador)</option>
                              <option value="+503">🇸🇻 +503 (El Salvador)</option>
                              <option value="+240">🇬🇶 +240 (Equatorial Guinea)</option>
                              <option value="+291">🇪🇷 +291 (Eritrea)</option>
                              <option value="+372">🇪🇪 +372 (Estonia)</option>
                              <option value="+500">🇫🇰 +500 (Falkland Islands (Malvinas))</option>
                              <option value="+298">🇫🇴 +298 (Faroe Islands)</option>
                              <option value="+679">🇫🇯 +679 (Fiji)</option>
                              <option value="+358">🇫🇮 +358 (Finland)</option>
                              <option value="+33">🇫🇷 +33 (France)</option>
                              <option value="+594">🇬🇫 +594 (French Guiana)</option>
                              <option value="+689">🇵🇫 +689 (French Polynesia)</option>
                              <option value="+241">🇬🇦 +241 (Gabon)</option>
                              <option value="+220">🇬🇲 +220 (Gambia)</option>
                              <option value="+350">🇬🇮 +350 (Gibraltar)</option>
                              <option value="+30">🇬🇷 +30 (Greece)</option>
                              <option value="+299">🇬🇱 +299 (Greenland)</option>
                              <option value="+1473">🇬🇩 +1473 (Grenada)</option>
                              <option value="+590">🇬🇵 +590 (Guadeloupe)</option>
                              <option value="+1671">🇬🇺 +1671 (Guam)</option>
                              <option value="+502">🇬🇹 +502 (Guatemala)</option>
                              <option value="+224">🇬🇳 +224 (Guinea)</option>
                              <option value="+245">🇬🇼 +245 (Guinea-Bissau)</option>
                              <option value="+592">🇬🇾 +592 (Guyana)</option>
                              <option value="+509">🇭🇹 +509 (Haiti)</option>
                              <option value="+504">🇭🇳 +504 (Honduras)</option>
                              <option value="+852">🇭🇰 +852 (Hong Kong)</option>
                              <option value="+36">🇭🇺 +36 (Hungary)</option>
                              <option value="+354">🇮🇸 +354 (Iceland)</option>
                              <option value="+353">🇮🇪 +353 (Ireland)</option>
                              <option value="+972">🇮🇱 +972 (Israel)</option>
                              <option value="+39">🇮🇹 +39 (Italy)</option>
                              <option value="+1876">🇯🇲 +1876 (Jamaica)</option>
                              <option value="+7">🇰🇿 +7 (Kazakhstan)</option>
                              <option value="+686">🇰🇮 +686 (Kiribati)</option>
                              <option value="+965">🇰🇼 +965 (Kuwait)</option>
                              <option value="+996">🇰🇬 +996 (Kyrgyzstan)</option>
                              <option value="+856">🇱🇦 +856 (Lao People's Democratic Republic)</option>
                              <option value="+371">🇱🇻 +371 (Latvia)</option>
                              <option value="+961">🇱🇧 +961 (Lebanon)</option>
                              <option value="+266">🇱🇸 +266 (Lesotho)</option>
                              <option value="+231">🇱🇷 +231 (Liberia)</option>
                              <option value="+218">🇱🇾 +218 (Libya)</option>
                              <option value="+423">🇱🇮 +423 (Liechtenstein)</option>
                              <option value="+370">🇱🇹 +370 (Lithuania)</option>
                              <option value="+352">🇱🇺 +352 (Luxembourg)</option>
                              <option value="+853">🇲🇴 +853 (Macao)</option>
                              <option value="+389">🇲🇰 +389 (Macedonia)</option>
                              <option value="+261">🇲🇬 +261 (Madagascar)</option>
                              <option value="+265">🇲🇼 +265 (Malawi)</option>
                              <option value="+960">🇲🇻 +960 (Maldives)</option>
                              <option value="+223">🇲🇱 +223 (Mali)</option>
                              <option value="+356">🇲🇹 +356 (Malta)</option>
                              <option value="+692">🇲🇭 +692 (Marshall Islands)</option>
                              <option value="+596">🇲🇶 +596 (Martinique)</option>
                              <option value="+222">🇲🇷 +222 (Mauritania)</option>
                              <option value="+230">🇲🇺 +230 (Mauritius)</option>
                              <option value="+262">🇾🇹 +262 (Mayotte)</option>
                              <option value="+52">🇲🇽 +52 (Mexico)</option>
                              <option value="+691">🇫🇲 +691 (Micronesia)</option>
                              <option value="+373">🇲🇩 +373 (Moldova)</option>
                              <option value="+377">🇲🇨 +377 (Monaco)</option>
                              <option value="+976">🇲🇳 +976 (Mongolia)</option>
                              <option value="+382">🇲🇪 +382 (Montenegro)</option>
                              <option value="+1664">🇲🇸 +1664 (Montserrat)</option>
                              <option value="+258">🇲🇿 +258 (Mozambique)</option>
                              <option value="+95">🇲🇲 +95 (Myanmar)</option>
                              <option value="+264">🇳🇦 +264 (Namibia)</option>
                              <option value="+674">🇳🇷 +674 (Nauru)</option>
                              <option value="+31">🇳🇱 +31 (Netherlands)</option>
                              <option value="+687">🇳🇨 +687 (New Caledonia)</option>
                              <option value="+64">🇳🇿 +64 (New Zealand)</option>
                              <option value="+505">🇳🇮 +505 (Nicaragua)</option>
                              <option value="+227">🇳🇪 +227 (Niger)</option>
                              <option value="+683">🇳🇺 +683 (Niue)</option>
                              <option value="+672">🇳🇫 +672 (Norfolk Island)</option>
                              <option value="+1670">🇲🇵 +1670 (Northern Mariana Islands)</option>
                              <option value="+47">🇳🇴 +47 (Norway)</option>
                              <option value="+968">🇴🇲 +968 (Oman)</option>
                              <option value="+680">🇵🇼 +680 (Palau)</option>
                              <option value="+970">🇵🇸 +970 (Palestinian Territory)</option>
                              <option value="+507">🇵🇦 +507 (Panama)</option>
                              <option value="+675">🇵🇬 +675 (Papua New Guinea)</option>
                              <option value="+595">🇵🇾 +595 (Paraguay)</option>
                              <option value="+51">🇵🇪 +51 (Peru)</option>
                              <option value="+48">🇵🇱 +48 (Poland)</option>
                              <option value="+351">🇵🇹 +351 (Portugal)</option>
                              <option value="+1939">🇵🇷 +1939 (Puerto Rico)</option>
                              <option value="+974">🇶🇦 +974 (Qatar)</option>
                              <option value="+262">🇷🇪 +262 (Réunion)</option>
                              <option value="+250">🇷🇼 +250 (Rwanda)</option>
                              <option value="+290">🇸🇭 +290 (Saint Helena)</option>
                              <option value="+1869">🇰🇳 +1869 (Saint Kitts and Nevis)</option>
                              <option value="+1758">🇱🇨 +1758 (Saint Lucia)</option>
                              <option value="+508">🇵🇲 +508 (Saint Pierre and Miquelon)</option>
                              <option value="+1784">🇻🇨 +1784 (Saint Vincent and the Grenadines)</option>
                              <option value="+684">🇼🇸 +684 (Samoa)</option>
                              <option value="+378">🇸🇲 +378 (San Marino)</option>
                              <option value="+239">🇸🇹 +239 (Sao Tome and Principe)</option>
                              <option value="+966">🇸🇦 +966 (Saudi Arabia)</option>
                              <option value="+221">🇸🇳 +221 (Senegal)</option>
                              <option value="+381">🇷🇸 +381 (Serbia)</option>
                              <option value="+248">🇸🇨 +248 (Seychelles)</option>
                              <option value="+232">🇸🇱 +232 (Sierra Leone)</option>
                              <option value="+65">🇸🇬 +65 (Singapore)</option>
                              <option value="+1721">🇸🇽 +1721 (Sint Maarten)</option>
                              <option value="+421">🇸🇰 +421 (Slovakia)</option>
                              <option value="+386">🇸🇮 +386 (Slovenia)</option>
                              <option value="+677">🇸🇧 +677 (Solomon Islands)</option>
                              <option value="+252">🇸🇴 +252 (Somalia)</option>
                              <option value="+27">🇿🇦 +27 (South Africa)</option>
                              <option value="+82">🇰🇷 +82 (South Korea)</option>
                              <option value="+211">🇸🇸 +211 (South Sudan)</option>
                              <option value="+34">🇪🇸 +34 (Spain)</option>
                              <option value="+94">🇱🇰 +94 (Sri Lanka)</option>
                            {/* Add more options for other countries as needed */}

                          </select>
                          </div>
                            {otpAlert.open && (
                              <Alert
                                type={otpAlert.type}
                                message={otpAlert.message}
                                onClose={closeOtpAlert}
                              />
                            )}
                            <input
                              type="tel"
                              className={`form-control shadow-none ${
                                errorsOtp?.phoneNumber ? "is-invalid" : ""
                              }`}
                              placeholder="Phone Number"
                              aria-invalid={
                                errorsOtp?.phoneNumber ? "true" : "false"
                              }
                              {...registerOtp("phoneNumber", {
                                required: {
                                  value: true,
                                  message: "Phone number is required!",
                                },
                                pattern: {
                                  value: /^[+]?[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/i,
                                  message: "Invalid phone number",
                                },
                                minLength: {
                                  value: 10,
                                  message:
                                    "Phone number must be at least 10 digits long",
                                },
                                maxLength: {
                                  value: 10,
                                  message:
                                    "Phone number must not be longer than 10 digits",
                                },
                              })}
                            />
                            {errorsOtp?.phoneNumber && (
                              <div className="mx-4 invalid-feedback">
                                {errorsOtp?.phoneNumber?.message ?? ""}
                              </div>
                            )}
                          </div>
                        </div>
                        <br />
                        <div className="col-lg-12 mb-2">
                          <div className="aai-form-container d-flex gap-3 ">
                            <label className="aai-form-check-label">
                              <input
                                type="checkbox"
                                className={`form-check-input ${
                                  errorsOtp?.acceptTerms ? "is-invalid" : ""
                                }`}
                                {...registerOtp("acceptTerms", {
                                  required:
                                    "You must accept the terms and conditions",
                                })}
                              />
                              <span style={{ marginLeft: "10px" }}>
                                By creating an account, you are agreeing to our
                                Privacy Policy.
                              </span>
                            </label>
                          </div>
                          {errorsOtp?.acceptTerms && (
                            <div className="mx-4 invalid-feedback">
                              {errorsOtp?.acceptTerms?.message}
                            </div>
                          )}
                        </div>
                        <div className="col-lg-12">
                          <br />
                          <div className="d-grid">
                            <button className="aai-btn btn-pill-solid">
                              Generate OTP
                            </button>
                            <br />
                          </div>
                        </div>
                      </form>

                    {isOtpModalVisible && (
                      <OtpModal
                        phoneNumber={phoneNumber}
                        countryCode={countryCode}
                        email={email}
                        otp={otp}
                        setOtp={setOtp}
                        onClose={() => setOtpModalVisible(false)}
                        onVerify={handleOtpVerification}
                        message={otpErrorMessage}
                        closeMessage={handleCloseOtpError}
                        onResendOtp={handleResendOtp}
                        resending={resendingOtp}
                        resendMessage={resendMessage}
                      />
                    )}

                    <div className="d-flex flex-column flex-md-row aai-option-bar justify-content-center align-items-center">
                      <span className="aai-auth-line"> </span>
                      <span className="aai-auth-option flex-shrink-0">
                        Or with email
                      </span>
                      <span className="aai-auth-line"></span>
                    </div>
                  </div>
                  <div>
                    {alert?.open && (
                      <Alert
                        type={alert?.type}
                        message={alert?.message}
                        onClose={alertHandler}
                      />
                    )}
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row g-3">
                        <div className="col-lg-12">
                          <div className="aai-form-container">
                            <input
                              type="email"
                              className={`form-control shadow-none ${
                                errors?.username ? "is-invalid" : ""
                              }`}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Email"
                              {...register("email", {
                                required: {
                                  value: true,
                                  message: "Email is required!",
                                },
                                pattern: {
                                  value:
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                  message: "Invalid email address",
                                },
                              })}
                            />
                            {errors?.username && (
                              <div className="mx-4 invalid-feedback">
                                {errors?.username?.message ?? ""}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-12 mb-2">
                          <div className="aai-form-container d-flex gap-3 ">
                            <label className="aai-form-check-label">
                              <input
                                type="checkbox"
                                className={`form-check-input ${
                                  errors?.acceptTerms ? "is-invalid" : ""
                                }`}
                                {...register("acceptTerms", {
                                  required:
                                    "You must accept the terms and conditions",
                                })}
                              />
                              <span style={{ marginLeft: "10px" }}>
                                By creating an account, you are agreeing to our
                                Privacy Policy.
                              </span>
                            </label>
                          </div>
                          {errors?.acceptTerms && (
                            <div className="mx-4 invalid-feedback">
                              {errors?.acceptTerms?.message}
                            </div>
                          )}
                        </div>
                        <div className="col-lg-12">
                          <div className="d-grid">
                            <button className="aai-btn btn-pill-solid">
                              Generate OTP
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Signup Form End  --> */}
      </main>
    </div>
  );
};

export default Login;
