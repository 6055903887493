import React from "react";
import Link from "components/Link";
import './Footer.css';
const Footer = () => {
  return (
    <footer className="aai-footer pt-120">
      <div className="container">
        <div className="aai-footer-support">
          <div className="row g-4 align-items-center">
            <div className="col-12 col-lg-6">
              <div className="aai-support-info d-flex align-items-center flex-column flex-lg-row">
                <div className="mb-3 mb-lg-0">
                  <img src="assets/img/icons/headsettt.svg" height={"90px"} alt="" />
                </div>
                <div className="aai-support-contact text-center text-lg-start">
                  <p className="aai-support-text mb-1">
                    Have a question? Call us 24/7
                  </p>
                  <Link to="tel:+91 9844383338" className="aai-support-number">
                    +91 9844383338
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="aai-newsletter">
                <form>
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your email"
                    />
                    <button className="aai-newsletter-btn" style={{
                      backgroundColor: "black", 
                      color: "white"
                    }}>
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="aai-footer-policies text-center mb-4" style={{marginTop:'30px',position:'relative',fontSize:'x-large'}}>
          <Link to="/privacy-policy" className="aai-footer-link">
            Privacy Policy
          </Link>
          <span> | </span>
          <Link to="/terms-use" className="aai-footer-link">
            Terms of Use
          </Link>
          <span> | </span>
          <Link to="/refund-and-cancellation" className="aai-footer-link">
          Cancellation and Refund
          </Link>
        </div>
        <div className="aai-footer-copyright text-center">
          <p className="aai-copyright-text">Copyright @2024 Oneaichat <span> | </span>  <a href="http://kaletech.in" target="_blank" rel="noopener noreferrer">KALETECH PRIVATE LIMITED</a></p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
