export const toolList = [
  {
    id: 1,
    name: "Somebody",
    icon: <i className="fa fa-commenting" aria-hidden="true"></i>,
  },
  {
    id: 2,
    name: "Health",
    icon: <i class="fa-solid fa-notes-medical"></i>,
  },
  {
    id: 3,
    name: "Marketing",
    icon:<i class="fa-solid fa-chart-simple"></i>,
  },
  {
    id: 4,
    name: "Coding",
    icon: <i class="fa-solid fa-terminal"></i>,
  },
  {
    id: 5,
    name: "Audio/Music",
    icon: <i class="fa-solid fa-music"></i>,
  },
  {
    id: 6,
    name: "Video",
    icon: <i class="fa-solid fa-video"></i>,
  },
  {
    id: 7,
    name: "Mathematics",
    icon:<i class="fa-solid fa-plus"></i>,
  },
  {
    id: 8,
    name: "Faith",
    icon: <i class="fa-solid fa-heart"></i>,
  },

  {
    id: 9,
    name: "Science",
    icon:<i class="fa-solid fa-flask"></i>,
  },
  {
    id: 10,
    name: "Art/Design",
    icon:<i class="fa-solid fa-pen-nib"></i>,
  },
  {
    id: 11,
    name: "Writing",
    icon: <i class="fa-solid fa-pencil"></i>,
  },
  {
    id: 12,
    name: "Finance",
    icon: <i class="fa-solid fa-coins"></i>,
  },
];

export const aiList = [
  {
    id: 1,
    name:"oneaichat",
    title: "OneAIChat-Somebody",
    subTitle: "Any query you may ask to get brief answers to the big questions.",
    icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  },
//{Gpt Modal open  */}
  {
    id: 2,
    title: "GPT-3.5-Turbo-0613",
    subTitle: (
      <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of OpenAI ' }} />
    ),
    icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
    otherAiList: [
      {
        id: 1,
        title: "Gpt-3.5-Turbo-0301",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of OpenAI ' }} />
        ),
        icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
      },
      {
        id: 2,
        title: "Gpt-3.5-Turbo-0125",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of OpenAI ' }} />
        ),
        icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
      },
      {
        id: 3,
        title: "Gpt-4-vision-preview",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of OpenAI ' }} />
        ),
        icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
      },
      {
        id: 4,
        title: "Gpt-4-1106-preview",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of OpenAI ' }} />
        ),
        icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
      },
      {
        id: 5,
        title: "Gpt-4-0125-preview",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of OpenAI ' }} />
        ),
        icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
      },
      {
        id: 6,
        title: "Gpt-4-turbo-preview",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of OpenAI ' }} />
        ),
        icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
      },
      {
        id: 7,
        title: "Gpt-3.5-Turbo-16k",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of OpenAI ' }} />
        ),
        icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
      },
      {
        id: 8,
        title: "Gpt-3.5-Turbo-1106",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of OpenAI ' }} />
        ),
        icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
      },
      {
        id: 9,
        title: "Gpt-3.5-Turbo-16k-0613",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of OpenAI ' }} />
        ),
        icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
      },
      {
        id: 10,
        title: "Gpt-3.5-Turbo-instruct",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of OpenAI ' }} />
        ),
        icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
      },
      {
        id: 11,
        title: "Gpt-4-0613",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of OpenAI ' }} />
        ),
        icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
      },
      {
        id: 12,
        title: "Gpt-3.5-Turbo-instruct-0914",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of OpenAI ' }} />
        ),
        icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
      },

      // this the gpt-40 i have added new 

      {
        id: 13,
        title: "Gpt-4o",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of OpenAI ' }} />
        ),
        icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
      },
      {
        id: 14,
        title: "Gpt-4o-2024-05-13",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of OpenAI ' }} />
        ),
        icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
      },




    ],
  },
//{Gpt Modal Close  */}
  {
    id: 3,
    title: "Gemini-1.0-pro",
    subTitle: (
      <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Google ' }} />
  ),
    icon: (
      <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
    ),
    otherAiList: [
      {
        id: 1,
        title: "Gemini-1.5-pro",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Google ' }} />
        ),
        icon: <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />,
      },
    ]
  },
  {
    id: 4,
    title: "Claude-2.0",
    subTitle: (
      <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Antropic ' }} />
    ),
    icon: <img src="/assets/img/chat/claude.webp" alt="ChatGPT" width="40px" />,
    otherAiList: [
      {
        id: 1,
        title: "Claude-+--1.2",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Antropic ' }} />
        ),
        icon: <img src="/assets/img/chat/claude.webp" alt="ChatGPT" width="40px" />,
      },
      {
        id: 2,
        title: "Claude-2.1",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Antropic ' }} />
        ),
        icon: <img src="/assets/img/chat/claude.webp" alt="ChatGPT" width="40px" />,
      },
      {
        id: 3,
        title: "Claude-3-opus-20240229",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Antropic ' }} />
        ),
        icon: <img src="/assets/img/chat/claude.webp" alt="ChatGPT" width="40px" />,
      },
      // Add more items as needed
      {
        id: 4,
        title: "Claude-3-sonnet-20240229",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Antropic ' }} />
        ),
        icon: <img src="/assets/img/chat/claude.webp" alt="ChatGPT" width="40px" />,
      },
      {
        id: 5,
        title: "Claude-3-haiku-20240307",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Antropic ' }} />
        ),
        icon: <img src="/assets/img/chat/claude.webp" alt="ChatGPT" width="40px" />,
      },
    ],
  },
  {
    id: 5,
    title: "Google Palm",
    subTitle: (
      <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Google ' }} />
  ),
    icon: (
      <img src="/assets/img/chat/palm.png" alt="ChatGPT" width="40px" />
    ),
  },
  {
    id: 6,
    title: "Mistral-7b",
    subTitle: (
      <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Mistral ' }} />
    ),
    icon: <img src="/assets/img/chat/mistral.jpeg" alt="mistral" width="40px" />,
    otherAiList: [
      {
        id: 1,
        title: "Mistral-tiny-2312",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Mistral ' }} />
        ),
        icon: <img src="/assets/img/chat/mistral.jpeg" alt="mistral" width="40px" />,
      },
      {
        id: 2,
        title: "Mistral-tiny",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Mistral ' }} />
        ),
        icon: <img src="/assets/img/chat/mistral.jpeg" alt="mistral" width="40px" />,
      },
     
      {
        id: 4,
        title: "Mistral-small-2312",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Mistral ' }} />
        ),
        icon: <img src="/assets/img/chat/mistral.jpeg" alt="mistral" width="40px" />,
      },
      {
        id: 5,
        title: "Open-mixtral-8x7b",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Mistral ' }} />
        ),
        icon: <img src="/assets/img/chat/mistral.jpeg" alt="mistral" width="40px" />,
      },
      {
        id: 6,
        title: "Mistral-small",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Mistral ' }} />
        ),
        icon: <img src="/assets/img/chat/mistral.jpeg" alt="mistral" width="40px" />,
      },
      {
        id: 7,
        title: "Mistral-small-2402",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Mistral ' }} />
        ),
        icon: <img src="/assets/img/chat/mistral.jpeg" alt="mistral" width="40px" />,
      },
      {
        id: 8,
        title: "Mistral-small-latest",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Mistral ' }} />
        ),
        icon: <img src="/assets/img/chat/mistral.jpeg" alt="mistral" width="40px" />,
      },
      {
        id: 9,
        title: "Mistral-medium-latest",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Mistral ' }} />
        ),
        icon: <img src="/assets/img/chat/mistral.jpeg" alt="mistral" width="40px" />,
      },
      {
        id: 10,
        title: "Mistral-medium-2312",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Mistral ' }} />
        ),
        icon: <img src="/assets/img/chat/mistral.jpeg" alt="mistral" width="40px" />,
      },
      {
        id: 11,
        title: "Mistral-medium",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Mistral ' }} />
        ),
        icon: <img src="/assets/img/chat/mistral.jpeg" alt="mistral" width="40px" />,
      },
      {
        id: 12,
        title: "Mistral-large-latest",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Mistral ' }} />
        ),
        icon: <img src="/assets/img/chat/mistral.jpeg" alt="mistral" width="40px" />,
      },
      {
        id: 13,
        title: "Mistral-large-2402",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Mistral ' }} />
        ),
        icon: <img src="/assets/img/chat/mistral.jpeg" alt="mistral" width="40px" />,
      },
      {
        id: 13,
        title: "Open-mixtral-8x22b",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Mistral ' }} />
        ),
        icon: <img src="/assets/img/chat/mistral.jpeg" alt="mistral" width="40px" />,
      },
      // Add more items as needed
    ],
    
  },
  {
    id: 1,
    title: "Command-R",
    subTitle: (
      <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Cohere ' }} />
    ),
    icon: <img src="/assets/img/chat/command.jpeg" alt="mistral" width="40px" />,
    otherAiList: [
      {
        id: 1,
        title: "Command-R-Plus",
        subTitle: (
          <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Cohere ' }} />
        ),
        icon: <img src="/assets/img/chat/command.jpeg" alt="mistral" width="40px" />,
      },
    // Add more items as needed
  ],
},
  // {
  //   id: 13,
  //   title: "Llama 2 70b",
  //   subTitle: (
  //     <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Llama ' }} />
  //   ),
  //   icon: <img src="/assets/img/chat/llama.jpeg" alt="llama" width="40px" />,
  // },
  


  // {
  //   id: 4,
  //   title: "Jasper",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 5,
  //   title: "Poe",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 6,
  //   title: "C.ai",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 7,
  //   title: "YouChat",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 8,
  //   title: "HuggingChat",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 9,
  //   title: "Bing AI",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 10,
  //   title: "ChatSpot",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
 
  // {
  //   id: 12,
  //   title: "Kommunicate",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },

];
export const healthAiList = [
  {
    id: 1,
    name:"oneaichat",
    title: "OneAIChat-Somebody",
    subTitle: "Any query you may ask to get brief answers to the big questions.",
    icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  },
  // {
  //   id: 2,
  //   title: "Health ChatGPT",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 3,
  //   title: "Health Google Bard",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 4,
  //   title: "Health Jasper",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 5,
  //   title: "Health Chatsonic",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 3,
  //   title: "Health Google Bard",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 5,
  //   title: "Health Chatsonic",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
];
export const marketingAiList = [
  {
    id: 1,
    name:"oneaichat",
    title: "OneAIChat-Somebody",
    subTitle: "Any query you may ask to get brief answers to the big questions.",
    icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  },
  // {
  //   id: 2,
  //   title: "SummarAlze",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 3,
  //   title: "EliteAI",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 4,
  //   title: "Robin",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 5,
  //   title: "Quicklines",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 6,
  //   title: "iContact",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 7,
  //   title: "Unspam.email",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 8,
  //   title: "Smartwriter",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 9,
  //   title: "ConverKit",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 10,
  //   title: "Sendinblue",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 11,
  //   title: "Marketingplan.io",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 12,
  //   title: "Cresta AI",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 13,
  //   title: "Quickchat",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 14,
  //   title: "Ferret",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 15,
  //   title: "Xembly ",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 16,
  //   title: "Qatalog",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 17,
  //   title: "Scale",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 18,
  //   title: "Reclaim",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 19,
  //   title: "Echowin",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 20,
  //   title: "Regie.ai",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 21,
  //   title: "Adobe sensei",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 22,
  //   title: "Poly.ai",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 23,
  //   title: "Predict ai",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 24,
  //   title: "Trypencil",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 25,
  //   title: "Rationale    ",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },  
];

export const videoAiList = [
  {
    id: 1,
    name:"oneaichat",
    title: "OneAIChat-Somebody",
    subTitle: "Any query you may ask to get brief answers to the big questions.",
    icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  },


  {
    id: 2,
    title: "Haiper_AI",
    subTitle: "Convert your text to video.",
    icon: <img src="/assets/img/chat/Haiper.jpeg" alt="Haiper_AI" width="40px" />,
  },


  // {
  //   id: 2,
  //   title: "Runway ML",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 3,
  //   title: "Synthesia",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 4,
  //   title: "Invideo",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 5,
  //   title: "Pictory",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 6,
  //   title: "DeepBrain AI",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 7,
  //   title: "HeyGen",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 8,
  //   title: "Veed.io",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 9,
  //   title: "Captions App",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 10,
  //   title: "CapCut",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 11,
  //   title: "Unscreen",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 12,
  //   title: "Magisto",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 13,
  //   title: "Animoto",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 14,
  //   title: "Adobe enhance",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 15,
  //   title: "Descript",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 16,
  //   title: "Krisp",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 17,
  //   title: "Filmora",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 18,
  //   title: "Flexiclip",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
];

export const scienceAiList = [
  {
    id: 1,
    name:"oneaichat",
    title: "OneAIChat-Somebody",
    subTitle: "Any query you may ask to get brief answers to the big questions.",
    icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  },
  // {
  //   id: 2,
  //   title: "Beautiful ai",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 3,
  //   title: "SheetAi.app",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 4,
  //   title: "Futurepedia",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 5,
  //   title: "Noty.ai",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 6,
  //   title: "AlanAI",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 7,
  //   title: "QuestAI",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 8,
  //   title: "MagicalAI",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 9,
  //   title: "Hints AI",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 10,
  //   title: "CoGram",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 11,
  //   title: "GitMind AI",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 12,
  //   title: "Audioread",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 13,
  //   title: "Vowel",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 14,
  //   title: "Rewind",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 15,
  //   title: "Mayday",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 16,
  //   title: "Fibery AI",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 17,
  //   title: "WhatThe AI",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 18,
  //   title: "Fireflies AI",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 19,
  //   title: "AI Intern",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 20,
  //   title: "Genel",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 21,
  //   title: "Nanonet",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 22,
  //   title: "Taskade",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 23,
  //   title: "Xembly",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 24,
  //   title: "Support Board",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 25,
  //   title: "ChatGPT for google sheets",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 26,
  //   title: "Resemble.AI",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },  
];
export const writingAiList = [
  {
    id: 1,
    name:"oneaichat",
    title: "OneAIChat-Somebody",
    subTitle: "Any query you may ask to get brief answers to the big questions.",
    icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  },
  // {
  //   id: 2,
  //   title: "Diib",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 3,
  //   title: "CanlRank",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 4,
  //   title: "KeywordTool",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 5,
  //   title: "Jasper",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 6,
  //   title: "ProRankTracker",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 7,
  //   title: "Ink",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 8,
  //   title: "SEO.ai",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 9,
  //   title: "Surfer SEO",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 10,
  //   title: "Scalenut",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 11,
  //   title: "Grammarly",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 12,
  //   title: "Tome.app",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 13,
  //   title: "Rytr",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 14,
  //   title: "Copy.ai",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 15,
  //   title: "Anyword",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 16,
  //   title: "Wordtune",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 17,
  //   title: "Simplified",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 18,
  //   title: "Text Matrics",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 19,
  //   title: "Prowritinggaid",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
  {
    id: 20,
    title: "Chatgpt",
    subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
    icon: (
      <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
    ),
  },
  // {
  //   id: 21,
  //   title: "Writecream",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 22,
  //   title: "QuillBot",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 23,
  //   title: "DeepL",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 24,
  //   title: "Writesonic",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 25,
  //   title: "Smart Copy",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 26,
  //   title: "WordAI",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },  
  // {
  //   id: 27,
  //   title: "ContentForge",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 28,
  //   title: "Hypotenuse AI",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
];
export const designAiList = [
{
  id: 1,
  name:"oneaichat",
    title: "OneAIChat-Somebody",
    subTitle: "Any query you may ask to get brief answers to the big questions.",
  icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
},
{
  id: 2,
  title: "Stable Diffusion Core",
  subTitle: (
    <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Stable Diffusion'}} />
  ),
  icon: <img src="/assets/img/chat/stability.png" alt="ChatGPT" width="50px" />,

  otherAiList: [
    {
      id: 1,
      title: "Stable Diffusion 3",
      subTitle: (
        <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Stable Diffusion ' }} />
      ),
      icon: <img src="/assets/img/chat/stability.png" alt="ChatGPT" width="50px" />,
    },
    {
      id: 2,
      title: "Stable Diffusion 3-Turbo",
      subTitle: (
        <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Stable Diffusion ' }} />
      ),
      icon: <img src="/assets/img/chat/stability.png" alt="ChatGPT" width="50px" />,
    },
    {
      id: 3,
      title: "Stable Diffusion 1.6",
      subTitle: (
        <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Stable Diffusion ' }} />
      ),
      icon: <img src="/assets/img/chat/stability.png" alt="ChatGPT" width="50px" />,
    },
    {
      id: 3,
      title: "Stable Diffusion-XL 1.0",
      subTitle: (
        <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of Stable Diffusion ' }} />
      ),
      icon: <img src="/assets/img/chat/stability.png" alt="ChatGPT" width="50px" />,
    },
  ],

},
{
  id: 4,
  title: "Dalle-3- HD-1024 * 1024",
  subTitle: (
    <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of OpenAI ' }} />
  ),
  icon: (
    <img src="/assets/img/chat/dalle.jpg" alt="ChatGPT" width="40px" />
  ),
  otherAiList: [
    {
      id: 8,
      title: "Dalle-3- HD-1024 * 1792",
      subTitle: (
        <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of OpenAI ' }} />
      ),
      icon: (
        <img src="/assets/img/chat/dalle.jpg" alt="ChatGPT" width="40px" />
      ),
    },
    {
      id: 9,
      title: "Dalle-3- Standard-1024 * 1024",
      subTitle: (
        <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of OpenAI ' }} />
      ),
      icon: (
        <img src="/assets/img/chat/dalle.jpg" alt="ChatGPT" width="40px" />
      ),
    },
    {
      id: 10,
      title: "Dalle-3- Standard-1024 * 1792",
      subTitle: (
        <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of OpenAI ' }} />
      ),
      icon: (
        <img src="/assets/img/chat/dalle.jpg" alt="ChatGPT" width="40px" />
      ),
    },
    {
      id: 5,
      title: "Dalle-2-1024 * 1024",
      subTitle: (
        <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of OpenAI ' }} />
      ),
      icon: (
        <img src="/assets/img/chat/dalle.jpg" alt="ChatGPT" width="40px" />
      ),
    },
    {
      id: 5,
      title: "Dalle-2-512 * 512",
      subTitle: (
        <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of OpenAI ' }} />
      ),
      icon: (
        <img src="/assets/img/chat/dalle.jpg" alt="ChatGPT" width="40px" />
      ),
    },
    {
      id: 5,
      title: "Dalle-2-256 * 256",
      subTitle: (
        <span dangerouslySetInnerHTML={{ __html: 'Registered &reg; Trademark &trade; of OpenAI ' }} />
      ),
      icon: (
        <img src="/assets/img/chat/dalle.jpg" alt="ChatGPT" width="40px" />
      ),
    },
  ]
},

]

export const codingAiList = [
  {
    id: 1,
    name:"oneaichat",
    title: "OneAIChat-Somebody",
    subTitle: "Any query you may ask to get brief answers to the big questions.",
    icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  },
  // {
  //   id: 2,
  //   title: "Coding Diib",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 3,
  //   title: " Coding CanlRank",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 4,
  //   title: " Coding KeywordTool",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 5,
  //   title: " Coding Jasper",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 6,
  //   title: "Coding ProRankTracker",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 7,
  //   title: "Coding Ink",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 8,
  //   title: "Coding SEO.ai",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 9,
  //   title: "Coding Surfer SEO",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 10,
  //   title: "Coding Scalenut",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 11,
  //   title: "Coding Grammarly",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 12,
  //   title: "Coding Tome.app",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
]


export const musicAiList = [
  {
    id: 1,
    name:"oneaichat",
    title: "OneAIChat-Somebody",
    subTitle: "Any query you may ask to get brief answers to the big questions.",
    icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  },
  // {
  //   id: 2,
  //   title: "Music Coding Diib",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 3,
  //   title: "Music Coding CanlRank",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 4,
  //   title: "Music Coding KeywordTool",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 5,
  //   title: "Music Coding Jasper",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 6,
  //   title: "Music Coding ProRankTracker",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 7,
  //   title: "Music Coding Ink",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 8,
  //   title: "Music Coding SEO.ai",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 9,
  //   title: "Music Coding Surfer SEO",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 10,
  //   title: "Music Coding Scalenut",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 11,
  //   title: "Music Coding Grammarly",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 12,
  //   title: "Music Coding Tome.app",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
]

export const mathAiList = [
  {
    id: 1,
    name:"oneaichat",
    title: "OneAIChat-Somebody",
    subTitle: "Any query you may ask to get brief answers to the big questions.",
    icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  },
  // {
  //   id: 2,
  //   title: "Math Coding Diib",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 3,
  //   title: "Math Coding CanlRank",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 4,
  //   title: "Math Coding KeywordTool",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 5,
  //   title: "Math Coding Jasper",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 6,
  //   title: "Math Coding ProRankTracker",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 7,
  //   title: "Math Coding Ink",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 8,
  //   title: "Math Coding SEO.ai",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 9,
  //   title: "Math Coding Surfer SEO",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 10,
  //   title: "Math Coding Scalenut",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 11,
  //   title: "Math Coding Grammarly",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 12,
  //   title: "Math Coding Tome.app",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
]

export const faithAiList = [
  {
    id: 1,
    name:"oneaichat",
    title: "OneAIChat-Somebody",
    subTitle: "Any query you may ask to get brief answers to the big questions.",
    icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  },
  // {
  //   id: 2,
  //   title: "Faith Coding Diib",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 3,
  //   title: "Faith Coding CanlRank",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 4,
  //   title: "Faith Coding KeywordTool",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 5,
  //   title: "Faith Coding Jasper",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 6,
  //   title: "Faith Coding ProRankTracker",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 7,
  //   title: "Faith Coding Ink",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 8,
  //   title: "Faith Coding SEO.ai",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 9,
  //   title: "Faith Coding Surfer SEO",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 10,
  //   title: "Faith Coding Scalenut",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 11,
  //   title: "Faith Coding Grammarly",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 12,
  //   title: "Faith Coding Tome.app",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
]


export const financeAiList = [
  {
    id: 1,
    name:"oneaichat",
    title: "OneAIChat-Somebody",
     subTitle: "Any query you may ask to get brief answers to the big questions.",
    icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  },
  // {
  //   id: 2,
  //   title: "Finance Coding Diib",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 3,
  //   title: "Finance Coding CanlRank",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 4,
  //   title: "Finance Coding KeywordTool",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 5,
  //   title: "Finance Coding Jasper",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 6,
  //   title: "Finance Coding ProRankTracker",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 7,
  //   title: "Finance Coding Ink",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 8,
  //   title: "Finance Coding SEO.ai",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/chatsonic.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 9,
  //   title: "Finance Coding Surfer SEO",
  //   subTitle: "Supporting line text for OneAI Chat in Health category.",
  //   icon: <img src="/assets/img/logo/2.jpeg" alt="ChatGPT" width="50px" />,
  // },
  // {
  //   id: 10,
  //   title: "Finance Coding Scalenut",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/chatgpt.svg" alt="ChatGPT" width="40px" />,
  // },
  // {
  //   id: 11,
  //   title: "Finance Coding Grammarly",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: (
  //     <img src="/assets/img/chat/googlebard.png" alt="ChatGPT" width="40px" />
  //   ),
  // },
  // {
  //   id: 12,
  //   title: "Finance Coding Tome.app",
  //   subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
  //   icon: <img src="/assets/img/chat/jasper.png" alt="ChatGPT" width="40px" />,
  // },
]
export const subcategoryList = [
  {
    id: 1,
    title: "Somebody",
    subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
    icon: <i className="fa fa-commenting fa-2xl" aria-hidden="true"></i> 
  },
  {
    id: 2,
    title: "Health",
    subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
    icon: <i class="fa-solid fa-notes-medical fa-2xl"></i>,
  },
  {
    id: 3,
    title: "Marketing",
    subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
    icon: (
      <i class="fa-solid fa-chart-simple fa-2xl"></i>
    ),
  },
  {
    id: 4,
    title: "Coding",
    subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
    icon: <i class="fa-solid fa-terminal fa-2xl"></i>,
  },
  {
    id: 5,
    title: "Audio/Music",
    subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
    icon: (
      <i class="fa-solid fa-music fa-2xl"></i>
    ),
  },
  {
    id: 6,
    title: "Video",
    subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
    icon: (
      <i class="fa-solid fa-video fa-2xl"></i>
    ),
  },
  {
    id: 7,
    title: "Mathematics",
    subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
    icon: (
      <i class="fa-solid fa-plus fa-2xl"></i>
    ),
  },
  {
    id: 8,
    title: "Faith",
    subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
    icon:  <i class="fa-solid fa-heart fa-2xl"></i>,
  },
  {
    id: 9,
    title: "Science",
    subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
    icon: <i class="fa-solid fa-flask fa-2xl"></i>,
  },
  {
    id: 10,
    title: "Art/Design",
    subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
    icon: (
      <i class="fa-solid fa-pen-nib fa-2xl"></i>
    ),
  },
  {
    id: 11,
    title: "Writing",
    subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
    icon: <i class="fa-solid fa-pencil fa-2xl"></i>,
  },
  {
    id: 12,
    title: "Finance",
    subTitle: "Supporting line text lorem Ipsum dolor sit amet, consectetur.",
    icon: (
      <i class="fa-solid fa-coins fa-2xl"></i>
    ),
  },
]