import { useQuery } from "react-query";
import { axiosInstance } from "config/axios";

const useGetRequest = ({ key, url, variables, method, ...rest }) => {
  const queryFn = async () => {
    if (method === "post") {
      return await axiosInstance.post(url, variables);
    }
    return await axiosInstance.get(url);
  };
  return useQuery({
    queryKey: key,
    queryFn,
    ...rest,
  });
};

export default useGetRequest;
