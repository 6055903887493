import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Authenticated = (props) => {
  const { children } = props;
  const authUser = localStorage.getItem("authUser");
  const navigate = useNavigate();

  useEffect(() => {
    // Extract the last part of the URL after the last '/'
    const urlParts = window.location.href.split("/");
    const roomIdParam = urlParts[urlParts.length - 1];

    if (roomIdParam) {
      // Save the room ID in local storage
      localStorage.setItem("roomId", roomIdParam);
    }

    // Check if the user is not authenticated
    if (!authUser) {
      // If not logged in, redirect to the login page with the current URL as a query parameter
   

      const redirectUrl = encodeURIComponent(window.location.href);
      const redirectWithCategories = `${redirectUrl}`;
      navigate(`/login?redirect=${redirectWithCategories}`);
      localStorage.setItem("redirectUrl", redirectUrl);
    }
  }, [authUser, navigate]);

  // If the user is authenticated, render the children
  return authUser ? children : null;
};

export default Authenticated;
